import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Auth, Login as LoginOTP, stages } from '@staccx/open-id'
import { Alert, Button, Input, ItemGroup, Layout, Loading } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import { Link } from 'react-router-dom'
import Logo from '../../../icons/Logo'

const oidcConfig = {
  authority: process.env.REACT_APP_FLEET_OIDC_URL ?? 'https://oidc.blue.link-demo.stacc.dev',
  client_id: 'fleet-web',
  redirect_uri: window.location.protocol + '//' + window.location.host + '/callback-login',
  response_type: 'id_token token',
  scope: 'openid profile portal-profile portal-api',
  silent_redirect_uri: window.location.protocol + '//' + window.location.host + '/callback-login-silent',
  post_logout_redirect_uri: window.location.protocol + '//' + window.location.host + '/callback-logout',
  automaticSilentRenew: true,
  clockSkew: 600
}

if (window.location.hostname !== 'localhost' && window.location.hostname !== '10.0.2.2') {
  const hostParts = window.location.host.split('.')
  hostParts.splice(0, 1, 'oidc')
  oidcConfig.authority = `https://${hostParts.join('.')}`
}

const configBytelab = {
  acrValue: 'bcc-provider',
  codePostUri: '/api/magic-link-email/magic-link',
  idPostUri: '/api/magic-link-email/request-link',
  callbackPath: '/callback/magic-link-email-provider',
  magicPollUri: '/api/magic-link-email/magic-link-completed'
}

const baseConfig = {
  oidcConfig,
  redirectAfterLogin: '/'
}

export const authService = new Auth(oidcConfig, true)
const redirectStages = [
  stages.errorDuringLogin,
  stages.tooManyAttempts,
  stages.failedToSubmitCode,
  stages.alreadySucceeded
]

const Login = ({ type = 'small' }) => {
  let config = null
  if (type === 'bytelab') {
    config = { ...baseConfig, ...configBytelab }
  } else {
    config = { ...baseConfig, ...configBytelab }
  }

  useEffect(() => {
    sessionStorage.removeItem('loggedoutReason')
  }, [])

  return (
    <LoginOTP oidcConfig={oidcConfig} {...config}>
      {({ lastError, stage, handleIdInput, handleCodeInput, submitCode, submitId }) => {
        if (redirectStages.includes(stage)) {
          window.location.replace('/')
        }
        if ([stages.submittingCode, stages.waitingForState, stages.submittingId, void 0].includes(stage)) {
          return (
            <Layout>
              <Center>
                <Loading />
              </Center>
            </Layout>
          )
        }

        return (
          <React.Fragment>
            {stage === stages.waitingForCode && (
              <form onSubmit={submitCode}>
                <Layout>
                  <Center>
                    <svg width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.7343 0L34.8021 10.0166V29.9834L17.7343 40L0.666748 29.9834V10.0166L17.7343 0ZM3.48302 28.388V22.4504L16.326 29.9839V35.9253L3.48302 28.388ZM16.326 26.7359L3.48302 19.2025V13.2647L16.326 20.7982V26.7359ZM19.1423 35.9255V20.7988L24.1584 17.8565V32.9817L19.1423 35.9255ZM26.9747 31.3289L31.9858 28.388V13.265L26.9747 16.2045V31.3289ZM22.7994 6.22079L17.7343 3.24826L4.84241 10.8143L9.90546 13.7842L22.7994 6.22079ZM25.5671 7.84511L12.674 15.4081L17.7347 18.3766L30.6267 10.8144L25.5671 7.84511Z"
                        fill="#BD5E55"
                      />
                    </svg>
                    <span>Stacc Asset Finance</span>{' '}
                  </Center>
                  <Input autoFocus onChange={handleCodeInput} id="loggin" label="Engangskode" />
                  {lastError && (
                    <TranslatedText i18nKey={lastError}>
                      {(value) => <Alert type={'warning'}>{value}</Alert>}
                    </TranslatedText>
                  )}

                  <Button variant="primary" type="submit">
                    {'Logg inn'}
                  </Button>
                  {type === 'email' && (
                    <TranslatedText i18nKey="MAGICLINK_CODE_MESSAGE">{(value) => <p>{value}</p>}</TranslatedText>
                  )}
                </Layout>
              </form>
            )}

            {stage === stages.waitingForId && (
              <form onSubmit={submitId}>
                <Layout>
                  <Center>
                    <svg width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.7343 0L34.8021 10.0166V29.9834L17.7343 40L0.666748 29.9834V10.0166L17.7343 0ZM3.48302 28.388V22.4504L16.326 29.9839V35.9253L3.48302 28.388ZM16.326 26.7359L3.48302 19.2025V13.2647L16.326 20.7982V26.7359ZM19.1423 35.9255V20.7988L24.1584 17.8565V32.9817L19.1423 35.9255ZM26.9747 31.3289L31.9858 28.388V13.265L26.9747 16.2045V31.3289ZM22.7994 6.22079L17.7343 3.24826L4.84241 10.8143L9.90546 13.7842L22.7994 6.22079ZM25.5671 7.84511L12.674 15.4081L17.7347 18.3766L30.6267 10.8144L25.5671 7.84511Z"
                        fill="#BD5E55"
                      />
                    </svg>
                    <span>Stacc Asset Finance</span>{' '}
                  </Center>
                  <TranslatedText i18nKey={type === 'sms' ? 'LOGIN_PHONE_INPUT_LABEL' : 'LOGIN_EMAIL_INPUT_LABEL'}>
                    {(value) =>
                      type === 'sms' ? (
                        <Input
                          mode="phone"
                          autoFocus
                          id="loggin"
                          onChange={(e) => handleIdInput({ target: { value: e.rawValue } })}
                          label={value}
                        />
                      ) : (
                        <Input id="loggin" autoFocus onChange={handleIdInput} label={value} />
                      )
                    }
                  </TranslatedText>

                  {lastError && (
                    <TranslatedText i18nKey={lastError}>
                      {(value) => <Alert type={'warning'}>{value}</Alert>}
                    </TranslatedText>
                  )}

                  <ItemGroup>
                    <TranslatedText i18nKey={'LOGIN_PHONE_GETOTP_LABEL'}>
                      {(value) => (
                        <Button variant="primary" type="submit">
                          {value}
                        </Button>
                      )}
                    </TranslatedText>
                    <TranslatedText i18nKey="LOGIN_PHONE_GETOTP_LABEL">
                      {(value) => (
                        <Link to={'/login'}>
                          <Button variant="secondary">Tilbake</Button>
                        </Link>
                      )}
                    </TranslatedText>
                  </ItemGroup>
                </Layout>
              </form>
            )}
            {stage === stages.waitingForValidation && (
              <TranslatedText i18nKey="MAGICLINK_MESSAGE">{(value) => <div>{value}</div>}</TranslatedText>
            )}
          </React.Fragment>
        )
      }}
    </LoginOTP>
  )
}

const Center = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 200px;
`

export default Login
