import React from 'react';
const Copy = () => {
  return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40 12H16C13.7891 12 12 13.7891 12 16V40C12 42.2109 13.7891 44 16 44H40C42.2109 44 44 42.2109 44 40V16C44 13.7891 42.2109 12 40 12ZM40 40H16V16H40V40Z"
      fill="#467EFF"/>
    <path d="M8 8H36V4H8C5.78906 4 4 5.78906 4 8V36H8V8Z" fill="#467EFF"/>
  </svg>
}
export default Copy
