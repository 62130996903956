import styled from "styled-components";

export const SmallHeading = styled.span`
  font-weight: var(--fontWeight-bold);
  font-size: var(--font-h3);
`

export const SmallSummaryHeading = styled.span`
  font-weight: var(--fontWeight-bold);
  font-size: var(--font-h3);
  padding: 0 0 var(--spacing-tiny) 0;
`

export const HeadingLargePadded = styled.h2`
  font-size: var(--font-h2);
  font-weight: var(--fontWeight-bold);
  padding: var(--spacing-small) 0 var(--spacing-small) 0;
`
