import React, { useState } from 'react'
import { css } from 'styled-components'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDebounce } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { CHOOSE_CAR, navigablePages, SUMMARY } from '../hooks/useCarConfiguration'
import AnchorButton from '../components/AnchorButton'
import Plus from '../../../icons/Plus'
import { defaultFlowRequestConfig, useFlows, useFlowsCount, useFlowSearch } from '../../../context/FlowProvider'
import { createNewCarConfig, deleteCarConfig, updateCarConfig } from '../../../api/flow'
import { useChosenCompany, useChosenConsolidateInvoice, useUserContext } from '../../../context/UserProvider'
import Container from '../../../components/Container'
import ActiveCarConfigurations from '../components/ActiveCarConfigurations'
import CompletedCarConfigurations from '../components/CompletedCarConfigurations'
import styled from 'styled-components'
function LandingPage() {
  const { userData: user } = useUserContext()
  const chosenCompany = useChosenCompany()
  const consolidateInvoice = useChosenConsolidateInvoice()
  const navigate = useNavigate()

  const [activeConfig, activeConfigSet] = useState(defaultFlowRequestConfig.active)
  const [completedConfig, completedConfigSet] = useState(defaultFlowRequestConfig.completed)

  const [activeSearch, activeSearchSet] = useState({ value: '' })
  const [debouncedActive] = useDebounce(activeSearch, 800)
  const [completedSearch, completedSearchSet] = useState({ value: '' })
  const [debouncedCompleted] = useDebounce(completedSearch, 800)
  const [isLoading, isLoadingSet] = useState(false)

  const { active, completed } = useFlows({ active: activeConfig, completed: completedConfig })
  const { activeCount, refetchActiveCount, completedCount, refetchCompleteCount } = useFlowsCount(
    (active?.flows?.concat(completed?.flows) || []).length > 0
  )

  const { flowSearchResults: filteredActive, searchLoading: activeSearchLoading } = useFlowSearch(true, debouncedActive)
  const { flowSearchResults: filteredCompleted, searchLoading: completedSearchLoading } = useFlowSearch(
    false,
    debouncedCompleted
  )

  const handleOpenConfiguration = (flow) => {
    const step = flow.data.configurationStep
    const { flowId } = flow
    if (!step) {
      navigate(`/car-order/${flowId}/${CHOOSE_CAR}`)
    }
    const route = navigablePages.includes(step)
    if (route) {
      navigate(`/car-order/${flowId}/${step}`)
    }
  }

  const handleCreateNewConfiguration = async ({ flow, step }) => {
    isLoadingSet(true)
    const flowId = flow?.flowId
    await createNewCarConfig({
      configurationStep: step,
      companyId: chosenCompany?.id,
      consolidateInvoiceId:
        consolidateInvoice?.ConsolidateCode === '_ALL_' ? null : consolidateInvoice?.ConsolidateCode,
      ...(flowId && {
        flowId,
        isDuplicateConfiguration: true,
        configurationStep: SUMMARY
      }),
      user: {
        id: user?.user?.id,
        roleType: user?.user?.roleType
      }
    }).then((data) => navigate(`/car-order/${data?.id}/${flowId ? SUMMARY : CHOOSE_CAR}`))
    isLoadingSet(false)
  }

  const handleRefresh = async () => {
    isLoadingSet(true)
    await Promise.all([active?.refetch(), completed?.refetch(), refetchActiveCount(), refetchCompleteCount()])
    isLoadingSet(false)
  }

  const handleDeleteConfiguration = async ({ flowId }) => {
    isLoadingSet(true)
    if (flowId) {
      await deleteCarConfig(flowId)
      await handleRefresh()
    }
    isLoadingSet(false)
  }

  const saveFlow = useMutation(
    'customConfigurationName',
    async ({ flowId, customConfigurationName, currentConfigurationName }) => {
      if (flowId && (customConfigurationName || customConfigurationName !== currentConfigurationName)) {
        await updateCarConfig({ flowId, data: { customConfigurationName } })
      }
    },
    { onSuccess: () => handleRefresh() }
  )

  const numActiveItems = activeSearch?.value ? filteredActive?.length || 0 : active?.flows?.length || 0
  const numMaxActiveItems = activeSearch?.value
    ? filteredActive?.length || 0
    : activeCount || active?.flows?.length || 0

  const numCompletedItems = completedSearch?.value ? filteredCompleted?.length || 0 : completed?.flows?.length || 0
  const numMaxCompletedItems = completedSearch?.value ? filteredCompleted?.length || 0 : completedCount || 0

  return (
    <Page>
      <Box>
        <a target='_blank' href='https://stacctest.bytetest.dk/'>
        <Bg />
        </a>
      </Box>
    </Page>
  )
}

const Page = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Box = styled.div`
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: fit-content;
  transition: 0.2s;
  transition-timing-function: ease-in-out;
  border: 2px solid transparent;
  :hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border: 2px solid gray;
    cursor: pointer;
  }
`
const Bg = styled.div`
  background-image: url('/bytelablogo.png');
  background-size: cover;
  height: 80px;
  width: 320px;
`
export default LandingPage
