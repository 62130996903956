import React, { useState } from 'react'
import { useQuery } from 'react-query'
import QuestionCard from '../components/QuestionCard'
import styled, { css } from 'styled-components'
import ContactInformation from '../components/ContactInformation'
import { useFeatureToggles, useUserContext } from '../../../context/UserProvider'
import ContactForm from '../components/ContactForm'
import { contactFromSanity, faqFromSanity } from '../../../api/api'
import { useI18nContext } from '../../../context/I18nProvider'
import { TranslatedText } from '@staccx/i18n'
import Container from '../../../components/Container'
import { BlockContent, Heading } from '@staccx/bento'
import * as Tabs from '@radix-ui/react-tabs'
import { Box } from '@staccx/bento'

const Help = () => {
  const [selected, selectedSet] = useState([])
  const { data = [] } = useQuery(['faq'], () => faqFromSanity())
  const { data: contactData = [] } = useQuery(['contact'], () => contactFromSanity())
  const { language } = useI18nContext()
  const featureToggles = useFeatureToggles()

  const result = data
    .filter((item) => item.headingText.nb !== 'Kontaktinfo')
    .map((faq) => ({
      headingText: faq.headingText[language],
      body: faq.body[language]
    }))

  const contactResult = contactData?.map((contact) => ({
    contact: contact
  }))

  const selectedHandler = (elem) => {
    if (selected.includes(elem)) {
      selectedSet((prevState) => prevState.filter((s) => s !== elem))
    } else {
      selectedSet((prevState) => [...prevState, elem])
    }
  }

  const { userData } = useUserContext()

  const leftResult = result.splice(0, Math.floor(result.length / 2))
  const [value, setValue] = useState('tab1')

  const tabs = [
    {
      value: 'tab1',
      label: 'Car fleet'
    },
    {
      value: 'tab2',
      label: 'Fleet Report'
    },
    {
      value: 'tab3',
      label: 'Environmental Report'
    },
    {
      value: 'tab4',
      label: 'Toll booth passings'
    },
    {
      value: 'tab5',
      label: 'Invoice report'
    },
    {
      value: 'tab6',
      label: 'Purchase Report'
    },
    {
      value: 'tab7',
      label: 'Leasing'
    },
    {
      value: 'tab8',
      label: 'Fuel Report'
    }
  ]


  const serializers = {
    types: {
      h3: (props) => (
        <pre data-language={props.node.language}>
          <h3>{props.node.h3}</h3>
        </pre>
      )
    }
  }
  return (
    <Container>
      <Heading level={1}>Support</Heading>
      <Heading>
        <TranslatedText i18nKey="FAQ" />
      </Heading>
      <Box variant="tabs">
        <StyledRoot defaultValue="tab1" onValueChange={(val) => setValue(val)}>
          <StyledList aria-label="Select tasks">
            {result.map((tab, index) => {
              return (
                <StyledTrigger key={tab.value} isactive={value === `tab${index + 1}`} value={`tab${index + 1}`}>
                  {tab.headingText}
                </StyledTrigger>
              )
            })}
          </StyledList>
          {result.map((tab, index) => {
            return (
              <QuestionCardBoxOpened>
              <StyledContent value={`tab${index + 1}`}>
                <BlockContent blocks={tab.body} serializers={serializers} />
              </StyledContent>
              </QuestionCardBoxOpened>
            )
          })}
        </StyledRoot>
      </Box>
      <Heading>Contact us</Heading>
      <ContactContainerForm>
        <ContactForm
          userData={userData?.user?.contactDetails}
          chosenCompany={userData?.chosenCompany?.name}
        ></ContactForm>
        <ContactContainer>
          <ContactInfoWrapper>
            <PhoneSvg />
            <p>55 33 39 50</p>
          </ContactInfoWrapper>
          <ContactInfoWrapper>
            <MailSvg />
            <p>hei@stacc.com</p>
          </ContactInfoWrapper>
          <ContactInfoWrapper>
            <MapPinSvg />
            <p>Tormøhlensgate 53C, 5058 Bergen</p>
          </ContactInfoWrapper>
        </ContactContainer>
      </ContactContainerForm>
    </Container>
  )
}

export default Help


const QuestionCardBoxOpened = styled.div`
  background-color: var(--color-white);
  color: #5e5b5b; // farge ikke lagt inn i themes
  padding: var(--spacing-medium);
  border-bottom-left-radius: var(--spacing-tinyPlus);
  border-bottom-right-radius: var(--spacing-tinyPlus);
  transition: all 0.3s;
  line-height: var(--spacing-medium);

  
`
const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  padding: var(--spacing-medium) 0;
`

const ContactInfoWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  place-content: start;
`
const CardGrid = styled.div`
  display: grid;
  margin: 0 auto;
  column-gap: var(--spacing-smallPlus);
  align-content: flex-start;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Grids = styled.div`
  display: grid;
  gap: var(--spacing-smallPlus);
  grid-template-columns: 1fr;
  align-content: start;
  position: relative;
`

const ContactContainerForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

export function PhoneSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={19} height={19} viewBox="0 0 19 19">
      <path
        fill="#076EFF"
        d="M11.275 1.556a.75.75 0 0 1 .92-.53 8.083 8.083 0 0 1 5.78 5.78.751.751 0 0 1-1.182.789.75.75 0 0 1-.268-.402 6.595 6.595 0 0 0-4.718-4.718.75.75 0 0 1-.532-.92Zm-.218 3.918c1.293.345 2.123 1.176 2.468 2.469a.75.75 0 1 0 1.45-.387c-.48-1.796-1.735-3.05-3.53-3.53a.75.75 0 0 0-.388 1.449Zm7.682 8.94A5.275 5.275 0 0 1 13.5 19C6.056 19 0 12.944 0 5.5A5.274 5.274 0 0 1 4.586.26a1.5 1.5 0 0 1 1.558.893l1.98 4.42v.011A1.5 1.5 0 0 1 8.005 7a1.025 1.025 0 0 1-.053.072L6 9.386c.702 1.427 2.195 2.906 3.64 3.61l2.282-1.941a1.501 1.501 0 0 1 1.492-.184l.013.005 4.416 1.98a1.5 1.5 0 0 1 .896 1.558Zm-1.489-.188h-.01l-4.407-1.973-2.282 1.941a.803.803 0 0 1-.07.053 1.501 1.501 0 0 1-1.476.107c-1.756-.849-3.507-2.586-4.356-4.323a1.5 1.5 0 0 1 .094-1.472.575.575 0 0 1 .053-.072L6.75 6.17 4.781 1.764v-.011A3.769 3.769 0 0 0 1.5 5.5a12.013 12.013 0 0 0 12 12 3.77 3.77 0 0 0 3.75-3.275v.001Z"
      />
    </svg>
  )
}

export function MailSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={20} height={16} viewBox="0 0 20 16">
      <path
        fill="#076EFF"
        d="M19 .5H1a.75.75 0 0 0-.75.75V14a1.5 1.5 0 0 0 1.5 1.5h16.5a1.5 1.5 0 0 0 1.5-1.5V1.25A.75.75 0 0 0 19 .5ZM17.072 2 10 8.483 2.928 2h14.144Zm1.178 12H1.75V2.955l7.743 7.098a.75.75 0 0 0 1.014 0l7.743-7.098V14Z"
      />
    </svg>
  )
}

export function MapPinSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={22} height={18} viewBox="0 0 18 22">
      <path
        fill="#076EFF"
        d="M9 5a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 9 5Zm0 6a2.25 2.25 0 1 1 0-4.5A2.25 2.25 0 0 1 9 11ZM9 .5A8.26 8.26 0 0 0 .75 8.75c0 2.944 1.36 6.064 3.938 9.023a23.834 23.834 0 0 0 3.885 3.591.75.75 0 0 0 .861 0 23.833 23.833 0 0 0 3.879-3.59c2.573-2.96 3.937-6.08 3.937-9.024A8.26 8.26 0 0 0 9 .5Zm0 19.313c-1.55-1.22-6.75-5.696-6.75-11.063a6.75 6.75 0 0 1 13.5 0c0 5.365-5.2 9.844-6.75 11.063Z"
      />
    </svg>
  )
}

const StyledButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 2rem;
  span {
    transition: ease-in-out 0.2s;
  }
`
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-medium);
  background-color: white;
  border: 2px solid transparent;
  border-bottom: 8px solid blue;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  :hover {
    cursor: pointer;
    span {
      transform: translateX(15px);
    }
  }
  transition: ease-in-out 0.2s;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-medium);
  padding: var(--spacing-medium);
  > a {
    text-decoration: none;
  }
  @media (min-width: 680px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1220px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
  }
`

const StyledTrigger = styled(Tabs.Trigger)`
  border: none;
  background-color: white;
  padding: 1rem 2rem;
  position: relative;

  cursor: pointer;
  ${(props) =>
    props.isactive
      ? css`
          color: black;
          font-weight: 600;
          :after {
            left: 50%;
            bottom: 0;
            position: absolute;
            transform: translateX(-50%);
            width: 75%;
            width: calc(100% - 4rem);
            content: '';
            display: block;
            height: 4px;
            background-color: blue;
          }
        `
      : css`
          border-bottom: 4px solid transparent;
        `}
`

const StyledList = styled(Tabs.List)`
  display: flex;
  border-bottom: 2px solid lightgray;
`
const StyledRoot = styled(Tabs.Root)`
  background-color: transparent;
`

const StyledContent = styled(Tabs.Content)`
       
`
