import React from 'react'
import { TranslatedText } from '@staccx/i18n'
import { Field, Formik } from 'formik'
import { Alert, Button, Input, Layout, LayoutItem, Loading, Warning } from '@staccx/bento'
import styled from 'styled-components'
import { useUserContext } from '../../context/UserProvider'
import { useMutation } from 'react-query'
import { updateDriverDetails } from '../../api/api'

const Yup = require('yup')

const validateSchema = Yup.object().shape({
  name: Yup.string().required(),
  mail: Yup.string().email().required(),
  phone: Yup.string().required(),
  address: Yup.string().nullable().required('Påkrevd'),
  postCode: Yup.string().required('Påkrevd')
})

const ProfileForm = ({ onFinish }) => {
  const { userData, updateCurrentUser } = useUserContext()
  const saveUser = useMutation(
    'saveUser',
    (driverDetails) => updateDriverDetails(userData.chosenCompany.id, userData.user.id, driverDetails),
    {
      onSuccess: () => {
        updateCurrentUser()
        onFinish()
      }
    }
  )

  const contactDetails = userData && userData.user.contactDetails

  if (!userData) {
    return <Loading />
  }

  return (
    <Formik
      initialValues={contactDetails}
      validationSchema={validateSchema}
      onSubmit={(details) => saveUser.mutate(details)}
      render={({ handleSubmit, isValid, values, touched, errors, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Layout variant="formElements">
              <LayoutItem>
                <Field
                  name={`name`}
                  render={({ field }) => (
                    <Input
                      id={`name`}
                      {...field}
                      placeholder={'Navn Navnesen'}
                      label={<TranslatedText i18nKey="NAME" />}
                    />
                  )}
                />
                {touched.name && errors.name && (
                  <Alert variant="error" type="warning">
                    {errors.name}
                  </Alert>
                )}
              </LayoutItem>
              <LayoutItem>
                <Field
                  name={`mail`}
                  render={({ field }) => (
                    <Input
                      id={`mail`}
                      {...field}
                      placeholder={'navn@selskap.no'}
                      label={<TranslatedText i18nKey="EMAIL" />}
                    />
                  )}
                />
                {touched.mail && errors.mail && (
                  <Alert variant="error" type="warning">
                    {errors.mail}
                  </Alert>
                )}
                {values.mail !== contactDetails.mail && [
                  <StyledWarning />,
                  <TranslatedText i18nKey={'CHANGED_EMAIL'}>{(value) => <span>{value}</span>}</TranslatedText>
                ]}
              </LayoutItem>
              <LayoutItem>
                <Field
                  name={`phone`}
                  render={({ field }) => (
                    <Input
                      mode={'phone'}
                      id={'phone'}
                      label={<TranslatedText i18nKey="PHONE" />}
                      placeholder={'454 54 454'}
                      locale={'nb'}
                      {...field}
                      onChange={(event) => {
                        setFieldValue('phone', event.rawValue)
                      }}
                    />
                  )}
                />
                {touched.phone && errors.phone && (
                  <Alert variant="error" type="warning">
                    {errors.phone}
                  </Alert>
                )}
                {values.phone.replace(/\s/g, '') !== contactDetails.phone.replace(/\s/g, '') && [
                  <StyledWarning />,
                  <TranslatedText i18nKey={'CHANGED_PHONE'}>{(value) => <span>{value}</span>}</TranslatedText>
                ]}
              </LayoutItem>
              <LayoutItem>
                <Field
                  name={`address`}
                  render={({ field }) => {
                    return (
                      <Input
                        id={'home'}
                        autocomplete="shipping street-address"
                        label={<TranslatedText i18nKey="HOME_ADDRESS" />}
                        placeholder={'Gatenavn 5'}
                        {...field}
                      />
                    )
                  }}
                />
                {touched.address && errors.address && (
                  <Alert variant="error" type="warning">
                    {errors.address}
                  </Alert>
                )}
              </LayoutItem>
              <LayoutItem>
                <Field
                  name={`postCode`}
                  render={({ field }) => {
                    const { onChange, ...rest } = field
                    return (
                      <Input
                        mode={'postal'}
                        {...rest}
                        onChange={(e) => setFieldValue('postCode', e.rawValue)}
                        locale={'nb'}
                        label={<TranslatedText i18nKey="POSTAL_NUMBER" />}
                        placeholder={'0001'}
                        id="dnwqu"
                      />
                    )
                  }}
                />
                {touched.postCode && errors.postCode && (
                  <Alert variant="error" type="warning">
                    {errors.postCode}
                  </Alert>
                )}
              </LayoutItem>
              {saveUser.isLoading ? (
                <Loading />
              ) : (
                <Button type={'submit'} disabled={!isValid || !touched} onClick={() => null}>
                  <TranslatedText i18nKey={'SAVE'} />
                </Button>
              )}
            </Layout>
          </form>
        )
      }}
    />
  )
}

const StyledWarning = styled(Warning)`
  vertical-align: middle;
`

export default ProfileForm
