import React, { useMemo, useState } from 'react'
import { Input, Wrapper } from '@staccx/bento'
import styled from 'styled-components'
import { i18nInstance, TranslatedText } from '@staccx/i18n'
import { get } from 'lodash'
import { NavLink, useParams } from 'react-router-dom'
import { CarTopSummary } from '../components/CarTopSummary'
import {
  colorFilter,
  exteriorFilter,
  flattenAccessories,
  interiorFilter,
  miscFilter,
  packageEquipmentFilter,
  packageFilter,
  packageInteriorFilter,
  packageOtherFilter,
  packageSecurityFilter,
  paintTypeFilter,
} from '../../../api/jato'
import formatOptionName from '../../../utils/formatOptionName'
import { useCarPrice } from '../hooks/useCarPrice'
import useCarConfiguration, { SUMMARY } from '../hooks/useCarConfiguration'
import Navigation from '../components/Navigation'
import { SmallSummaryHeading } from '../components/Headings'
import { useCarOptionRules } from '../hooks/useCarOptionRules'
import { STANDARD_PAINT } from './ConfigureCarOptions'
import FormError from '../components/FormError'
import RequiresModal from '../components/RequiresModal'
import useCarConfigurationQueries from '../hooks/useCarConfigurationQueries'
import CustomDatePicker from '../../../components/fields/CustomDatePicker'
import { useFlow } from '../../../context/FlowProvider'
import { NORWEGIAN_MOUNTED_ACCESSORIES } from '../../../utils/norwegianMountedOptions'
import ImageDialog from '../components/imageGallery/ImageDialog'
import Container from '../../../components/Container'
import Toast from '../components/toast/Toast'

const MAX_NOTES_LENGTH = 200

export function Summary() {
  const { flowId } = useParams()
  const { flow, refetch, isPolling } = useFlow(flowId, SUMMARY, true)

  const { saveAndNavigate, errorHandler, companyId, isRedirecting, isLoading } = useCarConfiguration({
    page: SUMMARY,
    flow,
    refetch,
    flowIsPolling: isPolling,
  })

  const { carConfiguration, vehicleType, visitedSteps, additionalInformation, chosenReplacingCar } = flow?.data || {}
  const {
    chosenPackages = [],
    chosenOptions = [],
    chosenFinancingOptions = {},
    vehicleId,
    chosenWheelPackage,
    chosenAgreements = [],
  } = carConfiguration || {}

  const {
    color,
    paintType,
    interior,
    exterior,
    misc,
    equipmentPackages,
    interiorPackages,
    securityPackages,
    otherPackages,
    norwegianMountedOptions,
  } = useMemo(() => {
    if ((!chosenOptions || !chosenPackages) && !isRedirecting) {
      return {}
    }

    return {
      color: (chosenOptions || []).filter(colorFilter).map((o) => ({
        ...o,
        optionName: formatOptionName(o.optionName),
      })),
      paintType: (chosenOptions || []).filter(paintTypeFilter).map((o) => ({
        ...o,
        optionName: formatOptionName(o.optionName),
      })),
      interior: (chosenOptions || []).filter(interiorFilter).map((o) => ({
        ...o,
        optionName: formatOptionName(o.optionName),
      })),
      exterior: (chosenOptions || []).filter(exteriorFilter).map((o) => ({
        ...o,
        optionName: formatOptionName(o.optionName),
      })),
      misc: (chosenOptions || []).filter(miscFilter).map((o) => ({
        ...o,
        optionName: formatOptionName(o.optionName),
      })),
      equipmentPackages: (chosenPackages || []).filter(packageEquipmentFilter).map((o) => ({
        ...o,
        optionName: formatOptionName(o.optionName),
      })),
      interiorPackages: (chosenPackages || []).filter(packageInteriorFilter).map((o) => ({
        ...o,
        optionName: formatOptionName(o.optionName),
      })),
      securityPackages: (chosenPackages || []).filter(packageSecurityFilter).map((o) => ({
        ...o,
        optionName: formatOptionName(o.optionName),
      })),
      otherPackages: (chosenPackages || []).filter(packageOtherFilter).map((o) => ({
        ...o,
        optionName: formatOptionName(o.optionName),
      })),
      norwegianMountedOptions: (chosenOptions || []).filter((o) => o.optionType === NORWEGIAN_MOUNTED_ACCESSORIES),
    }
  }, [chosenOptions, chosenPackages, isRedirecting])

  const { accessoriesRaw, wheelPackagesRaw } = useCarConfigurationQueries(SUMMARY, errorHandler, {
    vehicleType,
    companyId,
    vehicleId,
    make: carConfiguration?.makeKey,
    model: carConfiguration?.modelKey,
    modelYear: carConfiguration?.modelYear,
    bodyCode: carConfiguration?.bodyCode,
    doors: carConfiguration?.numberOfDoors,
    drivenWheels: carConfiguration?.drivenWheels,
    engine: carConfiguration?.derivativeLocal,
  })

  const { error: accessoriesError, data: accessoriesData } = accessoriesRaw

  const { accessories, photo, photos, standardEquipment } = useMemo(() => {
    if (!accessoriesData) return {}

    const { packages = [], options = {} } = accessoriesData

    return {
      accessories: { options, packages },
      photo: get(accessoriesData, 'vehiclePhotos[0]', []),
      photos: get(accessoriesData, 'vehiclePhotos', []),
      standardEquipment: get(accessoriesData, 'vehicleStandardEquipment', {}),
    }
  }, [accessoriesData])

  const hasSelectableWheels = wheelPackagesRaw?.data && !wheelPackagesRaw?.error

  const {
    selectedOptions,
    selectedPackages,
    includedOptions,
    excludedOptions,
    optionThatRequiresSelections,
    handleToggleOption,
    getOptionNameByOptionId,
    getOptionIncludedBy,
    getExcludedByOption,
    fulfillsRequirements,
    handleRemoveOption,
    getSelectedByPackage,
    requiredSelections,
    formError,
    formValidator,
    simpleFormValidator,
    currentSelectedOption,
    setRequiredSelections,
    getOptionDependencies,
  } = useCarOptionRules(
    [...flattenAccessories(accessories), STANDARD_PAINT],
    [...chosenOptions, ...chosenPackages],
    SUMMARY,
    !!carConfiguration?.derivativeLocal,
    visitedSteps,
    !!carConfiguration?.chosenWheelPackage,
    hasSelectableWheels
  )

  const [openPhotos, openPhotosSet] = useState(false)
  const [notes, notesSet] = useState(additionalInformation?.notes || '')
  const [startDate, startDateSet] = useState(
    additionalInformation?.preferredDeliveryDate ? new Date(additionalInformation.preferredDeliveryDate) : null
  )
  const [customConfigurationName, customConfigurationNameSet] = useState(flow?.data?.customConfigurationName)

  if (accessoriesError) {
    errorHandler(accessoriesError)
  }

  const { monthlyPrice, sumAccessoriesRetailPrice, sumOptionsWithoutResidualValueCalcPrice } = useCarPrice({
    configuration: accessoriesData ? carConfiguration : null,
    options: flow && !isRedirecting ? [...chosenPackages, ...chosenOptions] : [],
    vehicleType,
    financingOptions: carConfiguration?.chosenFinancingOptions || {},
    errorHandler,
  })

  const updateFlowHandler = ({ direction, route }) => {
    saveAndNavigate.mutate({
      carConfiguration: {
        ...carConfiguration,
        chosenOptions: selectedOptions.filter((o) => !packageFilter(o)),
        chosenPackages: selectedPackages,
        chosenWheelPackage,
        chosenAgreements,
      },
      prices: {
        monthlyPrice,
        sumAccessoriesRetailPrice,
        sumOptionsWithoutResidualValueCalcPrice,
      },
      additionalInformation: {
        notes,
        preferredDeliveryDate: startDate?.toISOString()?.split('T')?.[0],
      },
      customConfigurationName,
      step: SUMMARY,
      vehicleType,
      direction,
      formValidator,
      route,
    })
  }

  if (isRedirecting) {
    return null
  }

  return (
    <Container isLoading={isLoading} width="100%">
      <CarTopSummary
        car={carConfiguration}
        photoUrl={photo || (carConfiguration && carConfiguration.photoUrl)}
        coreCalculatedMonthlyPrice={monthlyPrice}
        vehiclePhotos={photos}
        standardEquipment={standardEquipment}
        onPress={() => {
          if (photos.length > 0) {
            openPhotosSet(!!photos)
          }
        }}
        page={SUMMARY}
        saveAndNavigate={updateFlowHandler}
        routeValidator={simpleFormValidator}
        visitedPages={visitedSteps}
      />
      <RequiresModal
        requiredSelections={requiredSelections}
        optionThatRequiresSelections={optionThatRequiresSelections}
        currentSelectedOption={currentSelectedOption}
        handleToggleOption={handleToggleOption}
        getOptionNameByOptionId={getOptionNameByOptionId}
        getOptionIncludedBy={getOptionIncludedBy}
        getExcludedByOption={getExcludedByOption}
        touchedOptions={{ selectedOptions, excludedOptions, includedOptions }}
        fulfillsRequirements={fulfillsRequirements}
        getSelectedByPackage={getSelectedByPackage}
        handleRemoveOption={handleRemoveOption}
        getOptionDependencies={getOptionDependencies}
      />
      {photos && <ImageDialog photos={photos} isOpen={openPhotos} openSet={openPhotosSet} />}
      <Wrapper>
        <div>
          <SmallSummaryHeading>
            <TranslatedText i18nKey="CAR_CONFIG_SUMMARY_HEADING" />
          </SmallSummaryHeading>
          <UpperField>
            <UpperColumn>
              <SmallSummaryHeading header>
                <TranslatedText i18nKey="FINANCING" />
              </SmallSummaryHeading>
              <Row>
                <TranslatedText i18nKey="AGREEMENT_TERM" />
                <Span>{chosenFinancingOptions.term}</Span>
              </Row>
              <Row>
                <TranslatedText i18nKey="YEARLY_MILAGE" />
                <Span>{chosenFinancingOptions.mileage}</Span>
              </Row>
            </UpperColumn>
            <UpperColumn>
              <SmallSummaryHeading header>
                <TranslatedText i18nKey="CAR_CONFIG_AGREEMENTS_HEADING" />
              </SmallSummaryHeading>
              {chosenAgreements.length > 0 ? (
                <>
                  {chosenAgreements.map((ag) => (
                    <AgreementRow>{ag.Description}</AgreementRow>
                  ))}
                </>
              ) : (
                <p>
                  <TranslatedText i18nKey="NO_SERVICE_AGREEMENTS_SELECTED" />
                </p>
              )}
            </UpperColumn>
          </UpperField>
          <LowerField>
            <LowerColumn>
              <SmallSummaryHeading>
                <TranslatedText i18nKey="PACKAGES" />
              </SmallSummaryHeading>
              {chosenPackages.length > 0 ? (
                <>
                  {equipmentPackages.map((eq) => (
                    <Row>{eq.optionName}</Row>
                  ))}
                  {interiorPackages.map((eq) => (
                    <Row>{eq.optionName}</Row>
                  ))}
                  {securityPackages.map((eq) => (
                    <Row>{eq.optionName}</Row>
                  ))}
                  {otherPackages.map((eq) => (
                    <Row>{eq.optionName}</Row>
                  ))}
                </>
              ) : (
                <p>
                  <TranslatedText i18nKey="NO_EQUIPMENT_PACKAGES_SELECTED" />
                </p>
              )}
            </LowerColumn>
            <LowerColumn>
              <SmallSummaryHeading>
                <TranslatedText i18nKey="EQUIPMENT" />
              </SmallSummaryHeading>
              {chosenOptions.length > 0 ? (
                <>
                  <Row>
                    <RowHeader>
                      <TranslatedText i18nKey="EXTERIOR">{(value) => <List>{value}</List>}</TranslatedText>
                    </RowHeader>
                    <Span>
                      {exterior.map((eq) => (
                        <List>{eq.optionName}</List>
                      ))}
                    </Span>
                  </Row>
                  <Row>
                    <RowHeader>
                      <TranslatedText i18nKey="PAINT_TYPE">{(value) => <List>{value}</List>}</TranslatedText>
                    </RowHeader>
                    <Span>
                      {paintType.map((eq) => (
                        <List>{eq.optionName}</List>
                      ))}
                    </Span>
                  </Row>
                  <Row>
                    <RowHeader>
                      <TranslatedText i18nKey="COLOR">{(value) => <List>{value}</List>}</TranslatedText>
                    </RowHeader>
                    <Span>
                      {color.map((eq) => (
                        <List>{eq.optionName}</List>
                      ))}
                    </Span>
                  </Row>
                  <Row>
                    <RowHeader>
                      <TranslatedText i18nKey="INTERIOR">{(value) => <List>{value}</List>}</TranslatedText>
                    </RowHeader>
                    <Span>
                      {interior.map((eq) => (
                        <List>{eq.optionName}</List>
                      ))}
                    </Span>
                  </Row>
                  <Row>
                    <RowHeader>
                      <TranslatedText i18nKey="MISCELLANEOUS">{(value) => <List>{value}</List>}</TranslatedText>
                    </RowHeader>
                    <Span>
                      {misc.map((eq) => (
                        <List>{eq.optionName}</List>
                      ))}
                    </Span>
                  </Row>
                  <Row>
                    <RowHeader>
                      <TranslatedText i18nKey="NORWEGIAN_MOUNTED_ACCESSORIES">
                        {(value) => <List>{value}</List>}
                      </TranslatedText>
                    </RowHeader>
                    <Span>
                      {norwegianMountedOptions.map((eq) => (
                        <List>{eq.optionName}</List>
                      ))}
                    </Span>
                  </Row>
                </>
              ) : (
                <p>
                  <TranslatedText i18nKey="NO_OPTIONS_SELECTED" />
                </p>
              )}
            </LowerColumn>
          </LowerField>
          {chosenWheelPackage && (
            <LowerField>
              <LowerColumn>
                <SmallSummaryHeading>
                  <TranslatedText i18nKey="WINTER_TYRES" />
                </SmallSummaryHeading>
                <Row>
                  <Span>
                    {chosenWheelPackage.TireType} {chosenWheelPackage.TireManufacturer}{' '}
                    {`${chosenWheelPackage.RimSize}"`}
                  </Span>
                </Row>
              </LowerColumn>
            </LowerField>
          )}
          <SmallSummaryHeading>
            <TranslatedText i18nKey="CAR_CONFIG_ORDER_ADDITIONAL_INFO" />
          </SmallSummaryHeading>
          <UpperField>
            <UpperColumn>
              <div>
                <SmallSummaryHeading>
                  <TranslatedText i18nKey="CAR_CONFIG_ORDER_ADDITIONAL_INFO_NOTES_HEADING" />
                </SmallSummaryHeading>
                <Row>
                  <TextArea
                    placeholder={i18nInstance.translate('CAR_CONFIG_ORDER_ADDITIONAL_INFO_NOTES')}
                    onChange={(e) => {
                      if (e.target.value.length <= MAX_NOTES_LENGTH) {
                        notesSet(e.target.value)
                      }
                    }}
                    value={notes || additionalInformation?.notes}
                  >
                    {notes || additionalInformation?.notes}
                  </TextArea>
                </Row>
                <Row>
                  {notes.length} /{MAX_NOTES_LENGTH}{' '}
                  <TranslatedText i18nKey="CHARACTERS">{(value) => value.toLowerCase()}</TranslatedText>
                </Row>
                <Row>
                  <Input
                    id="customConfigurationName"
                    value={
                      customConfigurationName?.toString()?.length >= 0
                        ? customConfigurationName
                        : flow?.data?.customConfigurationName
                    }
                    onChange={(e) => customConfigurationNameSet(e.target.value)}
                    label={<TranslatedText i18nKey="CAR_CONFIG_CUSTOM_NAME_DESC" />}
                  />
                </Row>
              </div>
            </UpperColumn>
            <UpperColumn>
              <div>
                <SmallSummaryHeading>
                  <TranslatedText i18nKey="CAR_CONFIG_ORDER_ADDITIONAL_INFO_DELIV_DATE" />
                </SmallSummaryHeading>
                <Row>
                  <CustomDatePicker
                    selected={startDate || new Date()}
                    onChange={(date) => {
                      startDateSet(date)
                    }}
                    language={i18nInstance?.language}
                  />
                </Row>
                <SmallSummaryHeading>
                  <TranslatedText i18nKey="CONFIGURATION_REPLACES_VEHICLE" />
                </SmallSummaryHeading>
                <Row>
                  {chosenReplacingCar ? (
                    <Link to={`/cars/${chosenReplacingCar?.registrationNumber}`} target="_blank">
                      {chosenReplacingCar?.description}
                    </Link>
                  ) : (
                    <TranslatedText i18nKey="NO_REPLACING_CAR_SELECTED" />
                  )}
                </Row>
              </div>
            </UpperColumn>
          </UpperField>
          <SmallSummaryHeading>
            <TranslatedText i18nKey="SHIPPING" />
          </SmallSummaryHeading>
          <UpperField>
            <UpperColumn>
              <Row>
                <TranslatedText i18nKey="SHIPPING_IS_ADDED" />
              </Row>
            </UpperColumn>
          </UpperField>
          <FormError errors={formError} setRequiredSelections={setRequiredSelections} navigate={updateFlowHandler} />
          <Navigation updateFlowHandler={updateFlowHandler} buttonNames={{ next: 'FINALIZE_CONFIGURATION' }} />
        </div>
      </Wrapper>
      <Toast limit={1} />
    </Container>
  )
}

export default Summary

const TextArea = styled.textarea`
  font-size: var(--font-h3);
  resize: vertical;
  border: 1px solid var(--color-line);
  border-radius: var(--spacing-micro);
  width: 100%;
  height: 80px;
  max-height: 150px;
  padding: var(--spacing-tiny);
  white-space: pre-line;

  :focus {
    border: 2px solid var(--color-primary);
  }

  transition: 0.2s linear;
`

const UpperField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--spacing-large);
  background-color: var(--color-white);
  margin: var(--spacing-small) 0;
  padding: 0 var(--spacing-medium);
`

const LowerField = styled.div`
  background-color: var(--color-white);

  > div {
    padding: var(--spacing-medium);
  }

  margin-bottom: var(--spacing-medium);
`

const UpperColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: var(--spacing-medium) var(--spacing-medium) var(--spacing-medium) 0;
  width: 100%;

  > div:not(:only-child) {
    box-shadow: 0 var(--spacing-microMinus) 0 0 var(--color-line);
  }
`

const LowerColumn = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:only-child) {
    box-shadow: 0 var(--spacing-microMinus) 0 0 var(--color-line);
  }
`

const List = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 var(--spacing-micro) var(--spacing-micro) 0;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-micro) 0;
`

const AgreementRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: var(--spacing-micro) 0;
`

const RowHeader = styled.span`
  text-align: left;
  overflow-wrap: break-word;
`

const Span = styled.span`
  text-align: right;
  overflow-wrap: break-word;
`

const Link = styled(NavLink)`
  text-decoration: none;
  color: var(--color-primary);
`
