import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, RadioButton } from '@staccx/bento'
import styled, { css } from 'styled-components'
import { i18nInstance, TranslatedText } from '@staccx/i18n'
import 'react-loading-skeleton/dist/skeleton.css'
import { useParams } from 'react-router-dom'
import CustomSkeleton from '../components/skeleton/skeleton'
import sortByKey from '../../../utils/sortByKey'
import useCarConfiguration, { CHOOSE_CAR } from '../hooks/useCarConfiguration'
import DropDown from '../../../components/DropDownMenu'
import useCarConfigurationQueries from '../hooks/useCarConfigurationQueries'
import { useContacts } from '../../../context/DriverProvider'
import { useUserContext } from '../../../context/UserProvider'
import { useFlow } from '../../../context/FlowProvider'
import { useActiveCars, useCarContext, useOutgoingCars } from "../../../context/CarProvider"
import Car from '../components/Car'
import Container from '../../../components/Container'
import Toast from '../components/toast/Toast'
import CarConfigurationExtraInformation from '../components/CarConfigurationExtraInformation'
import DialogConfirmChangingCar from '../components/DialogConfirmChangingCar'
import { ReportHeading } from '../../../theme/Theme.Text'

const val = (lang) => ({ value: lang, label: lang })

const ChooseCarVariant = () => {
  const { roleType } = useUserContext()
  const { flowId } = useParams()
  const { flow, refetch } = useFlow(flowId)
  const { currentUsersCar } = useCarContext()
  const outgoingCars = useOutgoingCars()
  const activeCars = useActiveCars()
  const replaceableCars = [...(outgoingCars || []), ...(activeCars || [])]

  const { errorHandler, companyId, isRedirecting, isLoading, saveAndNavigate } = useCarConfiguration({
    page: CHOOSE_CAR,
    flow,
    refetch,
  })

  const { vehicleType: vType, carConfiguration, chosenDriver, chosenReplacingCar } = flow?.data || {}

  const [vehicleType, vehicleTypeSet] = useState(vType || '')
  const [make, makeSet] = useState(carConfiguration?.makeKey || null)
  const [model, modelSet] = useState(carConfiguration?.modelKey || null)
  const [driver, driverSet] = useState(chosenDriver || null)
  const [car, carSet] = useState(chosenReplacingCar || currentUsersCar)
  const [isChangingCar, isChangingCarSet] = useState(false)
  const [savedPayload, savedPayloadSet] = useState(null)
  const previouslySelectedVehicleId = useRef(carConfiguration?.vehicleId || null)

  const onSelectCar = ({ body }) => {
    const selectedNewCar = carConfiguration ? !body?.vehicleIDs?.includes(carConfiguration?.vehicleId) : false
    const selectedSameCar = body?.vehicleIDs?.includes(carConfiguration?.vehicleId)
    isChangingCarSet(selectedNewCar)

    const rawDriver = driver ? (contactsRaw || []).find((d) => d.id === driver.value) : null
    const rawCar = car ? (replaceableCars || []).find((c) => c.accountNo === car.value) : null

    const payload = {
      ...(selectedSameCar
        ? {
            carConfiguration,
            vehicleType: vType,
          }
        : {
            carConfiguration: body,
            vehicleType,
          }),
      direction: 'next',
      chosenDriver: rawDriver || null,
      chosenReplacingCar: rawCar
        ? {
            accountNo: rawCar?.accountNo,
            registrationNumber: rawCar?.registrationNumber,
            description: rawCar?.description,
            plannedReturnDate: rawCar?.plannedReturnDate,
            driverName: rawCar?.driverName,
          }
        : null,
    }

    if (selectedNewCar) {
      savedPayloadSet(payload)
      return
    }

    saveConfiguration(payload)
  }

  const saveConfiguration = (payload) => saveAndNavigate.mutate(payload)
  const saveWithNewConfiguration = () => saveAndNavigate.mutate(savedPayload)
  const saveWithRetainConfiguration = () =>
    saveAndNavigate.mutate({ ...savedPayload, carConfiguration, vehicleType: vType })

  useEffect(() => {
    if (!flow) {
      return
    }
    vehicleTypeSet(vType || '')
    makeSet(carConfiguration?.makeKey || '')
    modelSet(carConfiguration?.modelKey || '')
    previouslySelectedVehicleId.current = carConfiguration?.vehicleId || null
  }, [flow])

  const vehicleTypeHandler = (type) => {
    if (type !== vehicleType) {
      vehicleTypeSet(type)
      makeSet(null)
      modelSet(null)
      errorHandler(null)
    }
  }

  const makeHandler = (m) => {
    makeSet(m)
    modelSet(null)
    errorHandler(null)
  }

  const { makesRaw, modelsRaw, bodiesRaw } = useCarConfigurationQueries(CHOOSE_CAR, errorHandler, {
    vehicleType,
    make,
    model,
    companyId,
  })
  const { error: makesError } = makesRaw
  const { error: modelsError } = modelsRaw
  const { error: bodiesError } = bodiesRaw

  const makes = useMemo(() => {
    if (!makesRaw.data) {
      return null
    }
    return Object.values(makesRaw.data)
      .map((i) => i.makeKey)
      .sort()
  }, [makesRaw.data])

  const models = useMemo(() => {
    if (!modelsRaw.data) {
      return null
    }
    return [...new Set(Object.values(modelsRaw.data).map((i) => i.modelKey))].sort()
  }, [modelsRaw.data])

  const bodies = useMemo(() => {
    if (!bodiesRaw.data) {
      return null
    }
    return sortByKey(bodiesRaw.data[0].selections, 'shortBodyName')
  }, [bodiesRaw.data])

  const { contacts: contactsRaw } = useContacts()

  if (isRedirecting) {
    return null
  }

  return (
    <Container isLoading={isLoading}>
      <TranslatedText i18nKey={'CONFIGURE_CAR_HEADING'}>
        {(value) => <ReportHeading noMargin>{value}</ReportHeading>}
      </TranslatedText>
      <DialogConfirmChangingCar
        isOpen={isChangingCar}
        handleIsOpen={isChangingCarSet}
        heading="CONFIRM_DIALOG_CHANGE_BASE_CAR"
        body="THIS_INCLUDES"
        selectedCar={savedPayload}
        onRetainConfiguration={saveWithRetainConfiguration}
        onConfirmChange={saveWithNewConfiguration}
      />
      <CarContainer>
        <SelectCarType>
          <CarConfigurationExtraInformation
            roleType={roleType}
            cars={replaceableCars}
            drivers={contactsRaw}
            car={car || chosenReplacingCar}
            driver={driver || chosenDriver}
            userCar={currentUsersCar}
            carHandler={carSet}
            driverHandler={driverSet}
          />
          <CarTypeToggle>
            <TranslatedText i18nKey="VEHICLE_TYPE">{(value) => <Span>{value}</Span>}</TranslatedText>
            <div>
              <VehicleButton
                variant="toggleButton"
                active={vehicleType === 'car'}
                onClick={() => vehicleTypeHandler('car')}
              >
                <TranslatedText i18nKey="PASSENGER_CAR" />
              </VehicleButton>
              <VehicleButton
                variant="toggleButton"
                active={vehicleType === 'lightCommercialVehicle'}
                onClick={() => vehicleTypeHandler('lightCommercialVehicle')}
              >
                <TranslatedText i18nKey="LIGHT_COMMERCIAL_VEHICLE" />
              </VehicleButton>
            </div>
          </CarTypeToggle>
          <Makes>
            {vehicleType && <TranslatedText i18nKey="MAKE">{(value) => <Span>{value}</Span>}</TranslatedText>}
            {makes ? (
              <DropDown
                options={makes.map((make) => val(make))}
                onChange={(e) => makeHandler(e.value)}
                value={make ? val(make) : null}
                placeholder={i18nInstance.translate('CHOOSE_MAKE')}
                overlap={true}
              />
            ) : (
              vehicleType &&
              !makesError && (
                <CustomSkeleton
                  skeletons={[
                    {
                      height: 30,
                      count: 1,
                    },
                  ]}
                  theme={css`
                    background-color: var(--color-bg);
                    grid-template-columns: 1fr;
                  `}
                />
              )
            )}
          </Makes>
          <Models>
            {make && <TranslatedText i18nKey="MODEL">{(value) => <Span>{value}</Span>}</TranslatedText>}
            {models ? (
              <div>
                {models.map((m, i) => (
                  <div onClick={() => modelSet(m)}>
                    <RadioButton group="models" id={m} key={m} value={m} checked={model && model === m}>
                      {m}
                    </RadioButton>
                  </div>
                ))}
              </div>
            ) : (
              make &&
              !modelsError && (
                <CustomSkeleton
                  skeletons={[
                    {
                      width: 20,
                      height: 20,
                      count: 3,
                    },
                    {
                      height: 20,
                      count: 3,
                    },
                  ]}
                />
              )
            )}
          </Models>
          {modelsError && (
            <TranslatedText i18nKey={'NO_AVAILABLE_MODELS_ERROR'}>{(value) => <div>{value}</div>}</TranslatedText>
          )}
        </SelectCarType>
        <div style={{ backgroundColor: 'var(--color-line)' }} />
        <RightColumn>
          {model && <TranslatedText i18nKey={'CHOOSE_CAR'}>{(value) => <Span>{value}</Span>}</TranslatedText>}
          <Bodies>
            {bodies
              ? bodies.map((body) => (
                  <Car
                    flow={{ data: { carConfiguration: body } }}
                    action={() => onSelectCar({ body })}
                    banner={true}
                    isSelected={body?.vehicleIDs?.includes(carConfiguration?.vehicleId)}
                  />
                ))
              : model &&
                !bodiesError && (
                  <CustomSkeleton
                    skeletons={[
                      {
                        width: 270,
                        height: 70,
                      },
                    ]}
                    theme={css`
                      background-color: var(--color-bg);
                      grid-template-columns: 1fr;
                    `}
                  />
                )}
          </Bodies>
        </RightColumn>
      </CarContainer>
      <Toast limit={1} />
    </Container>
  )
}

export default ChooseCarVariant

const Span = styled.span`
  font-size: var(--font-h3);
  font-weight: var(--fontWeight-bold);
  line-height: 1.5em;
`

const RightColumn = styled.div`
  width: fit-content;
`

const Bodies = styled.div`
  width: 100%;
  display: grid;
  gap: var(--spacing-large);
`

const CarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  width: 100%;
  height: fit-content;
  gap: var(--spacing-mediumPlus);
  padding-top: var(--spacing-large);
`
const SelectCarType = styled.div`
  display: grid;
  justify-self: end;
  width: 100%;
  align-self: start;
  gap: var(--spacing-medium);
`
const CarTypeToggle = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    width: 100%;

    > button {
      width: 100%;
    }
  }

  button:first-child {
    border-radius: var(--borderRadius) 0 0 var(--borderRadius);
  }

  button:last-child {
    border-radius: 0 var(--borderRadius) var(--borderRadius) 0;
  }
`

const VehicleButton = styled(Button)`
  font-size: var(--font-h3);
  height: var(--spacing-large);
`

const Models = styled.div`
  > div {
    background-color: var(--color-bg);
    border: var(--spacing-microMinus) solid var(--color-line);

    > div {
      cursor: pointer;
      padding: 0 var(--spacing-smallPlus);

      :not(:only-child) {
        box-shadow: 0 var(--spacing-microMinus) 0 0 var(--color-line);
      }
    }
  }
`

const Makes = styled.div`
  > div {
    background-color: var(--color-bg);
  }
`
