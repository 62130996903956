import React, { useMemo } from 'react'
import { Heading, Label, Layout } from '@staccx/bento'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import DropDown from '../../../components/DropDownMenu'
import { TranslatedText } from '@staccx/i18n'
import EnvironmentReportTable from '../components/EnvironmentReportTable'
import { formatNumber } from '../../../utils/tableFuncs'
import Table from '../../../components/tables/Table'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useUserContext } from '../../../context/UserProvider'
import { useQuery } from 'react-query'
import { fetchEnvironmentReport } from '../../../api/api'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'
import StatsTable from '../components/StatsTable'
import { EnvironmentReportGraph } from '../../../components/EnvironmentReportGraph'

const EnvironmentReport = () => {
  const { chosenCompany: company, environmentReportYear, handleEnvironmentReportYear } = useUserContext()
  const companyId = company && company.id

  const { data, status } = useQuery(['environmentReport', companyId], () => fetchEnvironmentReport(companyId), {
    enabled: !!companyId,
    onSuccess: (data) => data.forEach((doc) => (doc.litersPerTenKm = (doc.volume / doc.totalKm) * 10))
  })

  const environmentReport = useMemo(() => {
    if (!data) {
      return []
    }
    return data.filter((report) => report.year.toString() === environmentReportYear)
  }, [environmentReportYear, data])

  const environmentReportYears = useMemo(() => {
    if (!data) {
      return []
    }
    return [...new Set(data.map((row) => row.year))].sort((a, b) => b - a)
  }, [data])

  const agreementFilter = useFilterOnConsolidateAgreement()
  const filteredReport = environmentReport.filter(agreementFilter('consolidatedInvoiceAgreement'))

  const averageCo2PerCar =
    filteredReport.length > 0
      ? filteredReport.reduce((total, current) => total + current.co2Emission, 0) / filteredReport.length
      : 0

  const averageNoxPerCar =
    filteredReport.length > 0
      ? filteredReport.reduce((total, current) => total + current.nOXEmission, 0) / filteredReport.length
      : 0

  const statsData = [{ averageCo2PerCar, averageNoxPerCar }]

  const items = environmentReportYears.map((env) => ({ value: env, label: env }))

  const mockEnviormentReport = [
  {
    regNo: "XYZ123",
    vehicleID: "V123",
    invoiceGroup: "Group A",
    carDescription: "Sedan",
    totalKm: 5000,
    volume: 50.25,
    fuelType: "Gasoline",
    litersPerTenKm: 8.75,
    co2Emission: 120.5,
    nOXEmission: "20 g"
  },
  {
    regNo: "ABC456",
    vehicleID: "V456",
    invoiceGroup: "Group B",
    carDescription: "SUV",
    totalKm: 4000,
    volume: 45.75,
    fuelType: "Diesel",
    litersPerTenKm: 9.25,
    co2Emission: 130.75,
    nOXEmission: "22 g"
  },
  {
    regNo: "DEF789",
    vehicleID: "V789",
    invoiceGroup: "Group C",
    carDescription: "Hatchback",
    totalKm: 6000,
    volume: 55.50,
    fuelType: "Electric",
    litersPerTenKm: 7.50,
    co2Emission: 110.25,
    nOXEmission: "18 g"
  }
]

  return (
    <Container width="unlimited">
      <Layout rowGap={'small'}>
        <TranslatedText i18nKey={'ENVIRONMENTREPORT_HEADING'}>
          {(value) => <Heading level="1">{value}</Heading>}
        </TranslatedText>
        <Layout variant="fiftyFifty">
          <TranslatedText i18nKey="TERM">
            {(value) => (
              <div>
                <Label>{value}</Label>
                <DropDown
                  value={{ value: environmentReportYear, label: environmentReportYear }}
                  options={items}
                  onChange={(selectedItem) => handleEnvironmentReportYear(selectedItem.value)}
                />
              </div>
            )}
          </TranslatedText>
          <ConsolidateInvoiceSelect />
        </Layout>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <EnvironmentReportGraph data={{averageCo2PerCar, averageNoxPerCar}}/>
      <Layout rowGap={'medium'}>
        <EnvironmentReportTable isLoading={status === 'loading'} data={mockEnviormentReport} />
      </Layout>
    </Container>
  )
}

export default EnvironmentReport
