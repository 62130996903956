import React from 'react'
import { useQuery } from 'react-query'
import { Heading, Text } from '@staccx/bento'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import { TranslatedText } from '@staccx/i18n'
import { fetchTollTransactionDetails } from '../../../api/api'
import { useParams } from 'react-router-dom'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'
import TollReportAccountDetailsTable from '../components/TollTables/TollReportAccountDetails'

const TollReportAccountDetails = () => {
  const { year, month, account } = useParams()

  const { data = [], isLoading } = useQuery(['tollReportAccountDetails', year, month, account], () =>
    fetchTollTransactionDetails({ year, month, account })
  )

  return (
    <Container width="unlimited">
      <TranslatedText i18nKey={'TOLLREPORT_DETAILS_HEADING'}>
        {(value) => <Heading level="1">{value}</Heading>}
      </TranslatedText>
      <TranslatedText i18nKey="PERIOD">
        {(value) => (
          <Text level="4">
            {value} {year}-{month}
          </Text>
        )}
      </TranslatedText>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <TollReportAccountDetailsTable isLoading={isLoading} data={data} />
    </Container>
  )
}

export default TollReportAccountDetails
