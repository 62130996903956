import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  .content {
    visibility: hidden;
  }

  :hover {
    .content {
      visibility: visible;
    }
  }
`

const Trigger = styled.div`
  position: relative;
  cursor: pointer;
`

const Content = styled.div`
  z-index: 100;
  width: 250px;
  word-break: break-word;
  text-overflow: ellipsis;

  padding: var(--spacing-smallPlus);
  margin: var(--spacing-tinyPlus);
  background-color: var(--color-bg);
  border: 2.5px solid var(--color-primary);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  position: absolute;
  ${(props) =>
    props.contentAlign === 'top'
      ? css`
          bottom: 100%;
        `
      : css`
          top: 100%;
        `};
`

const TopArrow = styled.div`
  content: '';
  position: absolute;
  left: 50%;
  bottom: -20%;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 11px solid var(--color-primary);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
`

const TopArrowFill = styled.div`
  content: '';
  position: absolute;
  bottom: 3.5px;
  left: -8px;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 9px solid var(--color-white);
  border-left: 8.5px solid transparent;
  border-right: 8.5px solid transparent;
`

const BottomArrow = styled.div`
  content: '';
  position: absolute;
  left: 50%;
  top: -20%;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-bottom: 11px solid var(--color-primary);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
`

const BottomArrowFill = styled.div`
  content: '';
  position: absolute;
  top: 3.5px;
  left: -8px;
  border-bottom: 9px solid var(--color-white);
  border-left: 8.5px solid transparent;
  border-right: 8.5px solid transparent;
`

export default ({ trigger, children, contentAlign = 'top' }) => {
  return (
    <Wrapper>
      <Trigger className="trigger">{trigger}</Trigger>
      <Content className="content" contentAlign={contentAlign}>
        {contentAlign === 'top' ? (
          <TopArrow>
            <TopArrowFill />
          </TopArrow>
        ) : (
          <BottomArrow>
            <BottomArrowFill />
          </BottomArrow>
        )}
        {children}
      </Content>
    </Wrapper>
  )
}
