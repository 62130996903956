import React, { Component } from "react"
import { easeBackOut, easeLinear, scaleLinear, select } from "d3"
import PropTypes from "prop-types"
import { formatDate, Layout, Text } from "@staccx/bento"

class Mileage extends Component {
  constructor(props, context) {
    super(props, context)
    this.reRender = this.reRender.bind(this)
  }

  componentDidMount() {
    this.reRender()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.car !== this.props.car) {
      this.reRender()
    }
  }

  reRender() {
    clearTimeout(this.timeoutId)

    if (!this.props.car) {
      return
    }

    this.timeoutId = setTimeout(() => {
      const node = this.node

      const width = node.width.baseVal.value

      this.reset()

      const { radius, animationTime, strokeWidth, lineHeight, car } = this.props
      const maxMileage = Math.max(car.simulatedMileage, car.contractMileage)
      const mileageScale = scaleLinear()
        .domain([0, maxMileage])
        .range([radius, width - radius])

      this.animateCircleIn(
        "start",
        mileageScale(0),
        radius,
        0,
        animationTime,
        300,
        easeBackOut
      )
      this.animateCircleIn(
        "circle-current",
        mileageScale(car.mileage),
        radius - strokeWidth,
        strokeWidth,
        animationTime,
        500,
        easeBackOut
      )
      this.animateCircleIn(
        "circle-pulse",
        mileageScale(car.mileage),
        radius * 1.5,
        2,
        animationTime,
        550,
        easeBackOut
      )

      const contractMileage = Math.max(car.contractMileage, car.mileage)
      this.animateCircleIn(
        "circle-rest",
        mileageScale(contractMileage),
        radius,
        0,
        animationTime,
        700,
        easeBackOut
      )
      if (car.simulatedMileage >= maxMileage) {
        this.animateCircleIn(
          "circle-simulated",
          mileageScale(car.simulatedMileage),
          radius - strokeWidth,
          strokeWidth,
          animationTime,
          900,
          easeBackOut
        )
      }

      const padding = radius * 0.5

      this.drawLine(
        "current",
        radius,
        mileageScale(car.mileage) - padding,
        lineHeight,
        animationTime,
        1000
      )
      this.drawLine(
        "rest",
        mileageScale(car.mileage),
        mileageScale(contractMileage) - padding,
        lineHeight,
        animationTime,
        1000 + animationTime
      )
      if (car.simulatedMileage >= maxMileage) {
        this.drawLine(
          "simulated",
          mileageScale(contractMileage) + padding,
          mileageScale(car.simulatedMileage) - padding,
          lineHeight,
          animationTime,
          1000 + animationTime + animationTime
        )
      }
      setTimeout(() => this.pulsate(radius * 0.6, 3, 1500), 700)
    })
  }

  reset() {
    this.drawLine("simulated", 0, 0, 0, 0)
    this.drawLine("rest", 0, 0, 0, 0)
    this.drawLine("current", 0, 0, 0, 0)
    this.animateCircleIn("circle-simulated", 0, 0, 0, 0, 0, easeBackOut)
    this.animateCircleIn("circle-rest", 0, 0, 0, 0, 0, easeBackOut)
    this.animateCircleIn("circle-pulse", 0, 0, 0, 0, 0, easeBackOut)
    this.animateCircleIn("circle-current", 0, 0, 0, 0, 0, easeBackOut)
    this.animateCircleIn("start", 0, 0, 0, 0, 0, easeBackOut)
  }

  pulsate(radius, scale, time) {
    const circle = select("#circle-pulse")

    const repeat = () => {
      circle
        .attr("r", radius)
        .style("opacity", 1)
        .transition()
        .duration(time)
        .ease(easeLinear)
        .attr("r", radius * scale)
        .style("opacity", 0)
        .on("end", repeat)
    }
    repeat()
  }

  drawLine(name, from, to, height, time, delay) {
    select(`#${name}`)
      .attr("x1", from)
      .attr("x2", from)
      .transition()
      .ease(easeLinear)
      .duration(time)
      .delay(delay)
      .attr("x2", to)
  }

  animateCircleIn(
    name,
    x,
    radius,
    strokeWidth,
    animationTime,
    delay,
    easeFunction
  ) {
    select(`#${name}`)
      .attr("cx", x)
      .transition()
      .duration(animationTime)
      .ease(easeFunction)
      .delay(delay)
      .attr("r", radius)
      .style("stroke-width", strokeWidth)
  }

  render() {
    const { car } = this.props
    return (
      <Layout>
        {car && <Text>{`${car.mileage} km av ${car.contractMileage} km`}</Text>}
        <svg
          width={"100%"}
          height={this.props.height}
          ref={node => (this.node = node)}
        >
          <g>
            <line
              id="current"
              x1="0"
              y1="15"
              x2="0"
              y2="15"
              style={{
                stroke: "rgb(42, 109, 244)",
                strokeWidth: 5
              }}
            />
            <line
              id="rest"
              x1="0"
              y1="15"
              x2="0"
              y2="15"
              style={{
                stroke: "rgba(42, 109, 244, 0.3)",
                strokeWidth: 5
              }}
            />
            <line
              id="simulated"
              x1="0"
              y1="15"
              x2="0"
              y2="15"
              style={{
                stroke: "rgba(255, 91, 48, 0.5)",
                strokeWidth: 5,
                strokeDasharray: 1
              }}
            />
            <circle
              cx="6"
              cy="15"
              r="0"
              id={"start"}
              style={{
                fill: "rgb(42, 109, 244)"
              }}
            />

            <circle
              cx="30"
              cy="15"
              r="0"
              id={"circle-current"}
              style={{
                fill: "transparent",
                stroke: "rgb(42, 109, 244)",
                strokeWidth: 4.2
              }}
            />

            <circle
              cx="30"
              cy="15"
              r="0"
              id={"circle-pulse"}
              style={{
                fill: "transparent",
                stroke: "rgba(70, 126, 255, 0.8)",
                strokeWidth: 1.5
              }}
            />

            <circle
              cx="60"
              cy="15"
              r="0"
              id={"circle-rest"}
              style={{
                fill: "rgb(42, 109, 244)"
              }}
            />
            <circle
              cx="90"
              cy="15"
              r="0"
              id={"circle-simulated"}
              style={{
                fill: "transparent",
                stroke: "rgb(255, 91, 48)",
                strokeWidth: 4.2
              }}
            />
          </g>
        </svg>
        {car && (
          <Text>{`Simulert ${formatDate(new Date())} ${
            car.simulatedMileage
          } km`}</Text>
        )}
      </Layout>
    )
  }
}

Mileage.propTypes = {
  animationTime: PropTypes.number,
  car: PropTypes.object,
  height: PropTypes.string,
  radius: PropTypes.number,
  strokeWidth: PropTypes.number
}

export default Mileage

Mileage.defaultProps = {
  animationTime: 500,
  height: "25px",
  radius: 10,
  strokeWidth: 3
}
