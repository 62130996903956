import React from 'react'
import { useQuery } from 'react-query'
import { useUserContext } from '../../../context/UserProvider'
import { Heading, Layout } from '@staccx/bento'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import { fetchFleetReport, fetchFleetReportTerms } from '../../../api/api'
import { TranslatedText } from '@staccx/i18n'
import LeasingReportTable from '../components/LeasingReportTable'
import ReportTermSelect from '../components/ReportTermSelect'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'

const LeasingReport = () => {
  const { chosenCompany: company, fleetReportTerm } = useUserContext()
  const companyId = company && company.id
  const { data: terms = [] } = useQuery(['fleetReportTerms', companyId], () => fetchFleetReportTerms(companyId), {
    enabled: !!companyId
  })

  const { data = [], status } = useQuery(
    ['fleetReport', companyId, fleetReportTerm],
    () => fetchFleetReport(companyId, fleetReportTerm, 'leasing'),
    { enabled: !!companyId }
  )

  const agreementFilter = useFilterOnConsolidateAgreement()

  const mockLeasingReport = [
  {
    accountID: "INV123",
    regNo: "ABC123",
    vehicleID: "V123",
    consolidatedAgreementID: "329-4324",
    consolidatedAgreement: "Agreement 1",
    driverName: "John Doe",
    maxKm: 10000,
    totalTerms: 36,
    termsNow: 24,
    remainingTerms: 12,
    startDate: "2022-01-01",
    returnDate: "2025-01-01",
    termLeasing_incl_VAT: 5000,
    restLeasing_incl_VAT: 3000,
    restLeasing_excl_VAT: 2500
  },
  {
    accountID: "INV456",
    regNo: "DEF456",
    vehicleID: "V456",
    consolidatedAgreement: "Agreement 2",
    consolidatedAgreementID: "329-4325",
    driverName: "Jane Smith",
    maxKm: 12000,
    totalTerms: 48,
    termsNow: 36,
    remainingTerms: 12,
    startDate: "2021-06-15",
    returnDate: "2024-06-15",
    termLeasing_incl_VAT: 6000,
    restLeasing_incl_VAT: 4000,
    restLeasing_excl_VAT: 3500
  },
  {
    accountID: "INV789",
    regNo: "GHI789",
    consolidatedAgreementID: "329-4326",
    vehicleID: "V789",
    consolidatedAgreement: "Agreement 3",
    driverName: "Alex Johnson",
    maxKm: 8000,
    totalTerms: 24,
    termsNow: 12,
    remainingTerms: 12,
    startDate: "2023-03-10",
    returnDate: "2026-03-10",
    termLeasing_incl_VAT: 4500,
    restLeasing_incl_VAT: 2000,
    restLeasing_excl_VAT: 1800
  }
]

  return (
    <Container width="unlimited">
      <Layout rowGap={'small'}>
        <TranslatedText i18nKey={'LEASINGREPORT_HEADING'}>
          {(value) => <Heading level="1">{value}</Heading>}
        </TranslatedText>
        <Layout variant="fiftyFifty">
          <TranslatedText i18nKey="TERM">
            {(value) => <ReportTermSelect label={value} reportTerms={terms} />}
          </TranslatedText>
          <ConsolidateInvoiceSelect />
        </Layout>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <LeasingReportTable
        isLoading={status === 'loading'}
        data={mockLeasingReport}
      />
    </Container>
  )
}

export default LeasingReport
