import React from 'react';

const Tick = () => {
  return <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8336 0L4.73544 5.98475L1.56441 2.87268L0.199951 4.21364L4.73544 8.66667L12.2 1.34096L10.8336 0Z"
          fill="white"/>
  </svg>
}

export default Tick;
