import { BarChart } from '@mui/x-charts'
import { Box, Heading } from '@staccx/bento'
import styled from 'styled-components'

export function InTransit({  }) {
  const listOfCars = [
    {
      name: "Hyundai Ioniq 5",
      status: "",
      statusType: "",
    },
        {
      name: "Volkswagen ID.BUZZ",
      status: "",
      statusType: "",
    },
        {
      name: "Volkswagen ID.BUZZ",
      status: "",
      statusType: "",
    },
        {
      name: "Kia EV9",
      status: "",
      statusType: "",
    },
  ]
  return (
    <StyledContainer>
      <HeaderContainer>
        {listOfCars.map((car, index) => {
          return (<CarItem>
            <p>{car?.name}</p>
            <div>
              <p>{car?.status}</p>
            </div>
          </CarItem>)
        })}
      </HeaderContainer>
     
    </StyledContainer>
  )
}

const CarItem = styled.div`
 display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`


const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  padding: 1rem;
`   

const StyledContainer = styled.div`
  background-color: white;
  width: 100%;
  padding: 1rem;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1rem;
`
