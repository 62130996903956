import React from 'react'
import { useQuery } from 'react-query'
import { Heading, Label, Layout, LayoutItem, Text } from '@staccx/bento'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import { TranslatedText } from '@staccx/i18n'
import Table from '../../../components/tables/Table'
import { formatCurrency, formatNumber, sum } from '../../../utils/tableFuncs'
import { NavLink, useParams } from 'react-router-dom'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import { fetchFuelReportDetails } from '../../../api/api'
import { useChosenCompany } from '../../../context/UserProvider'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'
import FuelReportDetailsTable from '../components/FuelTables/FuelReportDetails'

const headers = (options) => [
  {
    i18nKey: 'REGISTRATION_NUMBER',
    accessor: 'regNo',
    contentFunction: (row) => {
      return (
        <NavLink to={`/reports/fuel/expense/${row.original.accountNo}/${options.year}/${options.month}`}>
          {row.value}
        </NavLink>
      )
    },
  },
  {
    i18nKey: 'VEHICLE_ID',
    accessor: 'vehicleID',
  },
  {
    i18nKey: 'INVOICE_GROUP',
    accessor: 'invoiceGroupingDescription',
  },
  {
    i18nKey: 'OBJECT',
    accessor: 'carDescription',
  },
  {
    i18nKey: 'DRIVER',
    accessor: 'driverName',
  },
  {
    i18nKey: 'CURRENCY_VALUE',
    accessor: 'amountincVAT',
    contentFunction: (row) => formatCurrency(row.value),
    Footer: ({ data }) => formatCurrency(sum(data, 'amountincVAT')),
  },
  {
    i18nKey: 'VOLUME',
    accessor: 'volume',
    contentFunction: (row) => formatNumber(row.value),
    Footer: ({ data }) => formatNumber(sum(data, 'volume')),
  },
  {
    i18nKey: 'ATTENTION',
    id: 'obsDescription',
    contentFunction: (row) => `${row.original.obs} - ${row.original.obsDescription}`,
  },
]

const FuelReportDetails = () => {
  const company = useChosenCompany()
  const companyId = company && company.id
  const { year, month } = useParams()

  const { data = [], isLoading } = useQuery(
    ['fuelReportDetails', companyId, year, month],
    () => fetchFuelReportDetails(company.id, year, month),
    { enabled: !!companyId }
  )

  const agreementFilter = useFilterOnConsolidateAgreement()
  const filterFunc = agreementFilter('consolidatedInvoiceAgreement')

  return (
    <Container width="unlimited">
      <TranslatedText i18nKey={'FUELREPORT_DETAILS_HEADING'}>
        {(value) => <Heading level="1">{value}</Heading>}
      </TranslatedText>
      <Layout variant="fiftyFifty">
        <LayoutItem>
          <TranslatedText i18nKey="PERIOD">{(value) => <Label>{value}</Label>}</TranslatedText>
          <Text>
            {year}-{month}
          </Text>
        </LayoutItem>
        <LayoutItem>
          <ConsolidateInvoiceSelect />
        </LayoutItem>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <FuelReportDetailsTable isLoading={isLoading} options={{ year, month }} data={data.filter(filterFunc)} />
    </Container>
  )
}

export default FuelReportDetails
