import React from 'react'
import { Button } from '@staccx/bento'
import styled from 'styled-components'
import { TranslatedText } from '@staccx/i18n'
import Mileage from './visuals/Mileage'
import FuelVolume from './visuals/FuelVolume'
import FuelCard from './visuals/FuelCard'
import Tollbooth from './visuals/Tollbooth'
import eventFilter from '../../../constants/eventFilter'

const filterByEvent = (filter) => (e) => e.eventType === filter

const CarDetailsOverview = ({ car }) => {
  /*   const fuelVolumeData = car.events.filter(filterByEvent(eventFilter.refuel)).map((event) => event.volume)

  const purchaseData = car.events.filter(filterByEvent(eventFilter.purchase)).map((event) => event.price)

  const toolPassingData = car.events.filter(filterByEvent(eventFilter.tollPassing)).map((event) => event.amount)
 */
  return (
    <>
      <CarDetailBox>
        <Button variant="goto">
          <HeadingText>
            <TranslatedText i18nKey="MILAGE" />
          </HeadingText>
        </Button>
        {car.mileage && <Mileage car={car} radius={6} />}
      </CarDetailBox>
      {car?.fuelVolume && (
        <CarDetailBox>
          <Button variant="goto">
            <HeadingText>
              <TranslatedText i18nKey="FUEL_VOLUME" />
            </HeadingText>
          </Button>
          <FuelVolume radius={2} data={car?.fuelVolume} />
        </CarDetailBox>
      )}

      {car?.fuelCard && (
        <CarDetailBox>
          <Button variant="goto">
            <HeadingText>
              <TranslatedText i18nKey="FUELCARD" />
            </HeadingText>
          </Button>
          <FuelCard data={car?.fuelCard} />
        </CarDetailBox>
      )}
      {car?.tollBoothData && (
        <CarDetailBox>
          <Button variant="goto">
            <HeadingText>
              <TranslatedText i18nKey="TOLLBOOTH" />
            </HeadingText>
          </Button>
          <Tollbooth data={car?.tollBoothData} />
        </CarDetailBox>
      )}
    </>
  )
}

export default CarDetailsOverview

const HeadingText = styled.div`
  color: var(--color-primary);
`

const CarDetailBox = styled.div`
  border: 1px solid var(--color-grayLine);
  background-color: white;
  padding-right: var(--spacing-largeMinus);
  padding-left: var(--spacing-largeMinus);
  padding-top: var(--spacing-mediumMinus);
  padding-bottom: var(--spacing-mediumMinus);
`
