import styled, {keyframes} from "styled-components"
import { Button } from "@staccx/bento"
export function TaskItem({task, handleTaskClick}) {

    const isButtonDisabled = task.status === "Awaiting signing" ? false : true
    return (
        <TaskItemBox>
            <TaskItemWrapper>
            <div>
            <p>{task.car}</p>
            <span>Status: {task.status}</span>
            </div>
            <StyledButton onClick={() => handleTaskClick(task)} disabled={isButtonDisabled}>{isButtonDisabled ? "Awaiting " : "Sign"}</StyledButton>
            </TaskItemWrapper>
        </TaskItemBox>
    )
}

const TaskItemWrapper = styled.div`
display: flex;
gap: 1rem;
align-items: center;
justify-content: space-between;
width: 100%;
border-bottom: 1px solid gray;
padding: 1rem 0;

`

const StyledButton = styled(Button)`
    font-size: 14px;
    padding: 0 1rem;
            transition: all 0.1s ease-in-out;
            cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    &:hover {
        transition: all 0.1s ease-in-out;
        box-shadow: ${props => props.disabled ? "none" : "0px 3px 5px rgba(0, 0, 0, .3)"};
    }
`
const TaskItemBox = styled.div`
display: flex;
gap: 1rem;
align-items: center;
justify-content: space-between;
`

