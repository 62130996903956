import React from 'react'
import { TranslatedText } from '@staccx/i18n'
import { formatDate } from '../../../../utils/tableFuncs'

export default function eventListOverviewData() {
  return [
    {
      accessorKey: 'eventType',
      id: 'eventType',
      header: () => <TranslatedText i18nKey={'TYPE'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row }) => {
        const value = row.original.eventType
        let returnValue = null
        value === 'deviation'
          ? (returnValue = (
              <TranslatedText i18nKey={'DEVIATION_SINGULAR'}>{(value) => <span>{value}</span>}</TranslatedText>
            ))
          : (returnValue = '')
        return returnValue
      },
      meta: { alignmentType: 'string' }
    },
    {
      accessorKey: 'date',
      id: 'date',
      header: () => <TranslatedText i18nKey={'DATE'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row }) => {
        const date = row.original.date
        return date ? formatDate(date) : ''
      },
      meta: { alignmentType: 'date' }
    },
    {
      accessorKey: 'carId',
      id: 'carId',
      header: () => <TranslatedText i18nKey={'CAR_ID'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: ({ getValue }) => getValue() || '',
      meta: { alignmentType: 'string' }
    },
    {
      accessorKey: 'car.driverName',
      id: 'car.driverName',
      header: () => <TranslatedText i18nKey={'DRIVER'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: ({ getValue }) => getValue() || 'Fredrik Myhre',
      meta: { alignmentType: 'string' }
    },
    {
      accessorKey: 'description',
      id: 'decription',
      header: () => <TranslatedText i18nKey={'DESCRIPTION'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: ({ getValue }) => getValue || 'Speeding',
      meta: { alignmentType: 'string' }
    }
  ]
}
