import { css } from 'styled-components'
import { theming } from '@staccx/bento'

const reset = css`
  html {
    font-size: ${theming.font('base')};
    line-height: 1.2;
    color: ${theming.color`text`};
  }
  body {
    --color-primary: ${theming.color`primary`};
    --color-primaryLight: ${theming.color`primaryLight`};
    --color-secondary: ${theming.color`secondary`};
    --color-gray: ${theming.color`gray`};
    --color-bg: ${theming.color`bg`};
    --color-bgGray: ${theming.color`bgGray`};
    --color-text: ${theming.color`text`};
    --color-line: ${theming.color`line`};
    --color-shadow: ${theming.color`line`};
    --color-white: ${theming.color`white`};
    --color-wcag: ${theming.color`wcag`};
    --color-wcag: ${theming.color`wcag`};
    --color-disabled: ${theming.color`disabled`};
    --color-warning: ${theming.color`warning`};
    --color-subtleWarning: ${theming.color`subtleWarning`};
    --color-positive: ${theming.color`positive`};
    --color-negative: ${theming.color`negative`};
    --color-subtleHover: ${theming.color`subtleHover`};
    --color-black: ${theming.color`black`};
    --color-headerText: ${theming.color`headerText`};
    --color-headerBackground: ${theming.color`headerBackground`};
    --color-rowBackground: ${theming.color`rowBackground`};
    --color-rowLine: ${theming.color`rowLine`};
    --color-grayLine: ${theming.color`grayLine`};

    --spacing-menuAndFooter: ${theming.spacing`menuAndFooter`};
    --spacing-hugePlus: ${theming.spacing`hugePlus`};
    --spacing-huge: ${theming.spacing`huge`};
    --spacing-hugeMinus: ${theming.spacing`hugeMinus`};
    --spacing-largePlus: ${theming.spacing`largePlus`};
    --spacing-large: ${theming.spacing`large`};
    --spacing-largeMinus: ${theming.spacing`largeMinus`};
    --spacing-mediumPlus: ${theming.spacing`mediumPlus`};
    --spacing-medium: ${theming.spacing`medium`};
    --spacing-mediumMinus: ${theming.spacing`mediumMinus`};
    --spacing-smallPlus: ${theming.spacing`smallPlus`};
    --spacing-small: ${theming.spacing`small`};
    --spacing-smallMinus: ${theming.spacing`smallMinus`};
    --spacing-tinyPlus: ${theming.spacing`tinyPlus`};
    --spacing-tiny: ${theming.spacing`tiny`};
    --spacing-tinyMinus: ${theming.spacing`tinyMinus`};
    --spacing-microPlus: ${theming.spacing`microPlus`};
    --spacing-micro: ${theming.spacing`micro`};
    --spacing-microMinus: ${theming.spacing`microMinus`};
    --spacing-switchThumb: ${theming.spacing`switchThumb`};
    --spacing-switchHeight: ${theming.spacing`switchHeight`};
    --spacing-switchWidth: ${theming.spacing`switchWidth`};
    --spacing-navBarOffset: ${theming.spacing`navBarOffset`};
    --spacing-topPaddingMainContent: ${theming.spacing`topPaddingMainContent`};

    --font-h1: ${theming.font`h1`};
    --font-h2: ${theming.font`h2`};
    --font-h3: ${theming.font`h3`};
    --font-h4: ${theming.font`h4`};
    --font-large: ${theming.font`large`};
    --font-medium: ${theming.font`medium`};
    --font-news: ${theming.font`news`};
    --font-base: ${theming.font`base`};
    --font-text: ${theming.font`text`};
    --font-small: ${theming.font`small`};
    --font-micro: ${theming.font`micro`};
    --font-notificationHeader: ${theming.font`notificationHeader`};

    --fontWeight-veryBold: ${theming.fontWeight`veryBold`};
    --fontWeight-bold: ${theming.fontWeight`bold`};
    --fontWeight-normal: ${theming.fontWeight`normal`};
    --fontWeight-light: ${theming.fontWeight`light`};

    --borderRadius: ${theming.borderRadius};

    --size-small: ${theming.wrapper`small`};
    --size-medium: ${theming.wrapper`medium`};
    --size-large: ${theming.wrapper`large`};
    --size-breakout: ${theming.wrapper`breakout`};
    --size-huge: ${theming.wrapper`huge`};
    --size-unlimited: ${theming.wrapper`unlimited`};
  }
  ::selection {
    background: rgba(39, 154, 241, 0.9);
    color: #fff;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }
`
theming.theme.append(
  theming.createGlobal({
    remFix: reset,
    base: theming.cssResets, // TODO: check if this is needed
  })
)
