import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import UserInfo from '../../pages/home/components/UserInfo'
import { TranslatedText } from '@staccx/i18n'
import AutoplanSmall from '../../icons/AutoPlanSmall'
import { reportsSubmenu } from '../../data/routes'
import MenuItem, { MenuTitle } from './MenuItem'
import { NavLink, useLocation } from 'react-router-dom'
import Language from '../../pages/profile/routes/Language'
import { StyledButton } from '../../theme/Theme.Button'
import { useFeatureToggles, useUser, useUserContext } from '../../context/UserProvider'
import Hamburger from './Hamburger'
import { useWindowSize } from '../../utils/useWindowSize'
import { useBudgetSelections, usePendingSignings } from '../../context/SigningProvider'

const Menu = ({ onClick }) => {
  let location = useLocation()
  const featureToggles = useFeatureToggles()
  const [visible, setVisible] = useState(false)
  const { roleType, hasConfigurationAccess } = useUserContext()
  const threshold = 1219
  const { width } = useWindowSize()
  const [isSmall, isSmallSet] = useState(width < threshold)

  const user = useUser()
  const { pendingSignings } = usePendingSignings(user?.user.id)
  const { budgetSelections } = useBudgetSelections(user?.user.id)
  const totalTasks = (pendingSignings?.length ?? 0) + (budgetSelections?.length ?? 0)

  const handleClick = () => setVisible(!visible)

  useEffect(() => {
    if (width < threshold !== isSmall) {
      setVisible(width > threshold)
      isSmallSet(width < threshold)
    }
  }, [width, threshold, isSmall])

  const fetchSubMenu = () => {
    return !isSmall ? reportsSubmenu(featureToggles) : null
  }

  const handleMenuClick = () => (onClick, setVisible(!visible))

  return (
    <MenuItems>
      <MenuItem onClick={() => handleMenuClick()} path={'home'} islogo={'true'}>
        <Logo>
          <AutoplanSmall />
        </Logo>
      </MenuItem>
      <HamburgerBtn visible={visible} onClick={() => handleClick()} />
      <Row visible={visible} className={'middleContent'}>
        <MenuList visible={visible} isSmall={width < threshold}>
          {roleType && (
            <MenuItem onClick={() => handleMenuClick()} path={'home'}>
              <TranslatedText i18nKey={'HOME_MENUITEM'}>{(value) => <MenuTitle>{value}</MenuTitle>}</TranslatedText>
            </MenuItem>
          )}

          {roleType === 1 && (
            <MenuItem onClick={() => handleMenuClick()} path={'my-car'}>
              <TranslatedText i18nKey={'MYCAR_MENUITEM'}>{(value) => <MenuTitle>{value}</MenuTitle>}</TranslatedText>
            </MenuItem>
          )}

          {roleType === 2 && (
            <MenuItem onClick={() => handleMenuClick()} path={'cars'}>
              <TranslatedText i18nKey={'CARS_MENUITEM'}>{(value) => <MenuTitle>{value}</MenuTitle>}</TranslatedText>
            </MenuItem>
          )}

          {roleType === 2 && (
            <MenuItem onClick={() => handleMenuClick()} path={'drivers'}>
              <TranslatedText i18nKey={'EMPLOYEES'}>{(value) => <MenuTitle>{value}</MenuTitle>}</TranslatedText>
            </MenuItem>
          )}

          {roleType === 2 && (
            <MenuItem onClick={() => handleMenuClick()} path={'invoices'}>
              <TranslatedText i18nKey={'INVOICES'}>{(value) => <MenuTitle>{value}</MenuTitle>}</TranslatedText>
            </MenuItem>
          )}

          {roleType === 2 && (
            <MenuItem path={'/reports'} submenu={fetchSubMenu()} onClick={() => handleMenuClick()} location={location}>
              <TranslatedText i18nKey={'REPORTS_MENUITEM'}>{(value) => <MenuTitle>{value}</MenuTitle>}</TranslatedText>
            </MenuItem>
          )}

          {roleType === 2 && featureToggles.customerCarConfiguration && hasConfigurationAccess && (
            <MenuItem path={'car-order'} onClick={() => handleMenuClick()} location={location}>
              <TranslatedText i18nKey={'CAR_CONFIG_HEADING'}>
                {(value) => <MenuTitle>{value}</MenuTitle>}
              </TranslatedText>
            </MenuItem>
          )}

          {roleType === 2 &&
            featureToggles.customerCarConfiguration &&
            hasConfigurationAccess &&
            featureToggles.dokumentarkiv && (
              <MenuItem path={'document-archive'} onClick={() => handleMenuClick()} location={location}>
                <TranslatedText i18nKey={'DOC_ARCHIVE'}>{(value) => <MenuTitle>{value}</MenuTitle>}</TranslatedText>
              </MenuItem>
            )}

          {roleType && (
            <MenuItem onClick={() => handleMenuClick()} path={'help'}>
              <TranslatedText i18nKey={'HELP_MENUITEM'}>{(value) => <MenuTitle>{value}</MenuTitle>}</TranslatedText>
            </MenuItem>
          )}

          {roleType === 2 && (
            <MenuItem onClick={() => handleMenuClick()} path={'tasks'} count={totalTasks}>
              <TranslatedText i18nKey={'MY_TASKS'}>{(value) => <MenuTitle>{value}</MenuTitle>}</TranslatedText>
            </MenuItem>
          )}

          <Content visible={visible}>
                <UserInfo />
                <Language />
                <NavLink to="/logout">
                  <OnHoverShadowButton>
                    <TranslatedText i18nKey="LOGOUT">{(value) => <span>{value}</span>}</TranslatedText>
                  </OnHoverShadowButton>
                </NavLink>
          </Content>
        </MenuList>
      </Row>
      <Row visible={visible && !isSmall} className={'rightSideContent'}>
        <UserInfo />
        <Language />
        <NavLink to="/logout">
          <OnHoverShadowButton>
            <TranslatedText i18nKey="LOGOUT">{(value) => <span>{value}</span>}</TranslatedText>
          </OnHoverShadowButton>
        </NavLink>
      </Row>
    </MenuItems>
  )
}

export default Menu

const Content = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-small);
  > button {
    margin: 0;
    width: 100%;
    >div {
      justify-content: flex-start;
    }
  }
  div.react-select-container {
    width: 100%;
  }
  > a {
    width: 100%;
    button {
      width: 100%;
    }
  }
}  
`

const MenuList = styled.div`
  border-radius: var(--borderRadius);
  width: 100%;
  min-height: 100vh;
  display: ${({ visible, isSmall }) => (!visible && isSmall ? 'none' : 'flex')};
  align-items: ${({ visible, isSmall }) => (!visible && isSmall ? 'center' : 'start')};
  flex-direction: column;
  row-gap: 0.15rem;
  border: none;
  margin-left: 0;
  svg {
    margin-right: 0.15rem;
  }

  @media only screen and (min-width: 1219px) {
    border-radius: unset;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
  }
`
const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
    min-height: 100vh;
  align-items: center;

  justify-content: flex-end;
  padding: 0 var(--spacing-medium);
  height: 72px;
  position: fixed;
  top: 0;
  width: 30rem;
  background-color: var(--color-white);
  z-index: 5;
  @media only screen and (min-width: 1219px) {
    justify-content: center;
    padding: 0 var(--spacing-huge);
  }
`
const Row = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-small);
  &.middleContent {
    width: var(--size-large);
    justify-content: center;
  }
  &.rightSideContent {
    position: absolute;
    right: var(--spacing-smallMinus);
    top: 0;
  }
  @media only screen and (min-width: 1219px) and (max-width: 1320px) {
    div.react-select-container {
      display: none;
    }
  }
  @media only screen and (max-width: 1219px) {
    &.middleContent {
      width: calc(100% - 1rem);
      justify-content: space-between;
    }
    &.rightSideContent {
      position: relative;
    }

    display: ${(p) => (!p.visible ? 'none' : 'flex')};
    grid-column: 1/-1;
    justify-content: flex-start;
    transition: ${(p) => (!p.visible ? 'unset' : 'visibility 0s, max-height 0.2s, opacity 0.15s ease-in-out 0.1s')};
    opacity: ${(p) => (!p.visible ? 0 : 1)};
    visibility: ${(p) => (!p.visible ? 'hidden' : 'visible')};
    background-color: ${(p) => (!p.visible ? 'transparent' : 'white')};
    border-radius: ${(p) => (p.visible ? 'var(--borderRadius);' : '0')};
    padding: ${(p) => (p.visible ? '1rem' : '0')};
    width: ${(p) => (p.visible ? 'calc(100% - 1rem)' : '0')};
    position: ${(p) => (p.visible ? 'absolute' : 'relative')};
    top: ${(p) => (p.visible ? '6rem' : '0')};
    left: ${(p) => (p.visible ? '0.5rem' : 'auto')};
    z-index: ${(p) => (p.visible ? '999' : 'auto')};
    height: ${(p) => (p.visible ? 'auto' : '100%')};
    box-shadow: 0 100px 80px rgba(0, 20, 92, 0.07), 0 41.7776px 33.4221px rgba(0, 20, 92, 0.0503198),
      0 22.3363px 17.869px rgba(0, 20, 92, 0.0417275), 0 12.5216px 10.0172px rgba(0, 20, 92, 0.035),
      0 6.6501px 5.32008px rgba(0, 20, 92, 0.0282725), 0 2.76726px 2.21381px rgba(0, 20, 92, 0.0196802);
  }

  @media only screen and (max-width: 580px) {
    top: ${(p) => (p.visible ? '6rem' : '0')};
  }
`

const OnHoverShadowButton = styled(StyledButton)`
  transition: 0.2s;
  :hover {
    box-shadow: 4px 4px 4px var(--color-shadow);
  }
`

const Logo = styled.div`
  height: var(--spacing-hugeMinus);
  padding: var(--spacing-small);
  align-items: center;
  z-index: 5;
  transition: 0.3s;
  :hover {
    transform: scale(1.05);
  }
`
const HamburgerBtn = styled(Hamburger)`
  position: absolute;
  right: var(--spacing-smallMinus);
  margin: 0 auto;
  @media only screen and (max-width: 1219px) {
    padding-right: 1rem;
  }
`
