import React, { useMemo, useState } from 'react'
import { Heading, Label, Layout } from '@staccx/bento'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import DropDown from '../../../components/DropDownMenu'
import { TranslatedText } from '@staccx/i18n'
import PurchaseReportTable from '../components/PurchaseTables/PurchaseReportTable'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import { useQuery } from 'react-query'
import { fetchPurchaseReport } from '../../../api/api'
import { useChosenCompany } from '../../../context/UserProvider'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'

const PurchaseReport = () => {
  const company = useChosenCompany()
  const companyId = company && company.id

  const { data = [], status } = useQuery(['purchaseReport', companyId], () => fetchPurchaseReport(companyId), {
    enabled: !!companyId
  })

  const [year, yearSet] = useState(new Date().getFullYear().toString())

  const reportYears = useMemo(() => {
    return [...new Set(data.map((el) => el.tyear))]
  }, [data])

  const agreementFilter = useFilterOnConsolidateAgreement()
  const items = reportYears.map((year) => ({ value: year, label: year }))

  const mockPurchaseReport = [
  {
    period: "2023-2024",
    consolidatedInvoiceAgreement: "Service Agreement - Gold Plan",
    amountIncVAT: 5000
  },
  {
    period: "2022-2023",
    consolidatedInvoiceAgreement: "Maintenance Agreement - Silver Plan",
    amountIncVAT: 7000
  },
  {
    period: "2024-2025",
    consolidatedInvoiceAgreement: "Lease Agreement - Platinum Plan",
    amountIncVAT: 6000
  },
  {
    period: "2025-2026",
    consolidatedInvoiceAgreement: "Support Agreement - Basic Plan",
    amountIncVAT: 4500
  },
  {
    period: "2026-2027",
    consolidatedInvoiceAgreement: "Insurance Agreement - Comprehensive Plan",
    amountIncVAT: 8000
  },
  {
    period: "2027-2028",
    consolidatedInvoiceAgreement: "Consulting Agreement - Professional Plan",
    amountIncVAT: 5500
  },
  {
    period: "2028-2029",
    consolidatedInvoiceAgreement: "Training Agreement - Advanced Plan",
    amountIncVAT: 6700
  },
  {
    period: "2029-2030",
    consolidatedInvoiceAgreement: "Software Agreement - Enterprise Plan",
    amountIncVAT: 7200
  },
  {
    period: "2030-2031",
    consolidatedInvoiceAgreement: "Development Agreement - Premium Plan",
    amountIncVAT: 6900
  }
]


  return (
    <Container width="unlimited">
      <Layout rowGap={'small'}>
        <TranslatedText i18nKey={'PURCHASEREPORT_HEADING'}>
          {(value) => <Heading level="1">{value}</Heading>}
        </TranslatedText>
        <Layout variant="fiftyFifty">
          <TranslatedText i18nKey="PERIOD">
            {(value) => (
              <div>
                <Label>{value}</Label>
                <DropDown
                  options={items}
                  onChange={(selectedItem) => yearSet(selectedItem.value)}
                  value={{ value: year, label: year }}
                />
              </div>
            )}
          </TranslatedText>
          <ConsolidateInvoiceSelect />
        </Layout>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <PurchaseReportTable
        isLoading={status === 'loading'}
        data={mockPurchaseReport}
      />
    </Container>
  )
}

export default PurchaseReport
