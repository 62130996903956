import React, { useState } from 'react'
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table'
import eventListOverviewData from './eventListOverviewData'
import eventListDeviationsData from './eventListDeviationsData'
import eventListPurchaseData from './eventListPurchaseData'
import eventListRefuelData from './eventListRefuelData'
import eventListTollPassingData from './eventListTollPassingData'
import TanStackTable from '../../../../components/tables/TanStackTable'
import { useNavigate } from 'react-router-dom'
import eventFilter from '../../../../constants/eventFilter'
import eventListAdditionalProductsData from './eventListAdditionalProductsData'

let columns = null

type EventListProps = {
  carsData: any
  currentFilter: any
  isLoading?: boolean
}

function EventListTable({ carsData, currentFilter, isLoading }: EventListProps) {
  const [sorting, setSorting] = useState<SortingState>([])
  let navigate = useNavigate()

  const data = carsData?.reduce((acc, curr) => {
    if (currentFilter === 'additionalProducts') return acc.concat(curr.additionalProducts)
    return acc.concat(
      curr.events
        .filter((e) => (currentFilter ? currentFilter === eventFilter.all || e.eventType === currentFilter : e))
        .map((event) => ({
          ...event,
          car: curr
        }))
    )
  }, [])

  switch (currentFilter) {
    case eventFilter.tollPassing:
      columns = eventListTollPassingData()
      break
    case eventFilter.refuel:
      columns = eventListRefuelData()
      break
    case eventFilter.purchase:
      columns = eventListPurchaseData()
      break
    case eventFilter.deviations:
      columns = eventListDeviationsData()
      break
    case eventFilter.additionalProducts:
      columns = eventListAdditionalProductsData()
      break
    default:
      columns = eventListOverviewData()
      break
  }

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  return (
    <TanStackTable
      table={table}
      isLoading={isLoading}
      handleRowClick={(row) => {
        navigate(`/cars/${row.original.carId}`)
      }}
    />
  )
}

export default EventListTable
