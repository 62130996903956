import React, { useState, useMemo } from 'react'
import { Layout } from '@staccx/bento'
import EventFilter from '../components/EventFilter'
import CarInfo from '../components/CarInfo'
import { useCar } from '../../../context/CarProvider'
import { useContacts } from '../../../context/DriverProvider'
import { useParams } from 'react-router-dom'
import Container from '../../../components/Container'
import EventListTable from '../components/EventListTables'
import styled from "styled-components"
import { activeVehicles, incomingVehicles, outgoingVehicles } from './CarMockData'
const CarDetails = () => {
  const { id } = useParams()
   const combinedAllCars = activeVehicles.concat(incomingVehicles, outgoingVehicles)

   const findCarById = (id) => {
     return combinedAllCars.find((car) => car.registrationNumber === id)
   }
   const car = findCarById(id)
  const [currentFilter, currentFilterSet] = useState('ALL')

  const carAsArray = useMemo(() => {
    return car ? [car] : []
  }, [car])
  return (
    <Container isLoading={false} renderChildrenDuringLoading={false}>
      <Layout rowGap="large">
         <CarInfo car={car} driver={car?.driver || null} />
          <EventFilter
          accountNo={"2313129"}
          currentFilter={currentFilter}
          setFilter={currentFilterSet}
          showCarFilter={false}
        />
       <EventListTable carsData={carAsArray} currentFilter={currentFilter} isLoading={car === null}/>
      </Layout>
    </Container>
  )
}


const StyledCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0;
  flex-direction: column;
`
export default CarDetails
