import React, { useState } from 'react'
import styled, { css } from 'styled-components'

const TextArea = ({ placeholder, handleChange, maxLength = 200 }) => {
  return (
    <>
      <StyledTextArea
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
        maxLength={maxLength}
      ></StyledTextArea>
    </>
  )
}

export default TextArea

const StyledTextArea = styled.textarea`
  font-size: var(--font-base);
  padding: var(--spacing-tinyPlus);
  resize: vertical;
  border: 0px;
  height: var(--spacing-huge);
  max-height: 150px;
  resize: none;
  white-space: pre-line;
  background-color: white;
  border-radius: 8px;
  :focus {
      border: 1px solid lightgrey;
      outline: none;
      background-color:#F5F8FE;
  }
  border: 1px solid lightgrey;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.1));  
`
