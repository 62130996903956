import React, { useEffect, useMemo, useState } from 'react'
import { get } from 'lodash'
import { CheckBox, Wrapper } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import styled, { css } from 'styled-components'
import { useParams } from 'react-router-dom'
import useCarConfiguration, { CONFIGURE_AGREEMENTS } from '../hooks/useCarConfiguration'
import { flattenAccessories } from '../../../api/jato'
import { useCarPrice } from '../hooks/useCarPrice'
import Navigation from '../components/Navigation'
import CarConfigContainer from '../components/CarConfigContainer'
import { CarTopSummary } from '../components/CarTopSummary'
import { SmallHeading } from '../components/Headings'
import CustomSkeleton from '../components/skeleton/skeleton'
import { useCarOptionRules } from '../hooks/useCarOptionRules'
import { STANDARD_PAINT } from './ConfigureCarOptions'
import useCarConfigurationQueries from '../hooks/useCarConfigurationQueries'
import { useFlow } from '../../../context/FlowProvider'
import ImageDialog from '../components/imageGallery/ImageDialog'
import Container from '../../../components/Container'

function ConfigureAgreements() {
  const { flowId } = useParams()
  const { flow, refetch } = useFlow(flowId)

  const { saveAndNavigate, errorHandler, companyId, isRedirecting, isLoading } = useCarConfiguration({
    page: CONFIGURE_AGREEMENTS,
    flow,
    refetch
  })

  const { carConfiguration, vehicleType, visitedSteps } = flow?.data || {}

  const {
    chosenPackages = [],
    chosenAgreements,
    chosenOptions = [],
    chosenFinancingOptions,
    vehicleId,
    derivativeLocal
  } = carConfiguration || {}

  const hasChosenEngine = !!derivativeLocal

  const [openPhotos, openPhotosSet] = useState(false)
  const [selectedAgreements, selectedAgreementsSet] = useState(chosenAgreements || [])

  const { accessoriesRaw, agreementsRaw, wheelPackagesRaw } = useCarConfigurationQueries(
    CONFIGURE_AGREEMENTS,
    errorHandler,
    {
      vehicleType,
      companyId,
      vehicleId,
      make: carConfiguration?.makeKey,
      model: carConfiguration?.modelKey,
      modelYear: carConfiguration?.modelYear,
      bodyCode: carConfiguration?.bodyCode,
      doors: carConfiguration?.numberOfDoors,
      drivenWheels: carConfiguration?.drivenWheels,
      engine: carConfiguration?.derivativeLocal,
      fuelType:
        carConfiguration?.fuelType +
        (carConfiguration?.secondaryFuelType && carConfiguration.secondaryFuelType !== '-'
          ? carConfiguration.secondaryFuelType
          : ''),
      ...chosenFinancingOptions
    }
  )

  const { error: accessoriesError, data: accessoriesData } = accessoriesRaw
  const { error: agreementsError, data: agreements } = agreementsRaw

  const { accessories, photo, photos, standardEquipment } = useMemo(() => {
    if (!accessoriesData) return {}

    const { packages = [], options = {} } = accessoriesData

    return {
      accessories: { options, packages },
      photo: get(accessoriesData, 'vehiclePhotos[0]', []),
      photos: get(accessoriesData, 'vehiclePhotos', []),
      standardEquipment: get(accessoriesData, 'vehicleStandardEquipment', {})
    }
  }, [accessoriesData])

  const hasSelectableWheels = wheelPackagesRaw?.data && !wheelPackagesRaw?.error

  const { simpleFormValidator } = useCarOptionRules(
    [...flattenAccessories(accessories), STANDARD_PAINT],
    [...chosenOptions, ...chosenPackages],
    CONFIGURE_AGREEMENTS,
    !!carConfiguration?.derivativeLocal,
    visitedSteps,
    !!carConfiguration?.chosenWheelPackage,
    hasSelectableWheels
  )

  const { monthlyPrice } = useCarPrice({
    configuration: accessoriesData
      ? {
          ...carConfiguration,
          chosenAgreements: selectedAgreements
        }
      : null,
    options: flow && !isRedirecting ? [...chosenPackages, ...chosenOptions] : [],
    vehicleType,
    financingOptions: chosenFinancingOptions || {},
    errorHandler
  })

  const updateFlowHandler = ({ direction, route }) => {
    saveAndNavigate.mutate({
      step: CONFIGURE_AGREEMENTS,
      carConfiguration: {
        ...carConfiguration,
        chosenAgreements: selectedAgreements
      },
      direction,
      route
    })
  }

  const onSelectAgreement = (item) => {
    if (selectedAgreements.some((a) => a.Type === item.Type)) {
      selectedAgreementsSet((prevState) => prevState.filter((a) => a.Type !== item.Type))
      return
    }
    selectedAgreementsSet((prevState) => [...prevState, item])
  }

  useEffect(() => {
    if (agreements && carConfiguration && !carConfiguration.chosenAgreements) {
      const selectedAgreement = selectedAgreements?.find((s) => s.Type === '1')
      if (!selectedAgreement) {
        const agreement = agreements?.find((a) => a.Type === '1')
        if (agreement) {
          selectedAgreementsSet((prevState) => [...prevState, agreement])
        }
      }
    }
  }, [agreements, carConfiguration])

  const reqError = accessoriesError || agreementsError
  if (reqError) {
    errorHandler(reqError)
  }

  if (isRedirecting) {
    return null
  }

  const disable = useMemo(() => {
    return selectedAgreements.length > 0
  }, [selectedAgreements])

  return (
    <Container isLoading={isLoading} width="100%">
      <CarTopSummary
        car={carConfiguration}
        photoUrl={photo || carConfiguration?.photoUrl}
        coreCalculatedMonthlyPrice={monthlyPrice}
        vehiclePhotos={photos}
        standardEquipment={standardEquipment}
        onPress={() => {
          if (photos.length > 0) {
            openPhotosSet(!!photos)
          }
        }}
        page={CONFIGURE_AGREEMENTS}
        saveAndNavigate={updateFlowHandler}
        routeValidator={simpleFormValidator}
        visitedPages={visitedSteps}
      />
      <ConfigureAgreementsWrapper>
        {photos && <ImageDialog photos={photos} isOpen={openPhotos} openSet={openPhotosSet} />}
        {agreementsError && <TranslatedText i18nKey="NO_AGREEMENTS_MSG" />}
        {!agreementsError && (
          <>
            {!hasChosenEngine ? (
              <TranslatedText i18nKey="ENGINE_MISSING_MESSAGE_AGREEMENTS" />
            ) : (
              <Agreements>
                <TranslatedText i18nKey="CAR_CONFIG_AGREEMENTS_HEADING">
                  {(value) => <SmallHeading>{value}</SmallHeading>}
                </TranslatedText>
                {agreements ? (
                  <div>
                    {agreements.map((agreement) => (
                      <StyledCheckBox
                        onChange={() => onSelectAgreement(agreement)}
                        group="agreementsTypes"
                        id={agreement.Type}
                        key={agreement.Type}
                        value={agreement.Description}
                        checked={selectedAgreements?.some((a) => a.Type === agreement.Type)}
                        disabled={disable && !selectedAgreements?.some((a) => a.Type === agreement.Type)}
                      >
                        {agreement.Description}
                      </StyledCheckBox>
                    ))}
                  </div>
                ) : (
                  !agreementsError && (
                    <CustomSkeleton
                      skeletons={[
                        {
                          width: 500,
                          height: 20
                        }
                      ]}
                      theme={css`
                        border: none;
                      `}
                    />
                  )
                )}
              </Agreements>
            )}
          </>
        )}
        <Navigation updateFlowHandler={updateFlowHandler} />
      </ConfigureAgreementsWrapper>
    </Container>
  )
}

export default ConfigureAgreements

const ConfigureAgreementsWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
`

const Agreements = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--color-bg);
  border: 1px solid var(--color-line);

  > span {
    background: var(--color-primaryLight);
    line-height: 3em;
  }

  > div > div {
    cursor: pointer;
  }

  > div > div:not(:first-child) {
    border-top: var(--spacing-microMinus) solid var(--color-line);
  }

  > span,
  > div > div {
    padding: 0 var(--spacing-smallPlus);
  }
`

const StyledCheckBox = styled(CheckBox)`
  &[disabled] ~ label::before {
    background-color: var(--color-line);
    cursor: not-allowed;
    border: 0px;
  }
`