import React from "react"

const Hybrid = props => (
  <svg viewBox="0 0 24 24" width="24" height="24" {...props}>
    <path
      fill="currentColor"
      d="M12 15a3 3 0 1 0 5.86.86A5 5 0 0 1 14 11V7h2V4h2v3h2V4h2v3h2v4a5 5 0 0 1-4.1 4.9A5 5 0 0 1 12 19v1H0V6c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v9zm10-4V9h-6v2a3 3 0 0 0 6 0zM2 6v4h8V6H2zm5.7 12h1.36v-6.28H7.7v2.5H5.3v-2.5H3.94V18H5.3v-2.57h2.4V18z"
    />
  </svg>
)

export default Hybrid
