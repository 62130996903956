import React, { createContext, useContext, useState } from 'react'
import sanityOptions from '../api/sanity'
import SanityQueryHelper from 'sanity-query-helper'
import { useQuery } from 'react-query'

const helper = new SanityQueryHelper({ sanityOptions })

const I18nContext = createContext({})

const I18nProvider = ({ children }) => {
  const [language, languageSet] = useState(localStorage.getItem('stacc-x-bento-locale') || 'nb')

  const { data: texts } = useQuery(['texts'], () =>
    helper
      .ofType('i18n')
      .pick(`"id": _id, key, value`)
      .select(0, 999)
      .send()
      .then((texts) =>
        texts.reduce((result, current) => {
          result[current.key] = current.value
          return result
        }, {})
      )
  )

  const values = {
    language,
    languages: [ 'en', 'nb'],
    texts,
    setLanguage: (value) => languageSet(value),
  }

  return <I18nContext.Provider value={values}>{children}</I18nContext.Provider>
}

export const useI18nContext = () => useContext(I18nContext)

export default I18nProvider
