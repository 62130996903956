import React from 'react'
import { TranslatedText } from '@staccx/i18n'
import { SmallHeading } from './Headings'
import styled from 'styled-components'
import Chevron from '../../../icons/Chevron'
import { NORWEGIAN_MOUNTED_ACCESSORIES } from "../../../utils/norwegianMountedOptions"

const NorwegianEquipment = ({
  norwegianEquipment,
  OptionsMenu,
  openOptionsMenus,
  handleOpenOptionsMenu,
  MultiOptionListItemSimple,
  handleNorwegianEquipmentToggleOption,
  selectedOptions,
  SelectionLists,
  getSelectionLists,
}) => {
  return (
    <>
      {norwegianEquipment.length > 0 && (
        <OptionsMenu
          key={NORWEGIAN_MOUNTED_ACCESSORIES}
          isOpen={openOptionsMenus.includes(NORWEGIAN_MOUNTED_ACCESSORIES)}
        >
          <div onClick={() => handleOpenOptionsMenu(NORWEGIAN_MOUNTED_ACCESSORIES)}>
            <TranslatedText i18nKey={`OPTION_HEADING_${NORWEGIAN_MOUNTED_ACCESSORIES.toUpperCase()}`}>
              {(value) => <SmallHeading>{value}</SmallHeading>}
            </TranslatedText>
            <StyledChevron isOpen={openOptionsMenus.includes(NORWEGIAN_MOUNTED_ACCESSORIES)} />
          </div>
          {openOptionsMenus.includes(NORWEGIAN_MOUNTED_ACCESSORIES) ? (
            <div>
              {norwegianEquipment?.map((option) => (
                <>
                  <MultiOptionListItemSimple
                    key={option.optionId}
                    option={option}
                    onSelect={handleNorwegianEquipmentToggleOption}
                    selectedOptions={selectedOptions}
                  />
                </>
              ))}
            </div>
          ) : (
            <SelectionLists>
              {getSelectionLists(norwegianEquipment, 5).map((list) => (
                <ol>{list}</ol>
              ))}
            </SelectionLists>
          )}
        </OptionsMenu>
      )}
    </>
  )
}

const StyledChevron = styled(Chevron)`
  transform: ${(p) => (p.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  width: 31px;
  height: 31px;
`

export default NorwegianEquipment
