import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TranslatedText } from '@staccx/i18n'
import { Field, Formik } from 'formik'
import { Alert, Box, Input, Loading } from '@staccx/bento'
import { Label } from '../../../theme/Theme.Text'
import { fetchContactDetails, updateDriverDetails, createAndSetNewDriver, createDriverWithDetails } from '../../../api/api'
import { getContactErrorMessage } from '../../../utils/errorHandling'
import styled from 'styled-components'
import SwitchButton from '../../../components/modal/SwitchButton'
import { useFeatureToggles } from '../../../context/UserProvider'
import { useChosenCompany } from '../../../context/UserProvider'
import { useLocation } from 'react-router-dom'
import { useCar } from '../../../context/CarProvider'
import { ButtonGroup } from '../../../theme/Theme.ItemGroup'
import { CancelButton, StyledButton } from '../../../theme/Theme.Button'
import TextArea from '../../../components/fields/TextArea'

const Yup = require('yup')

const validateSchema = Yup.object().shape({
  name: Yup.string().required(),
  mail: Yup.string().email().required(),
  phone: Yup.string().required(),
  address: Yup.string().nullable().required('Påkrevd'),
  postCode: Yup.string().required('Påkrevd'),
  jobTitle: Yup.string()
})

const detailsMap = (details) => ({
  name: details.name || '',
  mail: details.mail || '',
  phone: details.phone || '',
  address: details.address || '',
  postCode: details.postCode || '',
  jobTitle: details.jobTitle || ''
})

const ContactForm = ({ close, companyId, driver, ...props }) => {
  const [contactDetails, contactDetailsSet] = useState({})
  const [isLoading, isLoadingSet] = useState(true)
  const featureToggles = useFeatureToggles()

  let location = useLocation()
  const [setAsDriverOnCurrentCar, setAsDriverOnCurrentCarSet] = useState(false)
  const company = useChosenCompany()
  const splitPath = location.pathname.split('/')
  const isOnCar = splitPath.map((i) => i.toLowerCase()).includes('cars') && splitPath.length > 2
  const carRegNo = isOnCar ? splitPath[2] : ''
  const car = useCar(carRegNo)

  const getContactDetails = () => {
    if (!driver) {
      isLoadingSet(false)
      contactDetailsSet({
        name: '',
        mail: '',
        phone: '',
        address: '',
        postCode: '',
        jobTitle: ''
      })
      return
    }
    fetchContactDetails(driver.id)
      .then((details) => {
        contactDetailsSet(detailsMap(details))
        isLoadingSet(false)
      })
      .catch(() => alert(`Could not find details for driver with id ${driver.id}`))
  }

  useEffect(() => getContactDetails(), [])

  const switchButtons = [
    { id: 'driver', label: 'DRIVER' },
    { id: 'manager', label: 'MANAGER' },
    { id: 'carConfig', label: 'CAR_CONFIG' }
  ]

  const [message, setMessage] = useState(0)

  return !isLoading ? (
    <>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={contactDetails ? contactDetails : ''}
        validationSchema={validateSchema}
        onSubmit={(details, { setSubmitting, setTouched }) => {
          if (companyId && driver.id) {
            updateDriverDetails(companyId, driver.id, details)
              .then(() => {
                setSubmitting(false)
                setTouched(false)
                props.onFinish(details)
              })
              .catch((err) => {
                window.alert(getContactErrorMessage(err))
                setSubmitting(false)
              })
          }
          if (!driver && setAsDriverOnCurrentCar) {
            createAndSetNewDriver(company.id, car.accountNo, details)
              .then(() => {
                setSubmitting(false)
                props.onFinish(setAsDriverOnCurrentCar)
              })
              .catch((err) => {
                window.alert(getContactErrorMessage(err))
                setSubmitting(false)
              })
          } else if (!driver && !setAsDriverOnCurrentCar) {
            createDriverWithDetails(company.id, details)
              .then(() => {
                setSubmitting(false)
                props.onFinish(setAsDriverOnCurrentCar)
              })
              .catch((err) => {
                window.alert(getContactErrorMessage(err))
                setSubmitting(false)
              })
          }
        }}
        render={({ handleSubmit, isValid, isSubmitting, touched, errors, setFieldValue }) => {
          return (
            <Box variant="tabs">
              <form onSubmit={handleSubmit}>
                <Grid>
                  <div>
                    <Item>
                      <Heading level={3}>
                        Send us a message
                      </Heading>
                    </Item>
                    <Item>
                      One of our employees will come back to you as fast they can.
                    </Item>
                    <Item>
                      <Label>{<TranslatedText i18nKey="NAME" />}<Mandatory>*</Mandatory></Label>
                      <Field
                        name={`name`}
                        render={({ field }) => (
                          <TranslatedText i18nKey="PLACEHOLDER_NAME">
                            {(value) => <Input variant="filledInput" id={`name`} {...field} placeholder={value} />}
                          </TranslatedText>
                        )}
                      />
                      {touched.name && errors.name && (
                        <Alert variant="error" type="warning">
                          {errors.name}
                        </Alert>
                      )}
                    </Item>
                  
                    <Item>
                      <Label>{<TranslatedText i18nKey="EMAIL" />}<Mandatory>*</Mandatory></Label>
                      <Field
                        name={`mail`}
                        render={({ field }) => (
                          <TranslatedText i18nKey="PLACEHOLDER_EMAIL">
                            {(value) => (
                              <>
                                <Input variant="filledInput" id={`mail`} {...field} placeholder={value} />
                              </>
                            )}
                          </TranslatedText>
                        )}
                      />
                      {touched.mail && errors.mail && (
                        <Alert variant="error" type="warning">
                          {errors.mail}
                        </Alert>
                      )}
                    </Item>
                    <Item>
                      <Label>{<TranslatedText i18nKey="PHONE" />}<Mandatory>*</Mandatory></Label>
                      <Field
                        name={`phone`}
                        render={({ field }) => (
                          <Input
                            variant="filledInput"
                            mode={'phone'}
                            id={'phone'}
                            placeholder={'+47 411 11 111'}
                            locale={'nb'}
                            {...field}
                            onChange={(event) => setFieldValue('phone', event.rawValue)}
                          />
                        )}
                      />
                      {touched.phone && errors.phone && (
                        <Alert variant="error" type="warning">
                          {errors.phone}
                        </Alert>
                      )}
                    </Item>
                     <Item>
                      <Label>Company<Mandatory>*</Mandatory></Label>
                      <Field
                        name={`jobTitle`}
                        render={({ field }) => (
                          <TranslatedText i18nKey="WORK_ROLE">
                            {(value) => (
                              <>
                                <Input variant="filledInput" id={`jobTitle`} {...field} placeholder={value} />
                              </>
                            )}
                          </TranslatedText>
                        )}
                      />
                    </Item>
                    <Item>
                      <Label>Your message<Mandatory>*</Mandatory></Label>
                      <TextArea handleChange={(value) => setMessage(value)} placeholder="Tell us what we can do to help you"></TextArea>
                      <NumberContainer>
                        <Number>{message?.length || 0}</Number>/<Number>500</Number>
                      </NumberContainer>
                      </Item>                  
                    {isSubmitting ? (
                      <Loading />
                    ) : (
                      <ButtonGroup>
                        {driver && driver.id && (
                          <>
                            <StyledButton type={'submit'}  onClick={() => null}>
                              <TranslatedText i18nKey={'SAVE'} />
                            </StyledButton>
                
                          </>
                        )}
                        {!driver && (
                          <>
                          <StyledButton type={'submit'}  onClick={() => null}>
                            <TranslatedText i18nKey={'SAVE'} />
                          </StyledButton>
                  
                          </>
                        )}
                      </ButtonGroup>
                    )}
                  </div>
                </Grid>
              </form>
            </Box>
          )
        }}
      />
    </>
  ) : (
    <Loading />
  )
}

ContactForm.propTypes = {
  onFinish: PropTypes.func.isRequired
}

export default ContactForm

const Number = styled.span`
  font-weight: var(--fontWeight-bold);
  color: grey;
`
const NumberContainer = styled.div`
  display: flex;
    color: grey;
  justify-content: flex-end;
`


const Mandatory = styled.span` 
  color: #BF0031;
`

const Form = styled.div`
border-radius: 8px;
box-shadow: 0 2px 4px var(--color-line);
`


const Heading = styled.p`
  font-weight: var(--fontWeight-bold);
  font-size: var(--font-h2);
  margin-bottom: var(--spacing-medium);
`

const Item = styled.div`
  margin-bottom: var(--spacing-medium);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-smallPlus);
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  padding: var(--spacing-large);
  gap: var(--spacing-largePlus);
`

const Access = styled(Label)`
  margin-bottom: var(--spacing-smallMinus);
`
