import { useEffect, useState } from 'react'
import { useUserContext } from '../../../context/UserProvider'
import { useAllCars } from '../../../context/CarProvider'
import { useParams } from '../../../hooks/useParams'
import { useNavigate } from 'react-router-dom'
import { ActiveCardIcon, CarIcon, IncomingCardIcon, OutGoingCarIcon } from '../components/CarIcons'
import { activeVehicles, incomingVehicles, outgoingVehicles } from './CarMockData'
import FuelVolume from '../components/visuals/FuelVolume'
import Mileage from '../components/visuals/Mileage'
import CarCard from '../components/CarCard'
import { Flag } from '@staccx/bento'
import styled from 'styled-components'
import Logo from '../components/Logo'
import { FuelChart } from './FuelChart'

const Cars = () => {
  const { fuel, status, cars, hasReplacementCar, isOverMileage } = useParams()
  const [carFilter, carFilterSet] = useState({
    fuel,
    status,
    cars: outgoingVehicles,
    hasReplacementCar,
    isOverMileage
  })
/*   useEffect(() => {
    carFilterSet({
      fuel: fuel || null,
      carStatus: status || null,
      cars: cars || null,
      hasReplacementCar: hasReplacementCar || null,
      isOverMileage: isOverMileage || null
    })
  }, [fuel, status, cars, hasReplacementCar, isOverMileage])
 */
  const [currentFilter, currentFilterSet] = useState(null)
  const { roleType, chosenCompany } = useUserContext()

  const allCarsUnfiltered = useAllCars()
  const allCarsFiltered = useAllCars(carFilter)
  const activeCarsFiltered = activeVehicles
  const filteredIncomingCars = incomingVehicles
  const filteredOutgoingCars = outgoingVehicles

  const combinedAllCars = activeCarsFiltered.concat(filteredIncomingCars, filteredOutgoingCars)

  const cardOptions = [
    {
      label: 'Incoming cars',
      value: filteredIncomingCars.length,
      icon: <IncomingCardIcon />,
      status: 'incoming',

      colors: {
        primary: 'bg-orange-300',
        secondary: 'text-orange-800'
      }
    },
    {
      label: 'Active cars',
      value: activeCarsFiltered.length,
      icon: <ActiveCardIcon />,
      status: 'active',

      colors: {
        primary: 'bg-blue-300',
        secondary: 'text-blue-800'
      }
    },

    {
      label: 'Outgoing cars',
      value: filteredOutgoingCars.length,
      icon: <OutGoingCarIcon />,
      status: 'outgoing',
      colors: {
        primary: 'bg-red-300',
        secondary: 'text-red-800'
      }
    },
    {
      label: 'All cars',
      value: combinedAllCars.length,
      icon: <CarIcon />,
      colors: {
        primary: 'bg-purple-300',
        secondary: 'text-purple-800'
      }
    }
  ]

  const [focusStatus, setFocusStatus] = useState(null)

  const filterOptions = [
    {
      value: 'Active',
      label: 'Active'
    },
    {
      value: 'Incoming',
      label: 'Incoming'
    },
    {
      value: 'Outgoing',
      label: 'Outgoing'
    }
  ]

  const [filter, setFilter] = useState(null)
  const [selectedCar, setSelectedCar] = useState(null)
  return (
    <div className="p-24 flex flex-col gap-12">
      <div>
        <h1 className="font-bold text-xl">Fleet Overview</h1>
        <p>Stacc AS</p>
      </div>
      <div>
        <div className="flex w-full justify-between gap-4">
          {cardOptions.map((option) => {
            return (
              <div
                onMouseLeave={() => setFocusStatus(null)}
                onMouseEnter={() => setFocusStatus(option.status)}
                key={option.value}
                className="flex items-center justify-around bg-white p-10 min-w-[200px] w-full rounded-lg mb-4 shadow-md"
              >
                <div
                  className={`${option.colors.primary} ${option.colors.secondary} h-11 w-11 rounded-full flex items-center justify-center`}
                >
                  {option.icon && option.icon}
                </div>
                <div className="flex items-start flex-col">
                  <h2 className="font-bold text-lg">{option.value}</h2>
                  <p className="font-bold opacity-50">{option.label}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h3>Filters</h3>
        <div className="flex flex-row gap-4">
          {filterOptions.map((option) => {
            return (
              <button
                onClick={() => setFilter(option.value === filter ? null : option.value)}
                key={option.value}
                className={`flex flex-row items-center gap-2 }`}
              >
                <div className="h-6 w-6 bg-white border-blue-300 border rounded-md relative">
                  {filter === option.value && (
                    <div className="h-3 w-3 bg-blue-500 rounded-md absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform"></div>
                  )}
                </div>
                <span>{option.label}</span>
              </button>
            )
          })}
        </div>
      </div>
      <div className="w-full p-4 bg-white rounded-md shadow-md">
        <table className="text-left w-full">
          <thead>
            <tr className="[&>th]:pb-4">
              <th>Status</th>
              <th>Car</th>
              <th>Driver</th>
              <th>Make</th>
              <th>Model</th>
              <th>Account No</th>
              <th>Contract Mileage</th>
            </tr>
          </thead>
          <tbody className="">
            {combinedAllCars
              .sort((b, a) => b.deliveryDate.localeCompare(a.deliveryDate))
              .map((car, index) => {
                const statusColor =
                  car.status === 'active'
                    ? 'text-blue-500'
                    : car.status === 'incoming'
                    ? 'text-orange-500'
                    : 'text-red-500'
                const findEven = index % 2 === 0
                const isInFocus = focusStatus === car.status
                if (!!filter && filter.toLowerCase() !== car.status) {
                  return null
                }
                return (
                  <>
                    <tr
                      key={car.registrationNumber}
                      className={`border-2 border-white cursor-pointer  ${isInFocus && 'bg-slate-200'}`}
                      onClick={() => setSelectedCar(car)}
                    >
                      <td className={`${statusColor} h-2 max-w-2 capitalize py-4 px-2`}>{car.status}</td>
                      <td>{car.registrationNumber}</td>
                      <td>{car.driver.name}</td>
                      <td>{car.make}</td>
                      <td>{car.model}</td>
                      <td>{car.accountNo}</td>
                      <td>{car.contractMileage}</td>
                      <td>
                        <a href={`/cars/${car.registrationNumber}`}>➞</a>
                      </td>
                    </tr>
                  </>
                )
              })}
          </tbody>
        </table>
      </div>
      {selectedCar && (
        <div className="flex flex-col gap-4">
          <h2 className=" text-xl">Selected Car</h2>
          <div className="bg-white p-4 rounded-md shadow-md relative">
            <a
              href={`/cars/${selectedCar.registrationNumber}`}
              className="absolute right-4 text-blue-500 hover:underline cursor-pointer"
            >
              More details
            </a>
            <div className="flex gap-4 justify-between pt-2">
              <div className="p-4 flex flex-col gap-4">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col">
                    <span className=" text-lg"> {selectedCar.registrationNumber}</span>
                    <span className="text-2xl">
                      {' '}
                      {selectedCar.make} {selectedCar.model}
                    </span>
                  </div>
                </div>
                <Flag img={<StyledLogo brand={selectedCar.make} width={100} height={100} />} />
              </div>
              <div>
                <FuelChart car={selectedCar} />
              </div>
              <div className="px-8 py-4 flex flex-col gap-4 ">
                <h4 className="text-blue-500 text-xl">Mileage</h4>
                <Mileage car={selectedCar} radius={6} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
const StyledLogo = styled(Logo)`
  width: var(--spacing-huge);
  height: var(--spacing-huge);
  @media only screen and (max-width: 450px) {
    width: var(--spacing-largePlus);
    height: var(--spacing-largePlus);
  }
`

export default Cars
