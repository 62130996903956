import React from "react"

const Diesel = props => (
  <svg viewBox="0 0 24 24" width="24" height="24" {...props}>
    <path
      fill="currentColor"
      d="M17 7V6a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v14h12v-3.18A2.96 2.96 0 0 0 21 14V7h-4zm2 7a1 1 0 0 1-2 0V9h2v5zM7 6h8v4H7V6zm2.06 12v-6.28h2.36c1.67 0 2.77 1.05 2.77 3.14S13.09 18 11.42 18H9.06zm1.36-1.21h1c.81 0 1.32-.45 1.32-1.44v-.98c0-1-.5-1.44-1.32-1.44h-1v3.86z"
    />
  </svg>
)

export default Diesel
