import styled, { css } from 'styled-components'
import { Loading } from '@staccx/bento'

const Container = ({ isLoading, children, width = 'large', renderChildrenDuringLoading = true, css = {} }) => {
  return (
    <Home isLoading={isLoading} width={width} css={css}>
      {isLoading && <LoaderWrapper><Loading /></LoaderWrapper>}
      {isLoading && !renderChildrenDuringLoading ? null : children}
    </Home>
  )
}
const LoaderWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;

`
const Home = styled.div`
width: 100%;
 padding: 5rem 8rem;
 display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
`

export default Container
