import React from 'react'
import { useQuery } from 'react-query'
import { Heading, Layout, Loading, Text } from '@staccx/bento'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import { TranslatedText } from '@staccx/i18n'
import ReportTermSelect from '../components/ReportTermSelect'
import { fetchFleetReportExpense, fetchFleetReportTerms } from '../../../api/api'
import { useCarByAccountNo } from '../../../context/CarProvider'
import { useUserContext } from '../../../context/UserProvider'
import { useParams } from 'react-router-dom'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'
import FleetReportExpenseTable from '../components/FleetTables/FleetReportExpense'

const FleetReportExpense = () => {
  const { type, itemNo, account } = useParams()
  const { chosenCompany: company, fleetReportTerm } = useUserContext()
  const companyId = company && company.id

  const { data: terms = [], isLoading } = useQuery(
    ['fleetReportTerms', companyId],
    () => fetchFleetReportTerms(companyId),
    { enabled: !!companyId }
  )

  const { data: report, isLoading: reportLoading } = useQuery(
    ['fleetReportDetails', companyId, fleetReportTerm, account],
    () => fetchFleetReportExpense({ companyId, type, account, term: fleetReportTerm, itemNo }),
    { enabled: !!companyId }
  )
  const car = useCarByAccountNo(account)

  if (isLoading) return <Loading />

  const { make, model, driver = {}, registrationNumber = '' } = car ? car : {}

  return (
    <Container>
      <TranslatedText i18nKey={'FLEETREPORT_EXPENSE_HEADING'}>
        {(value) => <Heading level="1">{value}</Heading>}
      </TranslatedText>
      <Layout variant="fiftyFifty">
        <div>
          <p>
            <TranslatedText i18nKey="ACCOUNT">
              {(value) => (
                <Text level="4">
                  {value}: {account}
                </Text>
              )}
            </TranslatedText>
          </p>
          <p>
            <TranslatedText i18nKey="REGISTRATION_NUMBER">
              {(value) => (
                <Text level="4">
                  {value}: {registrationNumber}
                </Text>
              )}
            </TranslatedText>
          </p>
          <p>
            <TranslatedText i18nKey="CAR_MAKE_MODEL">
              {(value) => (
                <Text level="4">
                  {value}: {make} {model}
                </Text>
              )}
            </TranslatedText>
          </p>
          <p>
            <TranslatedText i18nKey="DRIVER">
              {(value) => (
                <Text level="4">
                  {value}: {driver.name}
                </Text>
              )}
            </TranslatedText>
          </p>
        </div>
        <TranslatedText i18nKey="TERM">
          {(value) => <ReportTermSelect label={value} reportTerms={terms} />}
        </TranslatedText>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <FleetReportExpenseTable isLoading={reportLoading} data={report} />
    </Container>
  )
}

export default FleetReportExpense
