import { theming } from '@staccx/bento'
const borderRadius = '8px'

const wrapper = {
  small: '480px',
  medium: '640px',
  large: '1200px',
  breakout: '640px',
  huge: '1280px',
  unlimited: '2000px',
}

const color = {
  bg: '#fff',
  bgGray: '#F8F8F8',
  primary: '#2a6df4',
  primaryLight: '#CCDBFA',
  secondary: '#f4f7ff',
  gray: '#F8F8F8',
  text: '#333333',
  white: '#fff',
  line: '#E4E2E2',
  wcag: '#777',
  disabled: '#BABABA',
  warning: '#FF5B30',
  subtleWarning: '#FFEEEE',
  positive: '#2a6df4',
  negative: '#FF5B30',
  black: '#333333',
  subtleHover: '#F5F8FE',
  headerText: '#5E5B5B',
  headerBackground: '#F4F7FF',
  rowBackground: '#FFFFFF',
  rowLine: '#E4E2E26e',
  grayLine: '#dadada',
  newFont: "#525050",
}

const webfonts = {
  google: {
    families: ['IBM Plex Sans:300,400,500'],
  },
}

const fontWeight = {
  veryBold: 700,
  bold: 500,
  normal: 400,
  light: 300,
}

const font = {
  h1: '24px',
  h2: '22px',
  h3: '20px',
  h4: '18px',
  large: '24px',
  medium: '22px',
  news: '18px',
  base: '16px',
  text: '14px',
  small: '12px',
  micro: '10px',
  notificationHeader: '16px',
}

const fontFamily = {
  heading: 'IBM Plex Sans',
  body: 'IBM Plex Sans',
  test: "Inter",
}

const spacing = {
  menuAndFooter: '172px',
  hugePlus: '300px',
  huge: '100px',
  hugeMinus: '60px',
  largePlus: '48px',
  large: '45px',
  largeMinus: '40px',
  mediumPlus: '32px',
  medium: '24px',
  mediumMinus: '20px',
  smallPlus: '14px',
  small: '12px',
  smallMinus: '10px',
  tinyPlus: '8px',
  tiny: '6px',
  tinyMinus: '4px',
  microPlus: '3px',
  micro: '2px',
  microMinus: '1px',
  switchWidth: '51px',
  switchHeight: '31px',
  switchThumb: '27px',
  navBarOffset: '100px',
  topPaddingMainContent: '143px',
}

theming.theme.append({
  color,
  font,
  fontWeight,
  fontFamily,
  borderRadius,
  wrapper,
  webfonts,
  spacing,
})
