import { CarSvg } from "../../../icons/NewMenuCollection"
import styled from "styled-components"

export function NewCard({children}) {
    return <StyledCard>
        {children}
    </StyledCard>
}


const StyledCard = styled.div`
background-color: #ffffff;
border: 8px solid white;
display: flex;
padding: 1rem;
align-items: center;
width: fit-content;
gap: 2rem;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.075);
border-radius: 8px;
@media (max-width: 768px) {
width: fit-content;
}
`



