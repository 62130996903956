import { keyframes } from '@emotion/react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { useUserContext } from '../../../context/UserProvider'
import axios from 'axios'
import { useState } from 'react'
export function OrderProgress() {
  const user = useUserContext()
  const { isLoading, isFetching, data, isError } = useQuery({
    queryKey: ['flows', 'initiate-contract'],
    queryFn: async () => {
      const data = await axios.get(`/api/flows?flowDefinitionId=initiate-contract&sort=createdAt&dir=-1`, {
        headers: {
          Authorization: `Bearer ${user.newUserData?.access_token}`
        }
      })
      return data.data?.flows
    }
  })
  const [amountToShow, setAmountToShow] = useState(3)

  if (isError) {
    return <p>Service Error: Could not find latest orders</p>
  }

  if (isFetching) {
    return (
      <Layout>
        <HalfWidthWrapper>
          <SkeletonLoader />
        </HalfWidthWrapper>{' '}
        <SkeletonLoader />
        <SkeletonLoader />{' '}
      </Layout>
    )
  }

  //contract status  9 = active, 5 = approvedcredit,  3/2 = credit validation, 1 = created?

  const mappedData = data?.map((flow) => {
    const contract = flow?.data?.currentContractDetails?.Contract
    return {
      status: contract?.ContractStatus,
      person: contract?.FinancialPartner,
      contractId: flow?.referenceId,
      vehicle: {
        brand: contract?.Vehicle?.BrandName,
        modelName: contract?.Vehicle?.ModelName,
        modelYear: contract?.Vehicle?.ModelYear
      }
    }
  })
  const findLevel = (status) => {
    switch (status) {
      case 1:
        return {
          level: 1,
          color: '#467EFF',
          text: 'Created'
        }
      case 2:
        return {
          level: 1,
          color: '#467EFF',
          text: 'Ready for Credit Assessment'
        }
      case 11:
        return {
          level: 1,
          color: '#467EFF',
          text: 'Awaiting Customer Information'
        }
      case 3:
        return {
          level: 1,
          color: '#467EFF',
          text: 'Awaiting Credit Assessment'
        }
      case 4:
        return {
          level: 2,
          color: '#467EFF',
          text: 'Credit Approved Conditionally'
        }
      case 5:
        return {
          level: 2,
          color: '#467EFF',
          text: 'Credit Approved'
        }
      case 6:
        return {
          level: 2,
          color: 'red',
          text: 'Credit Rejected'
        }
      case 7:
        return {
          level: 2,
          color: '#467EFF',
          text: 'Contract Ready for Signing'
        }
      case 8:
        return {
          level: 2,
          color: '#467EFF',
          text: 'Contract Signed'
        }
      case 10:
        return {
          level: 2,
          color: '#467EFF',
          text: 'Contract Approved'
        }
      case 12:
        return {
          level: 3,
          color: '#467EFF',
          text: 'Ready to Activate'
        }
      case 9:
        return {
          level: 4,
          color: 'green',
          text: 'Active'
        }
      default:
        return {
          level: 0,
          color: 'lightgray',
          text: 'What?'
        }
    }
  }

  return (
    <>
      {mappedData?.slice(0, amountToShow)?.map((order) => {
        const findLevelResult = findLevel(order?.status)
        return (
          <Layout>
            <p>Ref: {order?.contractId}</p>
            <HalfWidthWrapper>
              <h1>
                {order?.vehicle?.brand} - {order?.vehicle?.modelName}
              </h1>
            </HalfWidthWrapper>
            <p>
              {order?.person} <span></span>
            </p>
            <ProgressWrapper>
              <ProgressStepOne delay="0s" color={findLevelResult.level > 0 ? findLevelResult.color : 'lightgrey'} />
              <ProgressStepMiddle
                delay="0.3s"
                color={findLevelResult.level > 1 ? findLevelResult.color : 'lightgrey'}
              />
              <ProgressStepMiddle
                delay="0.6s"
                color={findLevelResult.level > 2 ? findLevelResult.color : 'lightgrey'}
              />
              <ProgressStepLast delay="0.9s" color={findLevelResult.level > 3 ? findLevelResult.color : 'lightgrey'} />
            </ProgressWrapper>
            <p>
              Status: <strong>{findLevelResult.text}</strong>
            </p>
          </Layout>
        )
      })}

      <ButtonContainer>
        {amountToShow < mappedData.length && (
          <StyledButton onClick={() => setAmountToShow(amountToShow + 3)}>Load more orders</StyledButton>
        )}{' '}
      </ButtonContainer>
    </>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: start;
`

const StyledButton = styled.button`
  border: none;
  background: none;
  color: blue;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
const PopIn = keyframes`
    0% {
        transform: scale(0.5);
        opacity: 0;

    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`

const ProgressWrapper = styled.div`
  display: flex;
  gap: 2px;
  width: 80%;
  height: 15px;
  align-items: center;
  justify-content: center;
`
const ProgressStepMiddle = styled.div`
  content: '';
  height: 12px;
  width: 100%;
  opacity: 0;
  @keyframes PopIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  animation: PopIn 0.5s ease-in-out 1 forwards;
  animation-delay: ${(props) => props.delay ?? '.5s'};

  background-color: ${(props) => props.color ?? 'lightgray'};
`

const ProgressStepOne = styled.div`
  content: '';
  height: 12px;
  width: 100%;
  border-radius: 8px 0 0 8px;
  @keyframes PopIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  opacity: 0;
  animation: PopIn 0.5s ease-in-out 1 forwards;
  display: inline-block;
  animation-delay: ${(props) => props.delay ?? '.5s'};
  background-color: ${(props) => props.color ?? 'lightgray'};
`

const ProgressStepLast = styled.div`
  content: '';
  height: 12px;
  width: 100%;
  border-radius: 0 8px 8px 0;
  @keyframes PopIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  opacity: 0;
  animation: PopIn 0.5s ease-in-out 1 forwards;
  animation-delay: ${(props) => props.delay ?? '.5s'};
  display: inline-block;
  background-color: ${(props) => props.color ?? 'lightgray'};
`
const Layout = styled.div`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  background-color: var(--color-white);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const HalfWidthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
`

const SkeletonLoader = styled.div`
  width: 100%;
  height: 20px;
  background: lightgray;
  background-size: 200% 100%;
  @keyframes skeletonLoaderAnimation {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(50px);
    }
    100% {
      transform: translateX(0%);
    }
  }
  &:after {
    content: '';
    display: block;
    width: 100%;
    left: 0;
    height: 20px;
    transform: translateX(-50%);
    background: linear-gradient(90deg, transparent 25%, white 50%, transparent 75%);
    animation: skeletonLoaderAnimation 2.5s infinite ease-in-out;
  }
`
