import { Wrapper, theming } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import styled from 'styled-components'
import Container from '../../../components/Container'
import { useBudgetSelections, usePendingSignings } from '../../../context/SigningProvider'
import { H4Heading, ReportHeading } from '../../../theme/Theme.Text'
import { SigningsList } from '../components/signing/SigningsList'
import { BudgetSelectionsList } from '../components/budgetSelection/BudgetSelectionsList'
import { Link } from 'react-router-dom'
import { useUser } from '../../../context/UserProvider'
import { NewCard } from '../components/NewCard'

const Tasks = () => {
  const user = useUser()
  const { pendingSignings, isLoading: signingsLoading } = usePendingSignings(user?.user.id)
  const { budgetSelections, isLoading: budgetSelectionsLoading } = useBudgetSelections(user?.user.id)

  console.log(pendingSignings, budgetSelections)
  return (
    <Container isLoading={false} renderChildrenDuringLoading={false}>
      <StyledWrapper>
        <div>
        <TranslatedText i18nKey={'MY_TASKS'}>
          {(value) => <ReportHeading>{value ?? 'Mine oppgaver'}</ReportHeading>}
        </TranslatedText>
     <H4Heading>{'Documents for signing'}</H4Heading>
        <NewCard>
          <SigningSvg />
          <div>
            <p>Toyota Yaris Grmn - Budget 0038555</p>
            <p>Due 22.12.2023</p>
          </div>
          <SecondaryButton>Go to signing</SecondaryButton>
        </NewCard>
        </div>
        <div>
        <>
          <TranslatedText i18nKey={'CUSTOMIZE_CAR_OPTIONS'}>
            {(value) => <H4Heading>{value ?? 'Gjøre tilvalg på bil'}</H4Heading>}
          </TranslatedText>
        </>

        <NewCard>
          <SidewayCardBlue />
          <div>
            <p>Toyota Yaris Grmn - Budget 0038555</p>
          </div>
          <SecondaryButton>Go to signing</SecondaryButton>
        </NewCard>
        </div>
      </StyledWrapper>
    </Container>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
`

const SecondaryButton = styled.button`
  background-color: #ffffff;
  border-style: solid solid solid solid;
  border-color: var(--color-primary);
  border-radius: 8px;
  border-width: 4px;
  padding: 0.5rem 1rem;
  :hover {
    cursor: pointer;
    background: lightgray;
  }
`
export default Tasks

export function SigningSvg() {
  return (
    <svg width="29" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 18H6.983c.332-.655.666-1.329 1-2.014 1.866.206 4.077-1.1 6.592-3.902.075.207.159.421.25.637.814 1.906 1.864 2.993 3.125 3.232 1.293.25 2.573-.404 3.885-1.978C22.688 14.984 24.438 16 28 16a1 1 0 0 0 0-2c-3.829 0-4.944-1.324-5-2.027a1 1 0 0 0-1.939-.318c-1.52 2.281-2.422 2.393-2.75 2.333-1.04-.197-2.01-2.617-2.336-4.19a1 1 0 0 0-1.85-.278c-2.475 3.078-4.135 4.125-5.176 4.393 1.061-2.36 1.854-4.432 2.361-6.176.853-2.93.915-4.978.189-6.262C11.124.805 10.338.006 8.76 0H8.73c-2 .014-3.575 1.913-4.328 5.213-.448 1.963-.522 4.148-.203 6 .318 1.85.982 3.188 1.943 3.986-.465.966-.94 1.91-1.403 2.804H2a1 1 0 0 0 0 2h1.676c-1.415 2.625-2.515 4.454-2.532 4.485a1 1 0 1 0 1.714 1.03c.018-.033 1.408-2.35 3.087-5.515H28a1 1 0 0 0 0-2V18Zm-5-6v-.026V12ZM6.35 5.657C6.848 3.473 7.81 2 8.75 2c.656 0 .875.232 1 .459.375.666.815 3.023-2.706 10.796-1.024-1.411-1.373-4.63-.695-7.598Z"
        fill="#076EFF"
      />
    </svg>
  )
}

export function SidewayCardBlue() {
  return (
    <svg fill="none" height={18} width={32} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 18">
      <path
        d="M30 6h-3.586L21 .586A1.982 1.982 0 0 0 19.586 0H5.535a2 2 0 0 0-1.66.89L.167 6.445A1.009 1.009 0 0 0 0 7v6a2 2 0 0 0 2 2h2.125a4 4 0 0 0 7.75 0h8.25a4 4 0 0 0 7.75 0H30a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2ZM5.535 2h14.051l4 4H2.875l2.66-4ZM8 16a2 2 0 1 1 0-4 2 2 0 0 1 0 4Zm16 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4Zm6-3h-2.125a4 4 0 0 0-7.75 0h-8.25a4 4 0 0 0-7.75 0H2V8h28v5Z"
        fill="#076EFF"
      />
    </svg>
  )
}
