import React, { useEffect, useMemo, useState } from 'react'
import { Wrapper } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import {
  colorFilter,
  EXTERIOR_PAINT_TYPE_SCHEMA_ID,
  flattenAccessories,
  packageFilter,
  paintTypeFilter,
} from '../../../api/jato'
import { useParams } from 'react-router-dom'
import { CarTopSummary } from '../components/CarTopSummary'
import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'
import { MultiOptionListItem } from '../components/fields/MultiOptionListItem/MultiOptionListItem'
import { useCarOptionRules } from '../hooks/useCarOptionRules'
import { useCarPrice } from '../hooks/useCarPrice'
import { get } from 'lodash'
import formatOptionName from '../../../utils/formatOptionName'
import CustomSkeleton from '../components/skeleton/skeleton'
import useCarConfiguration, { CONFIGURE_CAR_OPTIONS } from '../hooks/useCarConfiguration'
import Chevron from '../../../icons/Chevron'
import FormError from '../components/FormError'
import RequiresModal from '../components/RequiresModal'
import Navigation from '../components/Navigation'
import { SmallHeading } from '../components/Headings'
import useCarConfigurationQueries from '../hooks/useCarConfigurationQueries'
import { useFlow } from '../../../context/FlowProvider'
import { getNorskMontertUtstyr, NORWEGIAN_MOUNTED_ACCESSORIES } from '../../../utils/norwegianMountedOptions'
import { MultiOptionListItemSimple } from '../components/fields/MultiOptionListItem/MultiOptionListItemSimple'
import ImageDialog from '../components/imageGallery/ImageDialog'
import Container from '../../../components/Container'
import Toast from '../components/toast/Toast'
import ChoosePaint from '../components/ChoosePaint'
import NorwegianEquipment from '../components/NorwegianEquipment'

export const STANDARD_PAINT = {
  optionName: 'Standard lakk',
  optionId: 9999,
  price: 0,
  requires: [],
  excludes: [],
  includes: [],
  categoryName: 'utvendig design',
  optionTypeName: 'option',
  schemaIds: EXTERIOR_PAINT_TYPE_SCHEMA_ID,
}

const colorBelongsToPaint = (color, paint) => {
  return color.requires.length > 0
    ? color.requires.some((curr) => curr.optionId.every((i) => i === paint.optionId))
    : true
}

const ConfigureCarOptions = () => {
  const { flowId } = useParams()
  const { flow, refetch } = useFlow(flowId)

  const { saveAndNavigate, errorHandler, companyId, isRedirecting, isLoading } = useCarConfiguration({
    page: CONFIGURE_CAR_OPTIONS,
    flow,
    refetch,
  })

  const { carConfiguration, vehicleType, visitedSteps } = flow?.data || {}
  const { chosenOptions = [], chosenPackages = [], vehicleId } = carConfiguration || {}
  const [openPhotos, openPhotosSet] = useState(false)
  const [openOptionsMenus, openOptionsMenusSet] = useState([])

  const { accessoriesRaw } = useCarConfigurationQueries(CONFIGURE_CAR_OPTIONS, errorHandler, {
    vehicleType,
    companyId,
    vehicleId: vehicleId,
    make: carConfiguration?.makeKey,
    model: carConfiguration?.modelKey,
    modelYear: carConfiguration?.modelYear,
    bodyCode: carConfiguration?.bodyCode,
    doors: carConfiguration?.numberOfDoors,
    drivenWheels: carConfiguration?.drivenWheels,
    engine: carConfiguration?.derivativeLocal,
  })

  const { error: accessoriesError, data: accessoriesData } = accessoriesRaw

  const { accessories, photo, photos, standardEquipment } = useMemo(() => {
    if (!accessoriesData) return {}

    const { packages = [], options = {} } = accessoriesData

    return {
      accessories: { options, packages },
      photo: get(accessoriesData, 'vehiclePhotos[0]', []),
      photos: get(accessoriesData, 'vehiclePhotos', []),
      standardEquipment: get(accessoriesData, 'vehicleStandardEquipment', {}),
    }
  }, [accessoriesData])

  const handleOpenOptionsMenu = (menu) => {
    if (openOptionsMenus.includes(menu)) {
      return openOptionsMenusSet((prevState) => prevState.filter((m) => m !== menu))
    }
    openOptionsMenusSet((prevState) => [...prevState, menu])
  }

  const handlePaintChange = (paint) => replaceOptionWith(selectedPaintType, paint)

  const handleColorChange = (color) => {
    if (selectedColor && color.optionId === selectedColor.optionId) {
      return null
    }
    replaceOptionWith(selectedColor, color, true)
    removeFormError('color')
  }

  const {
    selectedOptions,
    includedOptions,
    excludedOptions,
    optionThatRequiresSelections,
    handleToggleOption,
    handleNorwegianEquipmentToggleOption,
    getOptionNameByOptionId,
    getOptionIncludedBy,
    getExcludedByOption,
    fulfillsRequirements,
    replaceOptionWith,
    handleRemoveOption,
    getSelectedByPackage,
    requiredSelections,
    needsVerification,
    selectedPackages,
    formError,
    removeFormError,
    simpleFormValidator,
    currentSelectedOption,
    setRequiredSelections,
    getOptionDependencies,
  } = useCarOptionRules(
    [...flattenAccessories(accessories), STANDARD_PAINT],
    [...chosenOptions, ...chosenPackages],
    CONFIGURE_CAR_OPTIONS,
    !!carConfiguration?.derivativeLocal,
    visitedSteps,
    !!carConfiguration?.chosenWheelPackage
  )
  // Effectively refreshes menus when options are updated. Ensures that checkboxes are up-to-date with what's actually selected

  useEffect(() => {
    openOptionsMenusSet((prevState) => prevState)
  }, [selectedOptions.length])
  const selectedPaintType = useMemo(() => (flow ? selectedOptions.find(paintTypeFilter) : null), [selectedOptions])
  const selectedColor = useMemo(() => (flow ? selectedOptions.find(colorFilter) : null), [selectedOptions])
  const updateFlowHandler = ({ direction, route }) => {
    saveAndNavigate.mutate({
      carConfiguration: {
        ...carConfiguration,
        chosenOptions: selectedOptions.filter((o) => !packageFilter(o)),
        chosenPackages: selectedPackages,
        chosenNorwegianEquipment: selectedOptions.filter((o) => o.optionType === NORWEGIAN_MOUNTED_ACCESSORIES),
      },
      direction,
      route,
    })
  }

  const { monthlyPrice } = useCarPrice({
    configuration: accessories ? carConfiguration : null,
    options: selectedOptions,
    vehicleType: vehicleType,
    financingOptions: carConfiguration?.chosenFinancingOptions || {},
    errorHandler,
  })

  const { paints, options } = useMemo(() => {
    if (!accessories) return {}
    const paints = accessories.options.exterior.filter(paintTypeFilter)
    const colors = accessories.options.paint.filter(colorFilter)

    const allOptionsExceptPaintAndColor = Object.keys(accessories.options).reduce((obj, optionKey) => {
      if (optionKey !== 'paint') {
        obj[optionKey] = accessories.options[optionKey].filter(
          (o) =>
            !(
              paints.some((paint) => o.optionId === paint.optionId) ||
              colors.some((color) => o.optionId === color.optionId)
            )
        )
      }
      return obj
    }, {})

    const standardPaint = {
      paintType: STANDARD_PAINT,
      colors: colors.filter((c) => paints.every((p) => !c.requires.some((r) => r.optionId.includes(p.optionId)))),
    }
    return {
      paints: [
        standardPaint,
        ...paints.map((ext, index) => ({
          paintType: ext,
          index,
          colors: colors.filter((color) => colorBelongsToPaint(color, ext)),
        })),
      ].filter((group) => group.colors.length > 0),
      options: allOptionsExceptPaintAndColor,
    }
  }, [accessories])

  const buildElements = (options) =>
    options
      .filter((item) => selectedOptions.some((o) => o.optionId === item.optionId))
      .map((option) => <li key={option.optionId}>{formatOptionName(option.optionName)}</li>)

  const chunkItems = (array, chunk_size) =>
    Array(Math.ceil(array.length / chunk_size))
      .fill()
      .map((_, index) => index * chunk_size)
      .map((begin) => array.slice(begin, begin + chunk_size))

  const getSelectionLists = (options, max) => {
    const optionElements = buildElements(options)
    return chunkItems(optionElements, max)
  }

  if (isRedirecting) {
    return null
  }

  const norwegianEquipment = useMemo(() => {
    if (!carConfiguration) {
      return []
    }
    return getNorskMontertUtstyr(carConfiguration)
  }, [carConfiguration])

  return (
    <Container isLoading={isLoading} width="100%">
      <CarTopSummary
        car={carConfiguration}
        photoUrl={photo || carConfiguration?.photoUrl || null}
        coreCalculatedMonthlyPrice={monthlyPrice}
        vehiclePhotos={photos}
        standardEquipment={standardEquipment}
        onPress={() => {
          if (photos.length > 0) {
            openPhotosSet(!!photos)
          }
        }}
        page={CONFIGURE_CAR_OPTIONS}
        saveAndNavigate={updateFlowHandler}
        routeValidator={simpleFormValidator}
        visitedPages={visitedSteps}
      />
      <StyledWrapper>
        <RequiresModal
          requiredSelections={requiredSelections}
          needsVerification={needsVerification}
          optionThatRequiresSelections={optionThatRequiresSelections}
          currentSelectedOption={currentSelectedOption}
          handleToggleOption={handleToggleOption}
          getOptionNameByOptionId={getOptionNameByOptionId}
          getOptionIncludedBy={getOptionIncludedBy}
          getExcludedByOption={getExcludedByOption}
          touchedOptions={{ selectedOptions, excludedOptions, includedOptions }}
          fulfillsRequirements={fulfillsRequirements}
          getSelectedByPackage={getSelectedByPackage}
          handleRemoveOption={handleRemoveOption}
          getOptionDependencies={getOptionDependencies}
        />
        {photos && <ImageDialog photos={photos} isOpen={openPhotos} openSet={openPhotosSet} />}
        <ChoosePaint
          paints={paints}
          accessoriesError={accessoriesError}
          formError={formError}
          selectedPaintType={selectedPaintType}
          handlePaintChange={handlePaintChange}
          selectedColor={selectedColor}
          handleColorChange={handleColorChange}
        />
        {options
          ? Object.keys(options).map(
              (optionKey) =>
                options[optionKey].length > 0 && (
                  <OptionsMenu key={optionKey} isOpen={openOptionsMenus.includes(optionKey)}>
                    <div onClick={() => handleOpenOptionsMenu(optionKey)}>
                      <TranslatedText i18nKey={`OPTION_HEADING_${optionKey.toUpperCase()}`}>
                        {(value) => <SmallHeading>{value}</SmallHeading>}
                      </TranslatedText>
                      <StyledChevron isOpen={openOptionsMenus.includes(optionKey)} />
                    </div>
                    {openOptionsMenus.includes(optionKey) ? (
                      <div>
                        {options[optionKey].map((option, i) => (
                          <>
                            <MultiOptionListItem
                              key={option.optionId}
                              option={option}
                              onSelect={handleToggleOption}
                              getOptionNameByOptionId={getOptionNameByOptionId}
                              getOptionIncludedBy={getOptionIncludedBy}
                              getExcludedByOption={getExcludedByOption}
                              touchedOptions={{ selectedOptions, excludedOptions, includedOptions }}
                              fulfillsRequirements={fulfillsRequirements}
                              getSelectedByPackage={getSelectedByPackage}
                              handleRemoveOption={handleRemoveOption}
                              getOptionDependencies={getOptionDependencies}
                            />
                          </>
                        ))}
                      </div>
                    ) : (
                      <SelectionLists>
                        {getSelectionLists(options[optionKey], 5).map((list) => (
                          <ol>{list}</ol>
                        ))}
                      </SelectionLists>
                    )}
                  </OptionsMenu>
                )
            )
          : !accessoriesError && (
              <CustomSkeleton
                skeletons={[
                  {
                    height: 20,
                    width: 300,
                  },
                ]}
              />
            )}

        <NorwegianEquipment
          norwegianEquipment={norwegianEquipment}
          OptionsMenu={OptionsMenu}
          openOptionsMenus={openOptionsMenus}
          handleOpenOptionsMenu={handleOpenOptionsMenu}
          MultiOptionListItemSimple={MultiOptionListItemSimple}
          handleNorwegianEquipmentToggleOption={handleNorwegianEquipmentToggleOption}
          selectedOptions={selectedOptions}
          SelectionLists={SelectionLists}
          getSelectionLists={getSelectionLists}
        />
        <FormError
          page={CONFIGURE_CAR_OPTIONS}
          errors={formError}
          setRequiredSelections={setRequiredSelections}
          navigate={updateFlowHandler}
        />
        <Navigation updateFlowHandler={updateFlowHandler} />
      </StyledWrapper>
      <Toast limit={1} />
    </Container>
  )
}

export default ConfigureCarOptions

const StyledWrapper = styled(Wrapper)`
  > div {
    margin-bottom: var(--spacing-medium);
  }
`

const OptionsMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--color-white);
  border: var(--spacing-microMinus) solid var(--color-line);

  @keyframes add {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => (props.isOpen ? `var(--color-primaryLight)` : `var(--color-bg)`)};
    height: var(--spacing-large);
    line-height: 2.5em;
    cursor: pointer;
    padding: 0 var(--spacing-smallPlus);
  }

  > div:last-child {
    transform-origin: 75% 0;
    animation: add 0.4s linear;
  }
`

const SelectionLists = styled.div`
  display: flex;
  flex-wrap: wrap;

  > ol {
    list-style-type: disc;
    padding: 0 var(--spacing-mediumPlus) var(--spacing-small) var(--spacing-mediumPlus);
    flex: 1 0 40%;
  }
`

const StyledChevron = styled(Chevron)`
  transform: ${(p) => (p.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  width: 31px;
  height: 31px;
`
