import { TaskItem } from "./TaskItem";
import styled from "styled-components"
export function TaskList({taskType = "Signing", tasks = [], handleTaskClick}) {
    return (
        <TaskListWrapper>
            <h1>{taskType}</h1>
            {tasks.length > 0 ? tasks.map((task) => {
                return <TaskItem handleTaskClick={handleTaskClick} task={task} />
            }): <NoActiveTasksCenter>No tasks</NoActiveTasksCenter>}
        </TaskListWrapper>
    )
}

const TaskListWrapper = styled.div`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  background-color: var(--color-white);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const NoActiveTasksCenter = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: var(--spacing-small);
    color: lightgray;
  `