import { useState } from 'react'
import { Button, theming } from '@staccx/bento'
import { TranslatedText, i18nInstance } from '@staccx/i18n'
import styled from 'styled-components'
import { BooleanOption } from './BooleanOption'
import { CheckBoxOption } from './CheckBoxOption'
import { completeTask } from '../../../../api/flow'
import { getSigningTasks } from '../../../../api/api'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../../../context/UserProvider'
import { getSigningFlows } from '../../../../api/api'

export const Selection = ({ taskId, budgetId, selections }) => {
  const { userData } = useUserContext()
  const userEmail = userData?.user?.contactDetails?.mail ?? ''

  const navigate = useNavigate()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [selectedBudgetSelections, setSelectedBudgetSelections] = useState({})

  const updateBudgetSelections = (option, selectedBudgetSelections) => {
    const { [option.typeCode]: removedSelection, ...updatedSelections } = selectedBudgetSelections
    if (selectedBudgetSelections[option.typeCode]) {
      return updatedSelections
    }
    return { ...selectedBudgetSelections, [option.typeCode]: option }
  }

  const handleRadioClick = (option) => {
    const updatedSelections = updateBudgetSelections(option, selectedBudgetSelections)
    setSelectedBudgetSelections(updatedSelections)
  }

  const handleCheckBoxClick = (option) => {
    const updatedSelections = updateBudgetSelections(option, selectedBudgetSelections)
    setSelectedBudgetSelections(updatedSelections)
    validateCheckBox(option, updatedSelections)
  }

  const validateCheckBox = (option, updatedSelections) => {
    const maximumChoices = option.maximumCustomerChoices
    const selectedChoices = Object.values(updatedSelections).filter(
      (i) => i.customerChoice === option.customerChoice
    ).length

    let errorMessage = ''
    if (selectedChoices === 0 && maximumChoices === 1) {
      errorMessage = i18nInstance.translate('MISSING_REQUIRED_FIELD') ?? 'Mangler påkrevd felt.'
    } else if (maximumChoices > 0 && selectedChoices > maximumChoices) {
      errorMessage =
        i18nInstance.translate('TOO_MANY_OPTIONS_SELECTED') ??
        'Du har valgt for mange alternativer. Vennligst fjern noen før du fortsetter.'
    }

    setFormErrors({ ...formErrors, [option.customerChoice]: errorMessage })

    return !errorMessage
  }

  // Validates that form contains no errors
  const validateForm = () => {
    const firmSelections = Object.values(selections)
      .flat()
      .filter((selection) => selection.inputType === 'Firm')

    const isValidCheckboxes = firmSelections.every((selection) => validateCheckBox(selection, selectedBudgetSelections))

    return isValidCheckboxes
  }

  const checkForSigning = async (attempt = 1) => {
    if (attempt <= 7) {
      const signings = await getSigningTasks()
      if (signings) {
        const signing = signings.tasks.find((i) => i.context.budgetId === budgetId)
        if (signing) {
          const signingFlows = await getSigningFlows(signing.variables.referenceId)
          if (signingFlows) {
            const flow = signingFlows.flows.find((i) => i.flowId === signing.flowId)
            if (flow) {
              const flowEmail = flow.data?.budgetData?.signer?.length > 0 ? flow.data.budgetData.signer[0].email : ''
              if (flowEmail.toLowerCase() === userEmail.toLowerCase()) {
                window.location.href = signing.context.document.signingUrl
              } else {
                navigate('/tasks/budget-selection/complete')
              }
            }
          }
        } else {
          // Call the function again after 2 seconds
          setTimeout(() => checkForSigning(attempt + 1), 2000)
        }
      }
    } else {
      // Redirect to tasks page after 5 failed attempts
      navigate('/tasks')
    }
  }

  const handleSubmit = async () => {
    const isValid = validateForm()
    if (isValid && !isSubmitting) {
      setIsSubmitting(true)
      const data = Object.keys(selectedBudgetSelections).reduce(
        (acc, curr) => acc.concat(selectedBudgetSelections[curr]),
        []
      )

      await completeTask({ taskId, data })
        .then(() => setTimeout(() => checkForSigning(), 2000))
        .catch(() => {
          setIsSubmitting(false)
          setFormErrors({
            ...formErrors,
            ['Form']:
              i18nInstance.translate('BUDGET_SELECTION_SUBMIT_ERROR') ??
              'Det oppstod en feil under innsending av dine tilvalg, prøv igjen senere eller kontakt oss.'
          })
        })
    }
  }

  return (
    <>
      {Object.entries(selections)
        .sort(([, optionsA], [, optionsB]) => {
          const inputTypeA = optionsA[0].inputType
          const inputTypeB = optionsB[0].inputType

          // Sorts form by inputType in following order: Boolean -> Firm -> List new
          return inputTypeA.localeCompare(inputTypeB)
        })
        .map(([label, options], index) => {
          const isBooleanOption = options.length === 1 && options[0].inputType === 'Boolean'
          const isFirmOption = options[0].inputType === 'Firm'
          const isListNewOption = options[0].inputType === 'List new'

          if (isBooleanOption) {
            const option = options[0]
            const selected = selectedBudgetSelections[option.typeCode]
            return (
              <BooleanOption
                key={option.typeCode}
                label={label}
                selected={selected}
                onClick={() => handleRadioClick(option)}
              />
            )
          }

          if (isFirmOption || isListNewOption) {
            const errors = formErrors[label]
            return (
              <CheckBoxOption
                key={index}
                label={label}
                options={options}
                onChange={handleCheckBoxClick}
                errors={errors}
              />
            )
          }

          // Handle unsupported option types
          return null
        })}
      <StyledButton onClick={() => handleSubmit()} aria-label="Gå til signering" role="button" disabled={isSubmitting}>
        {isSubmitting && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        <TranslatedText i18nKey="GO_TO_SIGNING">{(value) => value ?? 'Gå til signering'}</TranslatedText>
      </StyledButton>
      {isSubmitting && (
        <InfoText>
          <TranslatedText i18nKey="CREATING_SIGNING_ORDER">
            {(value) =>
              value ?? 'Vi oppretter din signering. Dette kan ta opptil 20 sekunder, vennligst ikke lukk siden.'
            }
          </TranslatedText>
        </InfoText>
      )}
      {formErrors['Form'] && <Error>{formErrors['Form']}</Error>}
    </>
  )
}

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'auto')};
`

const Spinner = styled.div`
  border: 4px transparent solid;
  border-top: 4px white solid;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  animation: spin 1.5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: ${theming.spacing.small()};
`

const Error = styled.p`
  color: var(--color-warning);
  margin-top: ${theming.spacing.small()};
`

const InfoText = styled.span`
  display: block;
  margin-top: ${theming.spacing.small()};
`
