import { ComparisonGraph } from "./ComparisonGraph";
import styled from "styled-components"
export function EnvironmentReportGraph({ data }) {
    console.log(data)

    const co2Data = {
        title: "Average CO2",
        data: data.averageCo2PerCar,
        overAllAverage: 1100,
    }

    const noxData = {
        title: "Average NOX",
        data: data.averageNoxPerCar,
        overAllAverage: 8900,
    }
    
    return <StyledContainer>
    <ComparisonGraph data={co2Data} />
    <ComparisonGraph data={noxData} />
    </StyledContainer>
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
`