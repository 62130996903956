import React from "react"
import { Heading, Text } from "@staccx/bento"
import ReactMarkdown from "react-markdown"

const blockSerializer = {
  types: {
    block: props => {
      switch (props.node.style) {
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
          return <Heading level={props.node.style}>{props.children}</Heading>
        default:
          return <Text>{props.children}</Text>
      }
    }, 
    localeMarkdown: ({node}) => console.log(node) || <ReactMarkdown children={node} />
  },
  marks: {}
}

export default blockSerializer
