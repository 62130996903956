import React, { useMemo, useState } from 'react'
import { Heading, Label, Layout } from '@staccx/bento'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import DropDown from '../../../components/DropDownMenu'
import { TranslatedText } from '@staccx/i18n'
import FuelReportTable from '../components/FuelTables/FuelReport'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import { useQuery } from 'react-query'
import { fetchFuelReport } from '../../../api/api'
import { useChosenCompany } from '../../../context/UserProvider'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'

const FuelReport = () => {
  const [selectedYear, selectedYearSet] = useState(new Date().getFullYear().toString())

  const company = useChosenCompany()
  const companyId = company && company.id

  const { data = [], status } = useQuery(['fuelReport', companyId], () => fetchFuelReport(company.id), {
    enabled: !!companyId
  })

  const fuelReportYears = useMemo(() => {
    if (!data) return []
    return [...new Set(data.map((el) => el.tyear))]
  }, [data])

  const agreementFilter = useFilterOnConsolidateAgreement()
  const filterFunc = agreementFilter('consolidatedInvoiceAgreement')

  const items = fuelReportYears.map((year) => ({ value: year, label: year }))
  const mockFuelReport = [
  {
    obsDescription: "Observation: Needs Maintenance",
    volume: 15,
    amountIncVAT: 1200.50,
    driverName: "Jane Smith",
    carDescription: "SUV Model Y",
    invoiceGroupDescription: "Group 2",
    vehicleID: "67890",
    regNo: "XYZ789",
        consolidatedInvoiceAgreement: "INV43-3225 - Agreement",

  },
  {
    consolidatedInvoiceAgreement: "INV4325 - Agreement",
    volume: 20,
    amountIncVAT: 1800.75,
    driverName: "Michael Johnson",
    carDescription: "Compact Model Z",
    invoiceGroupDescription: "Group 3",
    vehicleID: "54321",
    regNo: "LMN456"
  },
  {
        consolidatedInvoiceAgreement: "INV9932 - Agreement",
    obsDescription: "Observation: Tire Replacement Required",
    volume: 18,
    amountIncVAT: 1500.00,
    driverName: "Emily Brown",
    carDescription: "Electric Model E",
    invoiceGroupDescription: "Group 1",
    vehicleID: "98765",
    regNo: "PQR123"
  }
]

  return (
    <Container width="unlimited">
      <Layout rowGap={'small'}>
        <TranslatedText i18nKey={'FUELREPORT_HEADING'}>
          {(value) => <Heading level="1">{value}</Heading>}
        </TranslatedText>
        <Layout variant="fiftyFifty">
          <TranslatedText i18nKey="PERIOD">
            {(value) => (
              <div>
                <Label>{value}</Label>
                <DropDown
                  options={items}
                  onChange={(selectedYear) => selectedYearSet(selectedYear.value)}
                  value={{ value: selectedYear, label: selectedYear }}
                />
              </div>
            )}
          </TranslatedText>
          <ConsolidateInvoiceSelect />
        </Layout>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>
      <FuelReportTable
        isLoading={status === 'loading'}
        data={mockFuelReport}
      />
    </Container>
  )
}

export default FuelReport
