export const activeVehicles = [
  {
    registrationNumber: 'AB12345',
    driver: {
      name: 'Fredrik Myhre',
      driverName: "Fredrik Myhre",
    },
    status: 'active',
    make: 'Toyota',
    carId: '123',
    model: '1990',
    tollPassing: [
    {
      date: "2021-12-12",
      Description: "Toll booth",
      ReferenceNo: "70443509808953879",
    }
    ],
    refuel: 50,
    purchase: 20,
    deviations: 20,
    additionalProducts: [
      {
        Description: "Circle K",
        ReferenceNo: "70443509808953879",
      },
        {
        Description: "Road assistance",
        ReferenceNo: "9578000741831435",
      },
             {
        Description: "SHELL",
        ReferenceNo: "1325482",
      },
      {
        Description: "CLEVER One EV",
        ReferenceNo: "99943",
      }
    ],
    events: [{
      eventType: "deviation",
      date: "2021-12-12",
      carId: "123",
      description: "Speeding",
      driver: {
        name: "Fredrik Myhre",
      },
    }],
    maturity: '2022-12-31',
    deliveryDate: '2020-01-01',
    budgetNo: '70443509808953879',
    hasReplacementCar: false,
    active: true,
    cars: ['Toyota Camry', 'Honda Accord'],
    endsRatio: 0.7,
    filterByCars: true,
    height: 150,
    incoming: ['Ford Mustang'],
    incomingWidth: 200,
    maxCarsSpread: 5,
    outgoing: ['Chevrolet Silverado'],
    outgoingMonths: 6,
    accountNo: '3294',
    fuelVolume: [10, 50, 20, 2, 0],
    fuelCard: [32, 50, 20, 2, 100],
    tollBoothData: [10, 50, 20, 2, 100],
    contractMileage: 200000,
    simulatedMileage: 180000,
    mileage: 165000
  },

  {
     fuelVolume: [43, 12, 44, 22, 10],
    fuelCard: [32, 10, 20, 22, 33],
    tollBoothData: [23, 44, 12, 22, 98],
    registrationNumber: 'OP45678',
    driver: {
      name: 'Michael Davis',
      type: 'Main Contact'
    },
    status: 'active',
    make: 'Chevrolet',
    accountNo: '3294',
    model: '2019',
     tollPassing: [
    {
      date: "2021-12-12",
      Description: "Toll booth",
      ReferenceNo: "70443509808953879",
    }
    ],
    refuel: 50,
    purchase: 20,
    deviations: 20,
   additionalProducts: [
      {
        Description: "Circle K",
        ReferenceNo: "70443509808953879",
      },
        {
        Description: "Road assistance",
        ReferenceNo: "9578000741831435",
      },
             {
        Description: "SHELL",
        ReferenceNo: "	1325482",
      },
      {
        Description: "CLEVER One EV",
        ReferenceNo: "99943",
      }
    ],
    events: [{
      eventType: "deviation",
      date: "2021-12-12",
      carId: "123",
      description: "Speeding",
      driver: {
        name: "Fredrik Myhre",
      },
    }],
    contractMileage: 20000,
    simulatedMileage: 180000,
    maturity: '2026-05-25',
    deliveryDate: '2022-01-20',
    budgetNo: '987654',
    hasReplacementCar: true,
    active: false,
    cars: ['Chevrolet Malibu', 'Honda CR-V'],
    endsRatio: 0.9,
    filterByCars: false,
    engine: '2.5L',
    height: 170,
    fuel: 'Electric',
    accountNo: '45321789',
    incoming: ['Hyundai Sonata'],
    incomingWidth: 185,
    maxCarsSpread: 7,
    mileage: 165000,
    outgoing: ['Kia Sportage'],
    outgoingMonths: 5
  },
  {
    registrationNumber: 'QR56789',
    driver: {
      name: 'Alex Miller',
      type: 'Secondary Contact'
    },
    status: 'active',
    make: 'Nissan',
    model: '2016',
     tollPassing: [
    {
      date: "2021-12-12",
      Description: "Toll booth",
      ReferenceNo: "70443509808953879",
    }
    ],
    refuel: 50,
    purchase: 20,
    deviations: 20,
    additionalProducts: [
      {
        Description: "Circle K",
        ReferenceNo: "70443509808953879",
      },
        {
        Description: "Road assistance",
        ReferenceNo: "9578000741831435",
      },
             {
        Description: "SHELL",
        ReferenceNo: "	1325482",
      },
      {
        Description: "CLEVER One EV",
        ReferenceNo: "99943",
      }
    ],
    events: [{
      eventType: "deviation",
      date: "2021-12-12",
      carId: "123",
      description: "Speeding",
      driver: {
        name: "Fredrik Myhre",
      },
    }],
    fuelVolume: [46, 21, 39, 19, 13],
fuelCard: [36, 14, 23, 24, 32],
tollBoothData: [26, 43, 15, 24, 102],
    accountNo: '3294',
    contractMileage: 140000,
    simulatedMileage: 100000,
    maturity: '2024-12-30',
    deliveryDate: '2020-10-05',
    budgetNo: '234567',
    hasReplacementCar: true,
    active: true,
    cars: ['Nissan Altima', 'Toyota Camry'],
    endsRatio: 0.85,
    filterByCars: true,
    engine: '2.4L',
    height: 175,
    fuel: 'Gasoline',
    accountNo: '89532147',
    incoming: ['Honda Accord'],
    incomingWidth: 180,
    maxCarsSpread: 5,
    mileage: 80000,
    outgoing: ['Ford Explorer'],
    outgoingMonths: 6
  }
]

export const incomingVehicles = [
  {
    registrationNumber: 'MN34567',
    driver: {
      name: 'Sarah Johnson',
      type: 'Main Contact'
    },
    status: 'incoming',
    make: 'Ford',
    accountNo: '3294',
    model: '2018',
    contractMileage: 180000,
    simulatedMileage: 145000,
    maturity: '2025-10-15',
    deliveryDate: '2021-12-10',
    budgetNo: '876543',
    hasReplacementCar: false,
    active: true,
    cars: ['Ford Fusion', 'Chevrolet Cruze'],
    endsRatio: 0.6,
    filterByCars: true,
    additionalProducts: [
      {
        Description: "Circle K",
        ReferenceNo: "70443509808953879",
      },
        {
        Description: "Road assistance",
        ReferenceNo: "9578000741831435",
      },
             {
        Description: "SHELL",
        ReferenceNo: "	1325482",
      },
      {
        Description: "CLEVER One EV",
        ReferenceNo: "99943",
      }
    ],
    engine: '1.5L',
    height: 160,
    fuel: 'Hybrid',
    accountNo: '34218975',
    incoming: ['Toyota RAV4'],
    incomingWidth: 190,
    maxCarsSpread: 4,
        tollPassing: [
    {
      date: "2021-12-12",
      Description: "Toll booth",
      ReferenceNo: "70443509808953879",
    }
    ],
    refuel: 50,
    purchase: 20,
    deviations: 20,
    additionalProducts: [
    
    ],
    events: [
    ],
    mileage: 5,
    fuelVolume: [0],
fuelCard: [0],
tollBoothData: [0],
    outgoing: ['Nissan Rogue'],
    outgoingMonths: 3
  }
]

export const outgoingVehicles = [
  {
    registrationNumber: 'KL89012',
    accountNo: '3294',
    driver: {
      name: 'John Smith',
      type: 'Secondary Contact'
    },
    status: 'outgoing',
    additionalProducts: [
      {
        Description: "Circle K",
        ReferenceNo: "70443509808953879",
      },
        {
        Description: "Road assistance",
        ReferenceNo: "9578000741831435",
      },
             {
        Description: "SHELL",
        ReferenceNo: "	1325482",
      },
      {
        Description: "CLEVER One EV",
        ReferenceNo: "99943",
      }
    ],
    make: 'Honda',
    model: '2015',
     tollPassing: [
    {
      date: "2021-12-12",
      Description: "Toll booth",
      ReferenceNo: "70443509808953879",
    }
    ],
    refuel: 50,
    purchase: 20,
    deviations: 20,

    events: [{
      eventType: "deviation",
      date: "2021-12-12",
      carId: "123",
      description: "Speeding",
      driver: {
        name: "Fredrik Myhre",
      },
    }],
    contractMileage: 120000,
    simulatedMileage: 80000,
    fuelVolume: [44, 23, 41, 21, 14],
fuelCard: [34, 12, 20, 26, 33],
tollBoothData: [24, 42, 14, 22, 98],
    maturity: '2024-08-20',
    deliveryDate: '2020-05-15',
    budgetNo: '543210',
    hasReplacementCar: false,
    active: true,
    cars: ['Honda Civic', 'Toyota Corolla'],
    endsRatio: 0.8,
    filterByCars: false,
    engine: '2.0L',
    height: 165,
    fuel: 'Petrol',
    accountNo: '53421678',
    incoming: ['Nissan Altima'],
    incomingWidth: 180,
    maxCarsSpread: 5,
    mileage: 110000,
    outgoing: ['Ford F-150'],
    outgoingMonths: 3
  }
]