import { css } from "styled-components"
import { Box, color, spacing } from "@staccx/bento"

Box.createVariants({
  box: {
    login: css`
      background-color: ${color.white};
      border-radius: 2px;
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.3);
      padding: ${spacing.large};
      min-width: 320px;

      .no-cssgrid & {
        position: absolute;
        top: 50%;
        text-align: center;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    `,
    lined: css`
      border: 1px solid ${color.line};
      padding: ${spacing.small};
    `,
    graph: css`
      border: 1px solid ${color.line};
      padding: 20px; v
      margin: 20px;
    `,
    paddingTop: css`
      padding-top: 22px; /* Yes, magic number - but it lines up where it is used :shrug: */
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    `,
    tabs: css`
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
      border-radius: 8px;    
      background-color: white`
  },
})
