import React, { useEffect, useMemo, useRef } from "react"
import { useQuery } from "react-query"
import { faqFromSanity } from "../../../api/api"
import {
  Button,
  ExpandListItem,
  Heading,
  Input,
  ItemGroup,
  Layout,
  List,
  Text,
  useSearch,
  Wrapper
} from "@staccx/bento"
import { TranslatedText } from "@staccx/i18n"
import { useI18nContext } from "../../../context/I18nProvider"
import BlockContent from "@sanity/block-content-to-react"
import blockSerializers from "../../../data/blockSerializers"
import { usePagination } from "../../../hooks/usePagination"
import { extractTextFromBlocks } from "../../../api/sanity"
import Container from "../../../components/Container"

const Information = () => {
  const { data = [] } = useQuery(["faq"], () => faqFromSanity())
  const {language} = useI18nContext()
  const initialized = useRef(false)

  const docs = useMemo(() => {
    if (!data) return []
    return data.map(faq => ({ 
      ...faq,
      searchBody: extractTextFromBlocks(faq.body[language])
    }))
  }, [data, language])
  
  const [result, search] = useSearch({
    documents: docs, keys: [["headingText", language], "searchBody"]
  })

  useEffect(() => {
    if (docs.length > 0 && !initialized.current) {
      search("")
      initialized.current = true
    }
  }, [docs, search])

  const faqs = useMemo(() => {
    return result.map(faq => {
      return faq.item ?? faq
    }).map(faq => ({
      headingText: faq.headingText[language],
      body: faq.body[language]
    }))
  }, [result, language])
  
  const { results, next, prev, pageCount, currentPage } = usePagination({ data: faqs })

  return (
    <Container width={"720px"}>
      <Layout>
        <TranslatedText i18nKey="INFORMATION_HEADING">
          {value => <Heading level="1">{value}</Heading>}
        </TranslatedText>

        <React.Fragment>
          <Input
            variant="search"
            onChange={e => search(e.target.value)}
            placeholder="Søk"
            id="filterinfotmsyion"
          />
          <List>
            <React.Fragment>
              {results.map(faq => (
                <ExpandListItem
                  title={faq.headingText}
                  key={faq.headingText}
                >
                  <BlockContent
                    blocks={faq.body}
                    serializers={blockSerializers}
                  />
                </ExpandListItem>
              ))}
              {pageCount > 1 && (
                <ItemGroup variant="pagination">
                  <Button 
                    variant="previous"
                    onClick={prev}
                    disabled={currentPage < 1}
                  >
                    <TranslatedText i18nKey="PREVIOUS" />
                  </Button> 
                  <Text variant="page">{`${currentPage+1}/${pageCount}`}</Text>
                  <Button 
                    variant="next"
                    onClick={next}
                    disabled={pageCount-1 <= currentPage}
                  >
                    <TranslatedText i18nKey="NEXT" />
                  </Button>
                </ItemGroup>
              )}
            </React.Fragment>
          </List>
        </React.Fragment>
      </Layout>
    </Container>
  )
}

export default Information
