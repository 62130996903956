export const CarIcon = () => {
  return <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1545_9780)">
<path d="M4.00001 10C4.00001 10.5523 3.5523 11 3.00001 11C2.44773 11 2.00001 10.5523 2.00001 10C2.00001 9.44771 2.44773 9 3.00001 9C3.5523 9 4.00001 9.44771 4.00001 10Z" fill="currentColor"/>
<path d="M13 11C13.5523 11 14 10.5523 14 10C14 9.44771 13.5523 9 13 9C12.4477 9 12 9.44771 12 10C12 10.5523 12.4477 11 13 11Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.00639588 7.4199C0.001374 7.4506 -0.000696531 7.48131 1.3589e-05 7.51167V14.5C1.3589e-05 14.7761 0.223871 15 0.500014 15H4.50001C4.77616 15 5.00001 14.7761 5.00001 14.5V13H11V14.5C11 14.7761 11.2239 15 11.5 15H15.5C15.7762 15 16 14.7761 16 14.5V7.51168C16.0007 7.48131 15.9987 7.45059 15.9936 7.41987C15.9887 7.38916 15.9809 7.35938 15.9707 7.3308L14.2025 2.02623C13.9983 1.41219 13.4236 1 12.779 1H3.22101C2.57522 1 2.00174 1.41266 1.79756 2.02623L0.0293626 7.33081C0.0190882 7.35939 0.0113427 7.38918 0.00639588 7.4199ZM2.74647 2.34177C2.81438 2.13753 3.00493 2 3.22101 2H12.779C12.9944 2 13.1859 2.13815 13.2537 2.34211L14.8063 7H1.19373L2.74647 2.34177ZM12 13H15V14H12V13ZM15 12H1.00001V8H15V12ZM1.00001 13H4.00001V14H1.00001V13Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_1545_9780">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
}

export const OutGoingCarIcon = () => {
 return   <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1568_14753)">
<path d="M8.0015 8.5273C7.71028 8.5273 7.4742 8.29122 7.4742 8L7.4742 4.30889C7.4742 4.01767 7.71028 3.78158 8.0015 3.78158C8.29272 3.78158 8.5288 4.01767 8.5288 4.30889L8.5288 8C8.5288 8.29122 8.29272 8.5273 8.0015 8.5273Z" fill="currentColor"/>
<path d="M8.0015 10.1092C7.41906 10.1092 6.9469 10.5814 6.9469 11.1638C6.9469 11.7463 7.41906 12.2184 8.0015 12.2184C8.58394 12.2184 9.0561 11.7463 9.0561 11.1638C9.0561 10.5814 8.58394 10.1092 8.0015 10.1092Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.28142 7.25428L7.22682 0.308887C7.63866 -0.102962 8.3064 -0.102962 8.71825 0.308886L15.6636 7.25428C16.0755 7.66613 16.0755 8.33387 15.6636 8.74572L8.71825 15.6911C8.3064 16.103 7.63867 16.103 7.22682 15.6911L0.281421 8.74572C-0.130428 8.33387 -0.130428 7.66613 0.28142 7.25428ZM1.02714 8L7.97253 1.0546L14.9179 8L7.97254 14.9454L1.02714 8Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_1568_14753">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
}
export const ActiveCardIcon = () => {
  return <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.55456 2.003C6.76255 2.02583 6.93432 2.17576 6.98507 2.37875L9.65725 13.0675L12.0528 8.27641C12.1375 8.10702 12.3106 8.00001 12.5 8.00001H15.5C15.7761 8.00001 16 8.22387 16 8.50001C16 8.77616 15.7761 9.00001 15.5 9.00001H12.809L9.94721 14.7236C9.85364 14.9108 9.65343 15.0199 9.44544 14.997C9.23745 14.9742 9.06568 14.8243 9.01493 14.6213L6.34275 3.93255L3.94721 8.72362C3.86252 8.89301 3.68939 9.00001 3.5 9.00001H0.5C0.223858 9.00001 0 8.77616 0 8.50001C0 8.22387 0.223858 8.00001 0.5 8.00001H3.19098L6.05279 2.27641C6.14636 2.08926 6.34657 1.98017 6.55456 2.003Z" fill="currentColor"/>
</svg>
}

export const IncomingCardIcon = () => {
  return <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.14645 2.64645C8.34171 2.45118 8.65829 2.45118 8.85355 2.64645L13.8536 7.64645C14.0488 7.84171 14.0488 8.15829 13.8536 8.35355L8.85355 13.3536C8.65829 13.5488 8.34171 13.5488 8.14645 13.3536C7.95118 13.1583 7.95118 12.8417 8.14645 12.6464L12.2929 8.5H2.5C2.22386 8.5 2 8.27614 2 8C2 7.72386 2.22386 7.5 2.5 7.5H12.2929L8.14645 3.35355C7.95118 3.15829 7.95118 2.84171 8.14645 2.64645Z" fill="currentColor"/>
</svg>

}