import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const Notification = ({ notification }) => {
  return (
      <NotificationBox>
        <Row>
          <HeaderSpan fontColor={notification.fontColor}>{notification.type}</HeaderSpan>
        </Row>
        <Row>
          <TitleSpan>{notification.title}</TitleSpan>
          <NewButton to={notification.path}>Open</NewButton>
        </Row>
      
      </NotificationBox>
  )
}

export default Notification

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`
const NotificationBox = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  height: 100%;
  color: var(--color-black);
  background-color: transparent;
  border-radius: 12px;
  padding: var(--spacing-small);
  gap: var(--spacing-tiny);
  transition: 0.2s;
`

const NewButton = styled(NavLink)`
  text-decoration: none;
  color: var(--color-primary);
  font-weight: var(--fontWeight-bold);
  background-color: transparent;
  padding: var(--spacing-tiny) 1rem;
  border-radius: 12px;
  border: 3px solid var(--color-primary);

`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding: .5rem 0;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
`
const HeaderSpan = styled.span`
  text-transform: capitalize;
  font-size: 16px;
  font-weight: var(--fontWeight-bold);

`
const TitleSpan = styled.span`

`
const MessageSpan = styled.span`

`
