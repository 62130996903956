import { useQuery, useQueryClient } from 'react-query';
import {
  CHOOSE_CAR,
  CONFIGURE_BASE_CAR,
  CONFIGURE_CAR_OPTIONS,
  CONFIGURE_WHEEL_PACKAGES,
  SUMMARY,
} from './useCarConfiguration';
import {
  getBodies, getEngines, getMakes, getModels, getVehiclesBuilder,
} from '../../../api/jato';
import { getAgreements, getWheelPackages } from '../../../api/api';
import sortByKey from '../../../utils/sortByKey';

// Jato
const MAKES_QUERY = 'makes';
const MODELS_QUERY = 'models';
const BODIES_QUERY = 'bodies';
const ENGINES_QUERY = 'engines';
const VEHICLES_BUILDER_QUERY = 'vehiclesbuilder';

// Core
const WHEEL_PACKAGES_QUERY = 'wheelPackages';
const AGREEMENTS_QUERY = 'agreements';

const queryKeysMap = {
  [MAKES_QUERY]: ['vehicleType', 'companyId'],
  [MODELS_QUERY]: ['vehicleType', 'make', 'companyId'],
  [BODIES_QUERY]: [
    'vehicleType',
    'make',
    'model',
    'companyId',
  ],
  [ENGINES_QUERY]: [
    'companyId',
    'vehicleType',
    'make',
    'model',
    'modelYear',
    'bodyCode',
    'doors',
    'drivenWheels',
  ],
  [VEHICLES_BUILDER_QUERY]: [
    'vehicleId',
    'companyId',
    'vehicleType',
  ],
  [WHEEL_PACKAGES_QUERY]: [
    'companyId',
    'make',
    'model',
    'engine',
    'fuelType'
  ],
  [AGREEMENTS_QUERY]: [
    'make',
    'model',
    'engine',
    'fuelType',
    'vehicleType',
    'mileage',
    'term',
  ],
};

// Some queries are added to a page that does not actually need said queries
// This is so that said queries are fetched before the user goes the page for faster loading
const pageQueriesMap = {
  [CHOOSE_CAR]: [
    MAKES_QUERY,
    MODELS_QUERY,
    BODIES_QUERY,
  ],
  [CONFIGURE_BASE_CAR]: [
    ENGINES_QUERY,
    VEHICLES_BUILDER_QUERY,
  ],
  [CONFIGURE_CAR_OPTIONS]: [
    VEHICLES_BUILDER_QUERY,
    WHEEL_PACKAGES_QUERY,
  ],
  [CONFIGURE_WHEEL_PACKAGES]: [
    VEHICLES_BUILDER_QUERY,
    WHEEL_PACKAGES_QUERY,
    AGREEMENTS_QUERY,
  ],
  [AGREEMENTS_QUERY]: [
    VEHICLES_BUILDER_QUERY,
    AGREEMENTS_QUERY,
    WHEEL_PACKAGES_QUERY,
  ],
  [SUMMARY]: [
    VEHICLES_BUILDER_QUERY,
    WHEEL_PACKAGES_QUERY,
  ],
};

const arrayContainsOnlyActualValues = (array) => !array.includes(undefined) && !array.includes(null) && !array.includes('');

const canRunQuery = (query, queriesToRun, queryVars) => queriesToRun.includes(query) && arrayContainsOnlyActualValues(Object.values(queryVars));

const buildQueryFilter = (query, queryVariables) => queryKeysMap[query].reduce((acc, curr) => ({
  ...acc,
  [curr]: queryVariables[curr],
}), {});

const useCarConfigurationQueries = (
  page,
  errorHandler,
  query,
) => {
  let queryVariables = query;
  const queries = pageQueriesMap?.[page];
  const queryClient = useQueryClient();

  const makesQueryPayload = buildQueryFilter(MAKES_QUERY, queryVariables);
  const makesKey = [MAKES_QUERY, ...Object.values(makesQueryPayload)];
  const makesCache = queryClient.getQueryData(makesKey);
  const { data: makesData, error: makesError } = useQuery(makesKey, () => getMakes(makesQueryPayload), {
    enabled: !makesCache && canRunQuery(MAKES_QUERY, queries, makesQueryPayload),
    onError: (err) => errorHandler(err),
  });

  const modelsQueryPayload = buildQueryFilter(MODELS_QUERY, queryVariables);
  const modelsKey = [MODELS_QUERY, ...Object.values(modelsQueryPayload)];
  const modelsCache = queryClient.getQueryData(modelsKey);
  const { data: modelsData, error: modelsError } = useQuery(modelsKey, () => getModels(modelsQueryPayload), {
    enabled: !modelsCache && canRunQuery(MAKES_QUERY, queries, modelsQueryPayload),
    onError: (err) => errorHandler(err),
  });

  const bodiesQueryPayload = buildQueryFilter(BODIES_QUERY, queryVariables);
  const bodiesKey = [BODIES_QUERY, ...Object.values(bodiesQueryPayload)];
  const bodiesCache = queryClient.getQueryData(bodiesKey);
  const { data: bodiesData, error: bodiesError } = useQuery(bodiesKey, () => getBodies(bodiesQueryPayload), {
    enabled: !bodiesCache && canRunQuery(BODIES_QUERY, queries, bodiesQueryPayload),
    onError: (err) => errorHandler(err),
  });

  const enginesQueryPayload = buildQueryFilter(ENGINES_QUERY, queryVariables);
  const engineKey = [ENGINES_QUERY, ...Object.values(enginesQueryPayload)];
  const engineCache = queryClient.getQueryData(engineKey);
  const {
    data: engines,
    error: enginesError,
  } = useQuery(engineKey, async () => {
    const fetchedEngines = await getEngines(enginesQueryPayload);
    return sortByKey(fetchedEngines[0].selections, 'derivativeLocal');
  }, {
    enabled: !engineCache && canRunQuery(ENGINES_QUERY, queries, enginesQueryPayload),
    onError: (err) => errorHandler(err),
  });

  // Default to the only engine available for the car when the user is on CONFIGURE_BASE_CAR
  if (page === CONFIGURE_BASE_CAR && !queryVariables.engine && engines?.length === 1) {
    queryVariables = {
      ...queryVariables,
      ...engines[0],
      vehicleType: query?.vehicleType
    };
  }

  const vehiclesBuilderQueryPayload = buildQueryFilter(VEHICLES_BUILDER_QUERY, queryVariables);
  const vbKey = [VEHICLES_BUILDER_QUERY, ...Object.values(vehiclesBuilderQueryPayload)];
  const vbCache = queryClient.getQueryData(vbKey);
  const {
    data: accessoriesData,
    error: accessoriesError,
  } = useQuery(vbKey, async () => getVehiclesBuilder(vehiclesBuilderQueryPayload), {
    enabled: !vbCache && canRunQuery(VEHICLES_BUILDER_QUERY, queries, vehiclesBuilderQueryPayload),
    onError: (err) => errorHandler(err),
  });

  const hybridCodeElseNull = accessoriesData ? accessoriesData?.vehicleEbrochurePage?.pageItem?.find(page => page?.schemaId === 48602) : null

  const wheelPackagesQueryPayload = buildQueryFilter(WHEEL_PACKAGES_QUERY, queryVariables);
  const wheelPackagesKey = [WHEEL_PACKAGES_QUERY, ...Object.values(wheelPackagesQueryPayload)];
  const wheelPackagesCache = queryClient.getQueryData(wheelPackagesKey);
  const { data: wheelPackages, error: wheelPackagesError } = useQuery(wheelPackagesKey, () => {
    return getWheelPackages({
      ...wheelPackagesQueryPayload,
      ...(hybridCodeElseNull ? { hybridCode: hybridCodeElseNull.dataValue } : {}),
    })
  }, {
    enabled: !wheelPackagesCache && canRunQuery(WHEEL_PACKAGES_QUERY, queries, wheelPackagesQueryPayload) && hybridCodeElseNull !== null,
    onError: (err) => errorHandler(err),
  });

  const agreementsQueryPayload = buildQueryFilter(AGREEMENTS_QUERY, queryVariables);
  const agreementsKey = [AGREEMENTS_QUERY, ...Object.values(agreementsQueryPayload)];
  const agreementsCache = queryClient.getQueryData(agreementsKey);
  const { data: agreements, error: agreementsError } = useQuery(agreementsKey, () => {
    return getAgreements({
      make: agreementsQueryPayload.make,
      model: agreementsQueryPayload.model,
      engine: agreementsQueryPayload.engine,
      fuelType: agreementsQueryPayload.fuelType,
      ...(hybridCodeElseNull ? { hybridCode: hybridCodeElseNull.dataValue } : {}),
      carCategory: agreementsQueryPayload.vehicleType,
      drivingDistance: agreementsQueryPayload.mileage,
      termCountYear: agreementsQueryPayload.term,
    })
  }, {
    enabled: !agreementsCache && canRunQuery(AGREEMENTS_QUERY, queries, agreementsQueryPayload) && hybridCodeElseNull !== null,
    onError: (err) => errorHandler(err),
  });

  return {
    makesRaw: {
      data: makesData,
      error: makesError,
    },
    modelsRaw: {
      data: modelsData,
      error: modelsError,
    },
    bodiesRaw: {
      data: bodiesData,
      error: bodiesError,
    },
    enginesRaw: {
      data: engines,
      error: enginesError,
    },
    accessoriesRaw: {
      data: accessoriesData,
      error: accessoriesError,
    },
    wheelPackagesRaw: {
      data: wheelPackages,
      error: wheelPackagesError,
    },
    agreementsRaw: {
      data: agreements,
      error: agreementsError,
    },
  };
};

export default useCarConfigurationQueries;
