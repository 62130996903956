import { TranslatedText } from '@staccx/i18n'
import TERMS_AND_MILEAGE from './data/terms-and-mileage.json'
import React from 'react'
import styled from 'styled-components'
import { Button } from '@staccx/bento'
import { SmallHeading } from './Headings'
import DropDown from '../../../components/DropDownMenu'

const YearMileage = ({ selectedTerm, termHandler, selectedMileage, mileageHandler }) => {
  const val = (item) => ({ value: item, label: `${item} km` })

  return (
    <>
      <Column>
        <Row>
          <TranslatedText i18nKey="AGREEMENT_TERM">{(value) => <SmallHeading>{value}</SmallHeading>}</TranslatedText>
        </Row>
        <Row>
          <ButtonWrapper>
            {TERMS_AND_MILEAGE.map((item) => (
              <TermButton
                key={item.year}
                variant="toggleButton"
                active={selectedTerm === item}
                onClick={() => termHandler(item)}
              >
                {item.year} <TranslatedText i18nKey="YEARS">{(value) => value.toLowerCase()}</TranslatedText>
              </TermButton>
            ))}
          </ButtonWrapper>
        </Row>
      </Column>
      <Column>
        <Row>
          <TranslatedText i18nKey="YEARLY_MILAGE">{(value) => <SmallHeading>{value}</SmallHeading>}</TranslatedText>
        </Row>
        <Row>
          <DropDown
            options={selectedTerm && selectedTerm.mileages.map((mileage) => val(mileage))}
            onChange={(e) => mileageHandler(e.value)}
            value={val(selectedMileage)}
            width="100%"
          />
        </Row>
      </Column>
    </>
  )
}

export default YearMileage

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: var(--spacing-tinyMinus) 0;
  width: 100%;
  > div.react-select-container {
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  > button {
    font-weight: var(--fontWeight-bold);
    font-size: var(--font-h3);
    margin: 0 auto;
    width: 100%;
    height: var(--spacing-large);
  }

  button:first-child {
    border-radius: var(--spacing-tinyMinus) 0 0 var(--spacing-tinyMinus);
  }

  button:last-child {
    border-radius: 0 var(--spacing-tinyMinus) var(--spacing-tinyMinus) 0;
  }
`

const TermButton = styled(Button)`
  height: var(--spacing-large);
`
