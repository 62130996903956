import React from "react"
import Bensin from "./Bensin"
import Diesel from "./Diesel"
import Elektrisk from "./Elektrisk"
import Hybrid from "./Hybrid"

const ReturnFuelIcon = ({ fuel = "diesel", props }) => {
  switch (fuel.toLowerCase()) {
    case "diesel":
      return <Diesel {...props} />
    case "bensin":
      return <Bensin {...props} />
    case "electric":
      return <Elektrisk {...props} />
    case "hybrid":
      return <Hybrid {...props} />
    default:
      return null
  }
}

export default ReturnFuelIcon
