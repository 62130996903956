import React, { useState } from 'react'
import { endOfMonth, startOfMonth, subDays } from 'date-fns'
import { Alert, Button, Input, Layout } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import { useChosenCompany } from '../../../context/UserProvider'
import { exportFleet } from '../../../api/api'
import Container from '../../../components/Container'

const now = new Date()
const start = startOfMonth(subDays(now, 30))
const end = endOfMonth(start)

const FleetExport = () => {
  const company = useChosenCompany()
  const companyId = company && company.id

  const [fromDate, fromDateSet] = useState(start.toISOString().substring(0, 10))
  const [toDate, toDateSet] = useState(end.toISOString().substring(0, 10))
  const [isLoading, isLoadingSet] = useState(false)
  const [isError, isErrorSet] = useState(false)

  const handleSubmit = () => {
    isLoadingSet(true)
    exportFleet({
      companyId,
      from: fromDate,
      to: toDate,
    })
      .then(() => {
        isLoadingSet(false)
      })
      .catch(() => {
        isErrorSet(true)
      })
  }

  return (
    <Container>
      <Layout>
        <Layout variant="form">
          <TranslatedText i18nKey={'LABEL_FROM'}>
            {(value) => (
              <Input
                id={'fleet-export-from'}
                defaultValue={fromDate}
                type={'date'}
                label={value}
                placeholder={'Dato'}
                onChange={(e) => fromDateSet(e.target.value)}
              />
            )}
          </TranslatedText>
          <TranslatedText i18nKey={'LABEL_TO'}>
            {(value) => (
              <Input
                id={'fleet-export-to'}
                defaultValue={toDate}
                type={'date'}
                label={value}
                placeholder={'Dato'}
                onChange={(e) => toDateSet(e.target.value)}
              />
            )}
          </TranslatedText>
        </Layout>
        <TranslatedText i18nKey={'SAVE_EXCEL'}>
          {(value) => <Button onClick={handleSubmit}>{value}</Button>}
        </TranslatedText>
        {isLoading && (
          <div style={{ margin: '0 auto' }}>
            <TranslatedText i18nKey={'LOADING_PLEASE_WAIT'}>{(value) => <Alert>{value}</Alert>}</TranslatedText>
          </div>
        )}
        {isError && (
          <div style={{ margin: '0 auto' }}>
            <TranslatedText i18nKey={'ERROR_LOADING_DATA'}>
              {(value) => <Alert type={'warning'}>{value}</Alert>}
            </TranslatedText>
          </div>
        )}
      </Layout>
    </Container>
  )
}

export default FleetExport
