import React from 'react'
import { Heading } from '@staccx/bento'
import { TranslatedText, i18nInstance } from '@staccx/i18n'
import { useUserContext, useUser } from '../../../context/UserProvider'
import { Formik, Field } from 'formik'
import { Button, Input, LayoutItem, Loading, Warning, Alert } from '@staccx/bento'
import styled from 'styled-components'
import { updateDriverDetails } from '../../../api/api'
import { useMutation } from 'react-query'
import customToast from '../../carConfiguration/components/toast/customToast'
import Toast from '../../carConfiguration/components/toast/Toast'

const Yup = require('yup')

const validateSchema = Yup.object().shape({
  name: Yup.string().required(),
  jobTitle: Yup.string(),
  mail: Yup.string().email().required(),
  phone: Yup.string().required(),
  address: Yup.string().nullable().required('Påkrevd'),
  postCode: Yup.string().required('Påkrevd')
})

const UserInfo = () => {
  const { userData, updateCurrentUser } = useUserContext()
  const saveUser = useMutation(
    'saveUser',
    (driverDetails) => updateDriverDetails(userData.chosenCompany.id, userData.user.id, driverDetails),
    {
      onSuccess: () => updateCurrentUser(),
      onError: () => {
        customToast.error(i18nInstance.translate('ERROR_USERPROFILE'))
        customToast.clearQueue()
      }
    }
  )

  if (!userData) {
    return <Loading />
  }

  const contactDetails = userData && userData.user.contactDetails

  return (
    <>
      {contactDetails && (
        <Formik
          initialValues={contactDetails}
          validationSchema={validateSchema}
          onSubmit={(details) => saveUser.mutate(details)}
          render={({ handleSubmit, isValid, values, touched, errors, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid>
                  <StyledLayoutItem>
                    <Field
                      name={`name`}
                      render={({ field }) => (
                        <StyledInput
                          id={`name`}
                          {...field}
                          placeholder={'Navn Navnesen'}
                          label={<TranslatedText i18nKey="NAME" />}
                        />
                      )}
                    />
                    {touched.name && errors.name && (
                      <Alert variant="error" type="warning">
                        {errors.name}
                      </Alert>
                    )}
                  </StyledLayoutItem>
                  <StyledLayoutItem>
                    <Field
                      name={`jobTitle`}
                      render={({ field }) => (
                        <StyledInput
                          id={`jobTitle`}
                          {...field}
                          placeholder={'Stilling'}
                          label={<TranslatedText i18nKey="WORK_ROLE" />}
                        />
                      )}
                    />
                  </StyledLayoutItem>
                  <StyledLayoutItem>
                    <Field
                      name={`mail`}
                      render={({ field }) => (
                        <StyledInput
                          id={`mail`}
                          {...field}
                          placeholder={'Email'}
                          label={<TranslatedText i18nKey="EMAIL" />}
                        />
                      )}
                    />
                    {touched.mail && errors.mail && (
                      <Alert variant="error" type="warning">
                        {errors.mail}
                      </Alert>
                    )}
                    {values.mail !== contactDetails.mail && [
                      <StyledWarning />,
                      <TranslatedText i18nKey={'CHANGED_EMAIL'}>{(value) => <span>{value}</span>}</TranslatedText>
                    ]}
                  </StyledLayoutItem>
                  <StyledLayoutItem>
                    <Field
                      name={`phone`}
                      render={({ field }) => (
                        <StyledInput
                          mode={'phone'}
                          id={'phone'}
                          label={<TranslatedText i18nKey="PHONE" />}
                          placeholder={'411 11 111'}
                          locale={'nb'}
                          {...field}
                          onChange={(event) => {
                            setFieldValue('phone', event.rawValue)
                          }}
                        />
                      )}
                    />
                    {touched.phone && errors.phone && (
                      <Alert variant="error" type="warning">
                        {errors.phone}
                      </Alert>
                    )}
                    {values.phone.replace(/\s/g, '') !== contactDetails.phone.replace(/\s/g, '') && [
                      <StyledWarning />,
                      <TranslatedText i18nKey={'CHANGED_PHONE'}>{(value) => <span>{value}</span>}</TranslatedText>
                    ]}
                  </StyledLayoutItem>
                  <StyledLayoutItem>
                    <Field
                      name={`address`}
                      render={({ field }) => (
                        <StyledInput
                          id={'home'}
                          autocomplete="shipping street-address"
                          label={<TranslatedText i18nKey="HOME_ADDRESS" />}
                          placeholder={'Gatenavn 5'}
                          {...field}
                        />
                      )}
                    />
                    {touched.address && errors.address && (
                      <Alert variant="error" type="warning">
                        {errors.address}
                      </Alert>
                    )}
                  </StyledLayoutItem>

                  <StyledLayoutItem>
                    <Field
                      name={`postCode`}
                      render={({ field }) => {
                        const { onChange, ...rest } = field
                        return (
                          <StyledInput
                            mode={'postal'}
                            {...rest}
                            onChange={(e) => setFieldValue('postCode', e.rawValue)}
                            locale={'nb'}
                            label={<TranslatedText i18nKey="POSTAL_NUMBER" />}
                            placeholder={'0001'}
                            id="dnwqu"
                          />
                        )
                      }}
                    />
                    {touched.postCode && errors.postCode && (
                      <Alert variant="error" type="warning">
                        {errors.postCode}
                      </Alert>
                    )}
                  </StyledLayoutItem>

                  {saveUser.isLoading ? (
                    <Loading />
                  ) : (
                    <ButtonContainer>
                      <Button disabled={!isValid || !touched} type={'submit'} onClick={() => null}>
                        <TranslatedText i18nKey={'SAVE'} />
                      </Button>
                    </ButtonContainer>
                  )}
                </Grid>
              </form>
            )
          }}
        />
      )}
      <Toast limit={1} />
    </>
  )
}

export default UserInfo

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--spacing-mediumPlus);
  grid-row-gap: var(--spacing-mediumPlus);
  margin-bottom: var(--spacing-largeMinus);
`

const StyledInput = styled(Input)`
  font-size: var(--font-base);
  input::placeholder {
    color: gray;
  }
`

const StyledLayoutItem = styled(LayoutItem)`
  font-size: var(--font-text);
  color: var(--color-headerText);
`

const ButtonContainer = styled.div`
  width: 100%;
`

export const StyledWarning = styled(Warning)`
  vertical-align: middle;
`
