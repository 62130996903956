import React from 'react'
import { TranslatedText } from '@staccx/i18n'
import { formatCurrency, formatNumber } from '../../../../../utils/tableFuncs'
import { createColumnHelper } from '@tanstack/react-table'
import { TollReportDetails } from '../../../../../types'
import { NavLink } from 'react-router-dom'

type DataProps = {
  options: { year: string; month: string }
}
export default function tollReportDetailsData({ options }: DataProps) {
  const columnHelper = createColumnHelper<TollReportDetails>()
  return [
    columnHelper.accessor('regNo', {
      id: 'regNo',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'REGISTRATION_NUMBER'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row, getValue }) => (
        <NavLink
          to={`/reports/toll/transaction/${row.original.accountNo}/${options.year}/${options.month}`}
          style={{
            paddingLeft: `${row.depth * 2}rem`,
          }}
        >
          {getValue()}
        </NavLink>
      ),
    }),
    columnHelper.accessor('vehicleID', {
      id: 'vehicleID',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'VEHICLE_ID'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('invoiceGroupingDescription', {
      id: 'invoiceGroupingDescription',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'INVOICE_GROUP'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('carDescription', {
      id: 'carDescription',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'OBJECT'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('driverName', {
      id: 'driverName',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'DRIVER'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('amountIncVAT', {
      id: 'amountIncVAT',
      meta: { alignmentType: 'number' },
      header: () => <TranslatedText i18nKey={'CURRENCY_VALUE'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
    }),
    columnHelper.accessor('antallPasseringer', {
      id: 'antallPasseringer',
      meta: { alignmentType: 'number' },
      header: () => <TranslatedText i18nKey={'PASSINGS'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue(), { precision: 0 }) || '',
    }),
  ]
}
