import { CheckBox, RadioButton, theming } from '@staccx/bento'
import styled from 'styled-components'
import { SmallHeading } from '../../../carConfiguration/components/Headings'

export const CheckBoxOption = ({ label, options, onChange, errors }) => (
  <CheckBoxContainer errors={errors}>
    <div>
      <SmallHeading>{label}</SmallHeading>
      {options.map((option) => (
        <div key={option.typeCode}>
          <CheckBox id={option.typeCode} onChange={() => onChange(option)}>
            {option.customerAlternative}
          </CheckBox>
        </div>
      ))}
    </div>
    {errors && <Error>{errors}</Error>}
  </CheckBoxContainer>
)

const Error = styled.p`
  color: var(--color-warning);
`

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--spacing-small);
  margin-bottom: ${theming.spacing.large()};

  li:not(:first-child) {
    border-top: var(--spacing-microMinus) solid var(--color-line);
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--color-bg);
    border: 1px solid var(--color-line);

    > div {
      cursor: pointer;
    }

    > div > div {
      padding-left: ${theming.spacing('smallPlus')};
      padding-right: ${theming.spacing('smallPlus')};
    }

    > div > div:not(:first-child) {
      border-top: var(--spacing-microMinus) solid var(--color-line);
    }

    > span {
      background-color: var(--color-primaryLight);
      line-height: 2.5em;
      padding-left: var(--spacing-smallPlus);
    }
  }
`
