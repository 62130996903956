import React from "react"

const Bensin = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17 7h4v7a3 3 0 0 1-4 2.82V20H5V6c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v1zm2 7V9h-2v5a1 1 0 0 0 2 0zm-9.8 4h3.32c.93 0 1.62-.8 1.62-1.8 0-1.07-.82-1.47-1.41-1.47v-.05c.64-.03 1.17-.39 1.17-1.35 0-.97-.62-1.61-1.66-1.61H9.2V18zM7 6v4h8V6H7zm3.56 9.4h1.57c.36 0 .59.21.59.57v.31c0 .36-.23.57-.59.57h-1.57V15.4zm0-2.53h1.36c.36 0 .57.22.57.58v.27c0 .36-.21.57-.57.57h-1.36v-1.42z"
    />
  </svg>
)

export default Bensin
