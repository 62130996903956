import React from 'react'
import styled, { css } from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'
import { activeName } from './constants'
import { TranslatedText } from '@staccx/i18n'
import { CarSvg, HomeSvg } from '../../icons/NewMenuCollection'

export function NewMenuItem({key = 1, collapsed, renderSubmenuForPath, path, title, submenu, icon, onClick, islogo = 'false', count, ...props }) {
     const { pathname } = useLocation()
  let processedSubMenu = submenu?.map((sub) => ({
    ...sub,
    active: (sub.path === pathname).toString()
  }))
  const isSubMenuRoute = submenu?.some((sub) => sub.path === pathname)
  const isCurrentRoute = (pathname?.substring(1) === path || pathname === path || isSubMenuRoute || false)?.toString()
   
   return <MenuItem key={key}>
    <MenuElement to={path}
    collapsed={collapsed}
        style={({ isActive }) => (isActive ? activeName : undefined)}
        onClick={onClick}
        active={isCurrentRoute}>
          {icon}{ collapsed ? null : title}</MenuElement></MenuItem>
}

const MenuItem = styled.div`
display: flex
width: fit-content;
align-items: center;
justify-content: center;
color: #A3A2A2;
min-height: 24px;

`
const MenuElement = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: ${props => props.collapsed ? 'center' : 'start'};
    gap: 1rem;
    color: #A3A2A2;
    border-radius: 10px;
    text-decoration: none;
    height: fit-content;
    width: 100%;
    font-family: var(--font-test);
    font-size: var(--font-base);
    font-weight: 500;
    :hover {
       color: rgb(88,88,88);
    }
          ${(props) => props.active === "true" ? css` color: #000000` : css`background-color: transparent`}

`


