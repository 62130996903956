import React from 'react'
import styled from 'styled-components'
import { color, Flag, Heading, spacing, Button } from '@staccx/bento'
import Logo from './Logo'
import ReturnFuelIcon from '../../../icons/ReturnFuelIcon'
import { TranslatedText } from '@staccx/i18n'
import { Link } from 'react-router-dom'

const CarCard = ({ brand, car }) => {
  return (
    <>
      {car && brand && (
        <Grid>
          <Replacement>
            <Flag img={<StyledLogo brand={brand} width={100} height={100} />}>
              <strong>{car.registrationNumber}</strong>
              <Heading level="2">
                {car.make} {car.model}
              </Heading>
              <span>{car.engine}</span>
              <Fuel>
                <ReturnFuelIcon fuel={car.fuel} /> {car.fuel}
              </Fuel>
            </Flag>
          </Replacement>
        </Grid>
      )}
    </>
  )
}

export default CarCard

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`

const Replacement = styled.div`
  flex-direction: column;
  padding: var(--spacing-largeMinus);
`

const Fuel = styled.div`
  display: flex;
  color: ${color.wcag};
  align-items: center;
  svg {
    margin-right: ${spacing.tiny};
  }
`

const StyledLogo = styled(Logo)`
  width: var(--spacing-huge);
  height: var(--spacing-huge);
  @media only screen and (max-width: 450px) {
    width: var(--spacing-largePlus);
    height: var(--spacing-largePlus);
  }
`
