import React, { useState } from 'react'
import styled from 'styled-components'
import { Heading } from '@staccx/bento'
import { StyledButton } from '../../../theme/Theme.Button'
import { Label } from '../../../theme/Theme.Text'
import { StyledItemGroup } from '../../../theme/Theme.ItemGroup'
import { useCarContext } from '../../../context/CarProvider'
import DropDown from '../../../components/DropDownMenu'
import { TranslatedText } from '@staccx/i18n'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { sendAdditionalProductEmail } from '../../../api/flow'
import { useChosenCompany } from '../../../context/UserProvider'

const LostCard = ({ children, onOrder, onFaultOrder }) => {
  const [open, openSet] = useState(false)
  const [chosenCar, chosenCarSet] = useState('')
  const [cardType, cardTypeSet] = useState('')
  const company = useChosenCompany()

  const { allCars } = useCarContext()

  const onOrderNewCard = async () => {
    try {
      const payload = {
        regNr: chosenCar?.registrationNumber,
        company: company?.name,
        productType: cardType?.Description,
      }
      await sendAdditionalProductEmail(payload)
      onOrder()
      openSet(false)
    } catch (e) {
      onFaultOrder()
      openSet(false)
    }
  }
  return (
    <DialogPrimitive.Root open={open} onOpenChange={openSet}>
      <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>
      <Overlay />
      <DialogContent onPointerDownOutside={(event) => event.preventDefault()}>
        <Grid>
          <Heading level="2">{<TranslatedText i18nKey="LOST_CARD" />}</Heading>
          <DropdownWrapper>
            <TranslatedText i18nKey={'REG_NO'}>{(value) => <Label>{value}</Label>}</TranslatedText>
            <DropDown
              placeholder="XX NNNNN"
              options={allCars}
              radius={`var(--borderRadius)`}
              label={<TranslatedText i18nKey="REG_NO" />}
              getOptionValue={(option) => `${option['registrationNumber']}`}
              getOptionLabel={(option) => `${option['registrationNumber']}`}
              onChange={(car) => {
                chosenCarSet(car)
                cardTypeSet('')
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper>
            <TranslatedText i18nKey={'TYPE'}>{(value) => <Label>{value}</Label>}</TranslatedText>
            <DropDown
              options={chosenCar?.additionalProducts}
              placeholder={<TranslatedText i18nKey="CHOOSE" />}
              value={cardType || ''}
              radius={`var(--borderRadius)`}
              getOptionValue={(option) => `${option['AgreementCode']}`}
              getOptionLabel={(option) => `${option['Description']}`}
              onChange={(type) => cardTypeSet(type)}
            />
          </DropdownWrapper>
          <StyledItemGroup>
            <StyledButton onClick={() => onOrderNewCard()} disabled={chosenCar === '' || cardType === ''}>
              <TranslatedText i18nKey="ORDER" />
            </StyledButton>
            <DialogPrimitive.DialogClose asChild>
              <CancelButton
                onClick={() => {
                  cardTypeSet('')
                  chosenCarSet('')
                }}
              >
                <TranslatedText i18nKey="CLOSE" />
              </CancelButton>
            </DialogPrimitive.DialogClose>
          </StyledItemGroup>
        </Grid>
      </DialogContent>
    </DialogPrimitive.Root>
  )
}

export default LostCard

const CancelButton = styled.button`
  border: 1px solid transparent;
  background: none;
  cursor: pointer;
  color: #ED003D;
`
const DropdownWrapper = styled.div`
display: flex;
flex-direction: column;
gap: var(--spacing-small);

`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: var(--spacing-medium);
`
const DialogContent = styled(DialogPrimitive.Content)`
  max-width: 40vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  background-color: var(--color-white);
  border: var(--spacing-microMinus) solid var(--color-line);
  border-radius: var(--spacing-small);
  padding: var(--spacing-mediumMinus);
    z-index: 999;
  box-shadow: 0 0 var(--spacing-small) rgba(0, 0, 0, 0.12);
`
const Overlay = styled(DialogPrimitive.Overlay)`
  background-color: rgb(248, 248, 248, 0.8);
  position: fixed;
  z-index: 998;
  inset: 0;
`
