import React from 'react'
import { Heading, Layout, Text } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import { useQuery } from 'react-query'
import { useChosenCompany } from '../../../context/UserProvider'
import { fetchPurhcaseExpenseReport } from '../../../api/api'
import { useParams } from 'react-router-dom'
import Container from '../../../components/Container'
import PurchaseReportAccountDetailsTable from '../components/PurchaseTables/PurchaseReportAccountDetails'

const PurchaseReportAccountDetails = () => {
  const { year, month, account } = useParams()
  const company = useChosenCompany()
  const companyId = company && company.id

  const { data = [], isLoading } = useQuery(
    ['purchaseExpenseReport'],
    () =>
      fetchPurhcaseExpenseReport({
        year,
        month,
        account,
      }),
    { enabled: !!companyId }
  )

  return (
    <Container>
      <Layout rowGap={'small'} paddingBottom={'medium'}>
        <TranslatedText i18nKey={'PURCHASEREPORT_EXPENSE_HEADING'}>
          {(value) => <Heading level="1">{value}</Heading>}
        </TranslatedText>
        <TranslatedText i18nKey="PERIOD">
          {(value) => (
            <Text level="4">
              {value} {year}-{month}
            </Text>
          )}
        </TranslatedText>
      </Layout>
      <PurchaseReportAccountDetailsTable isLoading={isLoading} data={data} />
    </Container>
  )
}

export default PurchaseReportAccountDetails
