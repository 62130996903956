import { Heading, Loading } from "@staccx/bento";
import { useCarContext } from "../../../context/CarProvider";
import { useBudgetSelections, usePendingSignings } from "../../../context/SigningProvider";
import { useUser } from "../../../context/UserProvider";
import { useNotifications } from "../../home/hooks/useNotifications";
import Cars from "./Cars";
import styled, {css} from 'styled-components'
import { Navigate, useNavigate } from "react-router-dom";

export function CarsNavigator({  }) {
    const user = useUser()

  const { updateCars, allCars } = useCarContext()
  const { pendingSignings } = usePendingSignings(user?.user.id)
  const { budgetSelections } = useBudgetSelections(user?.user.id)
  const notifications = useNotifications(pendingSignings, budgetSelections, allCars)
    const nav = useNavigate()
  const carContext = useCarContext()
  const car = carContext?.currentUsersCar

    return <Cars/>


if(user?.user?.roleType === 1 && car) {
     return <Navigate to={`/cars/${car?.registrationNumber}`} replace />

}

/* if(user?.user?.roleType === 1 && notifications?.length > 0) {
    return <Container>
        <Heading>My Cars</Heading>
        {notifications.map((notification, index) => {
            return <a key={index}  href={notification?.path}><ButtonCard image="orderCar.png" text={notification?.title}/></a>
        })}
    </Container>
} */


return <Center><Loading/></Center>
}

const Center = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 100%;
height: 100%;
`

const Container = styled.div`
display: flex;
flex-direction: column;
padding: 4rem;
gap: 2rem;
`

const ButtonCard = styled.button`
  padding: 6rem 10rem;
  border: none;
  display: flex;
  align-items: end;
  justify-content: space-between;
  position: relative;
  border-radius: 12px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease-in-out;
  :hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    :before {
      transition: 0.3s ease-in-out;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.6) 100%);
    }
  }
  ${(p) => css`
    background-image: url('/${p.image}');
    background-size: cover;
  `}
  :before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.4) 100%);
    border-radius: 12px;
  }

  :after {
    ${(p) =>
      css`
        content: '${p.text}';
      `}
    position: absolute;
    bottom: 10px;
    left: 5%;
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
  }
`