import React from 'react'
import eventFilter from '../../../constants/eventFilter'
import { Button, ItemGroup, Layout } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import Excel from '../../../icons/Excel'
import { postToDownload } from '../../../api/api'
import styled from 'styled-components'
import ExcelButton from '../../reports/components/ExcelButton'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'

const mapExcelExport = (filter, params) => {
  if (filter) {
    switch (filter.toUpperCase()) {
      case 'TOLLPASSING':
        if (!params.accountNo) return null
        const from = new Date()
        from.setFullYear(from.getFullYear() - 1)
        const to = new Date()

        const fromString = `${from.getFullYear()}-${from.getMonth()}`
        const toString = `${to.getFullYear()}-${to.getMonth()}`
        return {
          exportUrl: `export/report/toll/transaction/${params.accountNo}/period/${fromString}/${toString}`,
          filename: `Tollreport-account-${params.accountNo}-${fromString}-${toString}.xlsx`
        }
      default:
        return null
    }
  } else {
    return {
      exportUrl: `export/report/fleet/carpark?companyId=${params.companyId}`,
      filename: `Carpark-report-company-${params.companyId}.xlsx`
    }
  }
}

const overviewFilter = [
  {
    name: 'CARS_HEADING',
    active: true,
    value: null
  },
  {
    name: 'OVERVIEW',
    active: false,
    value: eventFilter.all
  },
  {
    name: 'DEVIATION',
    active: false,
    value: eventFilter.deviations
  },
  {
    name: 'ADDITIONAL_PRODUCTS',
    active: false,
    value: eventFilter.additionalProducts
  }
]

const transactionsFilter = [
  {
    name: 'TOLLPASSING',
    active: false,
    value: eventFilter.tollPassing
  },
  {
    name: 'REFUELING',
    active: false,
    value: eventFilter.refuel
  },
  {
    name: 'PURCHASE',
    active: false,
    value: eventFilter.purchase
  }
]

const EventFilter = ({ currentFilter, setFilter, accountNo = '', showCarFilter = true, companyId = null }) => {
  const excelExport = mapExcelExport(currentFilter, { accountNo, companyId })
  const filteredOverviewFilter = showCarFilter ? overviewFilter.slice(0, -1) : overviewFilter.slice(1)

  return (
    <EventLayout>
      <ButtonGroup>
     
        <ItemGroup style={{ overflow: 'visible' }}>
          <Wrap>
            {filteredOverviewFilter.map((filterElement) => (
              <StyledButton
                variant="filter"
                active={filterElement.value === currentFilter}
                key={filterElement.name}
                onClick={() => {
                  setFilter(filterElement.value)
                }}
                style={filterElement.value === currentFilter ? activeTabStyle : {}}
              >
                <TranslatedText i18nKey={filterElement.name} />
              </StyledButton>
            ))}
            <TransactionWrapper>
              {transactionsFilter.map((transactionFilterElement) => (
                <TransactionButtons
                  variant="filter"
                  active={transactionFilterElement.value === currentFilter}
                  key={transactionFilterElement.name}
                  onClick={() => {
                    setFilter(transactionFilterElement.value)
                  }}
                  style={transactionFilterElement.value === currentFilter ? activeTabStyle : {}}
                >
                  <TranslatedText i18nKey={transactionFilterElement.name} />
                </TransactionButtons>
              ))}

              <TransactionText>
                <TransactionWrap>
                  <TransactionElem>
                    <span>Transactions last 3 months</span>
                  </TransactionElem>
                </TransactionWrap>
              </TransactionText>
            </TransactionWrapper>
         
          </Wrap>
        </ItemGroup>
      </ButtonGroup>
    </EventLayout>
  )
}

const activeTabStyle = {
  border: '0px',
  position: 'relative',
  backgroundColor: '#467EFF',
  color: 'var(--color-white)'
}

const TransactionWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`

const TransactionElem = styled.div`
  padding: 0px 1px;
`

const EventLayout = styled(Layout)`
  width: 100%;
  margin: 0;
`

const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-tiny);
  justify-content: space-between;
`
const StyledButton = styled(Button)`
  border-radius: var(--spacing-mediumMinus);
  border: 0;
  padding: 0 var(--spacing-mediumMinus);
  font-weight: var(--fontWeight-bold);
  background-color: var(--color-white);
  color: var(--color-primary);
  height: var(--spacing-mediumPlus);
  font-size: var(--font-text);
  margin-bottom: var(--spacing-tiny);
  &:hover {
    border: 0;
    background-color: var(--color-primary);
    color: var(--color-white);
  }
`

const TransactionButtons = styled(Button)`
  border-radius: var(--spacing-medium);
  border: 0;
  font-weight: var(--fontWeight-bold);
  padding: 0 var(--spacing-mediumMinus);
  background-color: var(--color-white);
  color: var(--color-primary);
  height: var(--spacing-mediumPlus);
  font-size: var(--font-text);
  &:hover {
    border: 0;
    background-color: var(--color-primary);
    color: var(--color-white);
  }
`

{
  /* const ExcelButton = styled(Button)`
  border-radius: var(--spacing-tinyMinus) var(--spacing-tinyMinus) 0 0;
  border-width: var(--spacing-microMinus);
  border-bottom: 0;
  padding: var(--spacing-smallPlus);
  margin-bottom: 0;
  font-weight: var(--fontWeight-bold);
  background-color: var(--color-white);
  color: var(--color-primary);
  border-color: var(--color-primary);
  height: var(--spacing-largeMinus);
  align-self: flex-end;
  span {
    font-size: var(--font-text);
  }
`
 */
}
const TransactionWrapper = styled.div`
  background-color: var(--color-primaryLight);
  border-radius: var(--spacing-medium);
  justify-content: center;
  align-items: center;
  display: flex;
  padding: var(--spacing-tiny);
  margin-bottom: var(--spacing-tiny);
  height: var(--spacing-large);
  gap: var(--spacing-smallMinus);
`

const TransactionText = styled.div`
  font-weight: var(--fontWeight-normal);
  color: var(--color-primary);
  font-size: var(--font-small);
  padding: 0 var(--spacing-tiny);
  text-transform: uppercase;
`

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  height: 100%;
`

/* const StyledTrigger = styled(Tabs.Trigger)`
  border: none;
  background-color: white;
  padding: 2rem;
  position: relative;

cursor: pointer;
  ${(props) =>
    props.isactive
      ? css`
          color: black;
          font-weight: 600;
           :after {
        left: 50%;
        bottom: 0;
        position: absolute;
        transform: translateX(-50%);
        width: 75%;
        width: calc(100% - 4rem);
        content: '';
        display: block;
        height: 4px;
        background-color: blue;
    }
        `
      : css`
          border-bottom: 4px solid transparent;
        `}
`

const StyledList = styled(Tabs.List)`
  display: flex;
  border-bottom: 2px solid lightgray;
`
const StyledRoot = styled(Tabs.Root)`
  background-color: transparent;
`

const StyledContent = styled(Tabs.Content)`` */

export default EventFilter
