import React from 'react'
import styled, { css } from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const CustomSkeleton = ({ skeletons = [], theme = {}, ...props }) => {
  return (
    <SkeletonWrapper theme={theme} cols={skeletons.length ? skeletons : [1]}>
      {skeletons.map((skelly, i) => (
        <Skeleton
          key={(JSON.stringify(skelly) + i).toString()}
          {...props}
          width={skelly.width || '100%'}
          height={skelly.height || '100%'}
          count={skelly.count || 3}
          circle={skelly.type === 'circle'}
        />
      ))}
    </SkeletonWrapper>
  )
}

export default CustomSkeleton

export const SkeletonWrapper = styled.div`
  justify-content: flex-start;
  gap: var(--spacing-small);
  background-color: var(--color-bg);
  border: 1px solid var(--color-line);
  width: 100%;
  line-height: 3.5;
  padding: var(--spacing-smallMinus);

  > span {
    display: initial;
    gap: var(--spacing-small);
  }

  display: grid;
  grid-template-columns: ${(props) => props.cols.map(c => `1fr`).join(' ')};

  ${(props) => props.theme};
`
