import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TranslatedText } from '@staccx/i18n'
import { Field, Formik } from 'formik'
import { Alert, Input, Loading } from '@staccx/bento'
import { Label } from '../../theme/Theme.Text'
import { fetchContactDetails, updateDriverDetails, createAndSetNewDriver, createDriverWithDetails } from '../../api/api'
import { getContactErrorMessage } from '../../utils/errorHandling'
import styled from 'styled-components'
import SwitchButton from './SwitchButton'
import { useFeatureToggles } from '../../context/UserProvider'
import { useChosenCompany } from '../../context/UserProvider'
import { useLocation } from 'react-router-dom'
import { useCar } from '../../context/CarProvider'
import { ButtonGroup } from '../../theme/Theme.ItemGroup'
import { CancelButton, StyledButton } from '../../theme/Theme.Button'

const Yup = require('yup')

const validateSchema = Yup.object().shape({
  name: Yup.string().required(),
  mail: Yup.string().email().required(),
  phone: Yup.string().required(),
  address: Yup.string().nullable().required('Påkrevd'),
  postCode: Yup.string().required('Påkrevd'),
  jobTitle: Yup.string()
})

const detailsMap = (details) => ({
  name: details.name || '',
  mail: details.mail || '',
  phone: details.phone || '',
  address: details.address || '',
  postCode: details.postCode || '',
  jobTitle: details.jobTitle || ''
})

const ContactForm = ({ close, companyId, driver, ...props }) => {
  const [contactDetails, contactDetailsSet] = useState({})
  const [isLoading, isLoadingSet] = useState(true)
  const featureToggles = useFeatureToggles()

  let location = useLocation()
  const [setAsDriverOnCurrentCar, setAsDriverOnCurrentCarSet] = useState(false)
  const company = useChosenCompany()
  const splitPath = location.pathname.split('/')
  const isOnCar = splitPath.map((i) => i.toLowerCase()).includes('cars') && splitPath.length > 2
  const carRegNo = isOnCar ? splitPath[2] : ''
  const car = useCar(carRegNo)

  const getContactDetails = () => {
    if (!driver) {
      isLoadingSet(false)
      contactDetailsSet({
        name: '',
        mail: '',
        phone: '',
        address: '',
        postCode: '',
        jobTitle: ''
      })
      return
    }
    fetchContactDetails(driver.id)
      .then((details) => {
        contactDetailsSet(detailsMap(details))
        isLoadingSet(false)
      })
      .catch(() => alert(`Could not find details for driver with id ${driver.id}`))
  }

  useEffect(() => getContactDetails(), [])

  const switchButtons = [
    { id: 'driver', label: 'DRIVER' },
    { id: 'manager', label: 'MANAGER' },
    { id: 'carConfig', label: 'CAR_CONFIG' }
  ]

  return !isLoading ? (
    <>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={contactDetails ? contactDetails : ''}
        validationSchema={validateSchema}
        onSubmit={(details, { setSubmitting, setTouched }) => {
          if (companyId && driver.id) {
            updateDriverDetails(companyId, driver.id, details)
              .then(() => {
                setSubmitting(false)
                setTouched(false)
                props.onFinish(details)
              })
              .catch((err) => {
                window.alert(getContactErrorMessage(err))
                setSubmitting(false)
              })
          }
          if (!driver && setAsDriverOnCurrentCar) {
            createAndSetNewDriver(company.id, car.accountNo, details)
              .then(() => {
                setSubmitting(false)
                props.onFinish(setAsDriverOnCurrentCar)
              })
              .catch((err) => {
                window.alert(getContactErrorMessage(err))
                setSubmitting(false)
              })
          } else if (!driver && !setAsDriverOnCurrentCar) {
            createDriverWithDetails(company.id, details)
              .then(() => {
                setSubmitting(false)
                props.onFinish(setAsDriverOnCurrentCar)
              })
              .catch((err) => {
                window.alert(getContactErrorMessage(err))
                setSubmitting(false)
              })
          }
        }}
        render={({ handleSubmit, isValid, isSubmitting, touched, errors, setFieldValue }) => {
          return (
            <>
              {driver ? (
                <Heading>
                  <TranslatedText i18nKey="CHANGE_EMP" />
                </Heading>
              ) : (
                <Heading>
                  <TranslatedText i18nKey="NEW_EMPLOYEE" />
                </Heading>
              )}
              <form onSubmit={handleSubmit}>
                <Grid>
                  <div>
                    <Item>
                      <Label>{<TranslatedText i18nKey="NAME" />}<Mandatory>*</Mandatory></Label>
                      <Field
                        name={`name`}
                        render={({ field }) => (
                          <TranslatedText i18nKey="PLACEHOLDER_NAME">
                            {(value) => <Input variant="filledInput" id={`name`} {...field} placeholder={value} />}
                          </TranslatedText>
                        )}
                      />
                      {touched.name && errors.name && (
                        <Alert variant="error" type="warning">
                          {errors.name}
                        </Alert>
                      )}
                    </Item>
                    <Item>
                      <Label>{<TranslatedText i18nKey="WORK_ROLE" />}</Label>
                      <Field
                        name={`jobTitle`}
                        render={({ field }) => (
                          <TranslatedText i18nKey="WORK_ROLE">
                            {(value) => (
                              <>
                                <Input variant="filledInput" id={`jobTitle`} {...field} placeholder={value} />
                              </>
                            )}
                          </TranslatedText>
                        )}
                      />
                    </Item>
                    <Item>
                      <Label>{<TranslatedText i18nKey="EMAIL" />}<Mandatory>*</Mandatory></Label>
                      <Field
                        name={`mail`}
                        render={({ field }) => (
                          <TranslatedText i18nKey="PLACEHOLDER_EMAIL">
                            {(value) => (
                              <>
                                <Input variant="filledInput" id={`mail`} {...field} placeholder={value} />
                              </>
                            )}
                          </TranslatedText>
                        )}
                      />
                      {touched.mail && errors.mail && (
                        <Alert variant="error" type="warning">
                          {errors.mail}
                        </Alert>
                      )}
                    </Item>
                    <Item>
                      <Label>{<TranslatedText i18nKey="PHONE" />}<Mandatory>*</Mandatory></Label>
                      <Field
                        name={`phone`}
                        render={({ field }) => (
                          <Input
                            variant="filledInput"
                            mode={'phone'}
                            id={'phone'}
                            placeholder={'+47 411 11 111'}
                            locale={'nb'}
                            {...field}
                            onChange={(event) => setFieldValue('phone', event.rawValue)}
                          />
                        )}
                      />
                      {touched.phone && errors.phone && (
                        <Alert variant="error" type="warning">
                          {errors.phone}
                        </Alert>
                      )}
                    </Item>
                    <Item>
                      <Label>{<TranslatedText i18nKey="HOME_ADDRESS" />}<Mandatory>*</Mandatory></Label>
                      <Field
                        name={`address`}
                        render={({ field }) => {
                          return (
                            <TranslatedText i18nKey="PLACEHOLDER_ADDRESS">
                              {(value) => (
                                <Input
                                  variant="filledInput"
                                  id={'home'}
                                  autocomplete="shipping street-address"
                                  placeholder={value}
                                  {...field}
                                />
                              )}
                            </TranslatedText>
                          )
                        }}
                      />
                      {touched.address && errors.address && (
                        <Alert variant="error" type="warning">
                          {errors.address}
                        </Alert>
                      )}
                    </Item>
                    <Item>
                      <Label>{<TranslatedText i18nKey="POSTAL_NUMBER" />}<Mandatory>*</Mandatory></Label>
                      <Field
                        name={`postCode`}
                        render={({ field }) => {
                          const { onChange, ...rest } = field
                          return (
                            <Input
                              mode="postal"
                              variant="filledInput"
                              {...rest}
                              onChange={(e) => setFieldValue('postCode', e.rawValue)}
                              locale={'nb'}
                              placeholder={'0001'}
                              id="dnwqu"
                            />
                          )
                        }}
                      />
                      {touched.postCode && errors.postCode && (
                        <Alert variant="error" type="warning">
                          {errors.postCode}
                        </Alert>
                      )}
                    </Item>
                    {isOnCar && (
                      <Item>
                        <Field
                          name={`setAsDriverOnCurrentCar`}
                          render={() => {
                            return (
                              <StyledLabel>
                                <CheckBox onChange={(e) => setAsDriverOnCurrentCarSet(e.target.checked)} id="das23s" />
                                <TranslatedText i18nKey={'SET_NEW_DRIVER_ON_CURRENT_CAR'}>
                                  {(value) => <span>{value}</span>}
                                </TranslatedText>
                              </StyledLabel>
                            )
                          }}
                        />
                      </Item>
                    )}
                    {isSubmitting ? (
                      <Loading />
                    ) : (
                      <ButtonGroup>
                        {driver && driver.id && (
                          <>
                            <StyledButton type={'submit'}  onClick={() => null}>
                              <TranslatedText i18nKey={'SAVE'} />
                            </StyledButton>
                            <DeleteButton onClick={() => props.onDiscontinue()}>
                              {<TranslatedText i18nKey="DELETE_EMP" />}
                            </DeleteButton>
                          </>
                        )}
                        {!driver && (
                          <>
                          <StyledButton type={'submit'}  onClick={() => null}>
                            <TranslatedText i18nKey={'SAVE'} />
                          </StyledButton>
                          <CancelButton type={'button'}  onClick={() => close(false)}>
                            Cancel
                          </CancelButton>
                          </>
                        )}
                      </ButtonGroup>
                    )}
                  </div>
                  {featureToggles.tilgang && (
                    <div>
                      <Access>{<TranslatedText i18nKey="ACCESS" />}</Access>
                      {switchButtons.map((btn) => (
                        <div>
                          <SwitchButton
                            id={btn.id}
                            label={<TranslatedText i18nKey={btn.label} />}
                            onCheckedChange={(checked) => {
                              setFieldValue(btn.id, checked)
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </Grid>
              </form>
            </>
          )
        }}
      />
    </>
  ) : (
    <Loading />
  )
}

ContactForm.propTypes = {
  onFinish: PropTypes.func.isRequired
}

export default ContactForm

const Mandatory = styled.span` 
  color: #BF0031;
`

const Form = styled.div`
border-radius: 8px;
box-shadow: 0 2px 4px var(--color-line);
`


const Heading = styled.p`
  font-weight: var(--fontWeight-bold);
  font-size: var(--font-h2);
  margin-bottom: var(--spacing-medium);
`

const DeleteButton = styled.button`
  border: 1px solid transparent;
  background: none;
  cursor: pointer;
  color: #ED003D;
`
const Item = styled.div`
  margin-bottom: var(--spacing-medium);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-smallPlus);
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  gap: var(--spacing-largePlus);
`

const Access = styled(Label)`
  margin-bottom: var(--spacing-smallMinus);
`
