import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Menu from './menu/Menu'
import Footer from './menu/Footer'
import ObliqueBox from '../pages/help/components/ObliqueBox'
import { NewMenu } from './menu/NewMenu'

const PageLayout = ({ children }) => {
  const { pathname } = useLocation()

  const localStorageCollapse = localStorage.getItem('collapse')
  const [collapse, setCollapse] = useState(localStorageCollapse === 'true' ? true : false)
  const setLocalStorage = (collapse) => {
    localStorage.setItem('collapse', collapse)
  }
  function handleCollapse() {
    setCollapse(!collapse)
    setLocalStorage(!collapse)
  }
  return (
    <InnerLayout collapse={!collapse}>
      <div>
      <NewMenu handleCollapse={handleCollapse} collapse={!collapse}/>
      </div>
      <ContentWrapper>
        <Content>{children}  <Footer /></Content>
      
      </ContentWrapper>
    </InnerLayout>
  )
}

export default PageLayout

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
`
const InnerLayout = styled.div`
  display: grid;
  grid-template-columns: ${p => (p.collapse ? '75px' : '300px')} auto;
  background-color: var(--color-bgGray);
  max-height: 100vh;
  width: 100%;
  overflow: none;
  position: absolute;
`


const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`
