import React from 'react'
import type { Purchase } from '../../../../../types'
import { createColumnHelper } from '@tanstack/react-table'
import { TranslatedText } from '@staccx/i18n'
import { getConsolidateInvoiceDescription } from '../../../../../utils/getConsolidateInvoiceDescription'
import { formatCurrency } from '../../../../../utils/tableFuncs'

export default function purchaseReportTableData(chosenCompany?: string) {
  const columnHelper = createColumnHelper<Purchase>()
  return [
    columnHelper.accessor(undefined, {
      id: 'period',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'PERIOD'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => 'Last 3 years' || ''
    }),
    columnHelper.accessor('consolidatedInvoiceAgreement', {
      id: 'consolidatedInvoiceAgreement',
      meta: { alignmentType: 'string' },
      header: () => (
        <TranslatedText i18nKey={'CONSOLIDATED_AGREEMENT'}>{(value) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => row.getValue() || ''
    }),
    columnHelper.accessor('amountIncVAT', {
      id: 'amountIncVAT',
      header: () => <TranslatedText i18nKey={'CURRENCY_VALUE'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
      footer: (props) => props.column.id,
      meta: { alignmentType: 'number' }
    })
  ]
}
