import React, { Component } from "react"
import PropTypes from "prop-types"
import { curveCatmullRom, easeElasticOut, line, max, min, scaleLinear, select } from "d3"
import { Layout } from "@staccx/bento"
import last from "../../../../utils/last"

class CurvedLineGraph extends Component {
  constructor(props, context) {
    super(props, context)
    this.reRender = this.reRender.bind(this)
  }

  componentDidMount() {
    this.reRender()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.reRender()
    }
  }

  reRender() {
    clearTimeout(this.timeoutId)

    this.timeoutId = setTimeout(() => {
      const {
        height,
        pointRadius,
        pointStrokeWidth,
        lineStrokeWidth,
        data,
        dataMaxCount
      } = this.props

      if (!data || !data.length) {
        return
      }
      const padding = pointRadius + pointStrokeWidth

      const last5 = data.slice(
        data.length > dataMaxCount
          ? data.length - dataMaxCount
          : Math.min(data.length - dataMaxCount, dataMaxCount)
      )

      const width = this.node.width.baseVal.value
      const minValue = min(last5)
      const maxValue = max(last5)
      const nPoints = [last(last5) * 0.9, ...last5]

      const circleXPosition = scaleLinear()
        .domain([0, nPoints.length - 1])
        .range([padding, width - padding])

      const yPosition = scaleLinear()
        .domain([minValue, maxValue])
        .range([height - padding, padding])

      const lineGenerator = line()
        .x((d, index) => circleXPosition(index))
        .y(yPosition)
        .curve(curveCatmullRom)

      const pathData = lineGenerator(nPoints)

      select(`#${this.props.name}-line`)
        .style("fill", "none")
        .style("stroke", "rgba(42, 109, 244, 0.3)")
        .style("stroke-width", lineStrokeWidth)
        .transition()
        .duration(550)
        .ease(easeElasticOut)
        .delay(200)
        .attr("d", pathData)

      select(`#${this.props.name}`)
        .selectAll("circle")
        .data(last5)
        .enter()
        .append("circle")
        .attr("r", 0)
        .attr("cx", (d, index) => circleXPosition(index + 1))
        .attr("cy", yPosition)
        .style("fill", "white")
        .style("stroke", "rgb(42, 109, 244)")
        .style("stroke-width", pointStrokeWidth)
        .transition()
        .duration(550)
        .ease(easeElasticOut)
        .delay(200)
        .attr("r", pointRadius)

      select(`#${this.props.name}`)
        .selectAll("circle")
        .data(last5)
        .exit()
        .remove()
    })
  }

  render() {
    return (
      <Layout>
        <svg
          id={this.props.name}
          width="100%"
          height={this.props.height}
          ref={node => (this.node = node)}
        >
          <path
            id={`${this.props.name}-line`}
            style={{
              fill: "none",
              stroke: "rgba(42, 109, 244, 0.3)",
              strokeWidth: 4
            }}
          />
        </svg>
      </Layout>
    )
  }
}

CurvedLineGraph.propTypes = {
  data: PropTypes.any,
  dataMaxCount: PropTypes.number,
  height: PropTypes.string,
  lineStrokeWidth: PropTypes.number,
  name: PropTypes.string.isRequired,
  pointRadius: PropTypes.number,
  pointStrokeWidth: PropTypes.number
}

export default CurvedLineGraph

CurvedLineGraph.defaultProps = {
  dataMaxCount: 5,
  height: "100",
  lineStrokeWidth: 2,
  pointRadius: 6,
  pointStrokeWidth: 3
}
