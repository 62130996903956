import React from 'react'
import { Heading } from '@staccx/bento'
import { TranslatedText } from '@staccx/i18n'
import styled from 'styled-components'

const MileageReportDetailsCard = ({ data, account }) => {
  return (
    <>
      <Container>
        <ContentContainer>
          <RowContainer>
            <Heading level={'4'}>
              {data[0]?.VehicleBrand} {data[0]?.VehicleModel} {data[0]?.RegistrationNo}
            </Heading>
          </RowContainer>

          <RowContainer carInfoGap>
            <ContentContainer carInfo>
              <TranslatedText i18nKey="ACCOUNT">
                {(value) => (
                  <Row>
                    <StyledP>{value}</StyledP>
                    <StyledP>{account}</StyledP>
                  </Row>
                )}
              </TranslatedText>

              <TranslatedText i18nKey="DRIVER">
                {(value) => (
                  <Row bottom>
                    <StyledP>{value}</StyledP>
                    <StyledP>{data[0]?.DriverName}</StyledP>
                  </Row>
                )}
              </TranslatedText>
            </ContentContainer>

            <ContentContainer carInfo>
              <TranslatedText i18nKey="CUSTOMER_NO">
                {(value) => (
                  <Row>
                    <StyledP>{value}</StyledP>
                    <StyledP>{data[0]?.CustomerNo}</StyledP>
                  </Row>
                )}
              </TranslatedText>

              <TranslatedText i18nKey="CONSOLIDATED_INVOICE_NO_AND_DESC">
                {(value) => (
                  <Row bottom>
                    <StyledP>{value}</StyledP>
                    <StyledP>
                      {data[0]?.ConsolidatedInvoiceDesc} {data[0]?.ConsolidatedInvoiceNo}
                    </StyledP>
                  </Row>
                )}
              </TranslatedText>
            </ContentContainer>
          </RowContainer>
        </ContentContainer>
      </Container>
    </>
  )
}


export default MileageReportDetailsCard

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.carInfo ? '50%' : '')};
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => (props.carInfoGap ? 'var(--spacing-huge)' : 'var(spacing--tiny)')};
`

const Container = styled.div`
  background-color: var(--color-white);
  border: var(--spacing-microMinus) solid var(--color-grayLine);
  box-shadow: 0px var(--spacing-tinyMinus) var(--spacing-small) rgba(0, 0, 0, 0.04);
  padding: var(--spacing-large);
`

const StyledP = styled.p`
  font-weight: var(--fontWeight-normal);
  padding: var(--spacing-smallMinus) 0px var(--spacing-smallMinus) var(--spacing-mediumMinus);
`

const Row = styled.div`
  border-bottom: ${(props) => (props.bottom ? '' : '1px solid var(--color-grayLine)')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
