import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { TranslatedText } from '@staccx/i18n'
import { formatCurrency } from '../../../../../utils/tableFuncs'
import { PurchaseReportDetails } from '../../../../../types'
import { NavLink } from 'react-router-dom'

type DataProps = {
  options: { year: string; month: string }
}
export default function purchaseReportDetailsData({ options }: DataProps) {
  const columnHelper = createColumnHelper<PurchaseReportDetails>()
  return [
    columnHelper.accessor('regNo', {
      id: 'regNo',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'PERIOD'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row, getValue }) => (
        <NavLink
          to={`/reports/purchase/expense/${row.original.accountNo}/${options.year}/${options.month}`}
          style={{
            paddingLeft: `${row.depth * 2}rem`,
          }}
        >
          {getValue()}
        </NavLink>
      ),
    }),
    columnHelper.accessor('vehicleID', {
      id: 'vehicleID',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'VEHICLE_ID'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('invoiceGroupingDescription', {
      id: 'invoiceGroupingDescription',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'INVOICE_GROUP'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('carDescription', {
      id: 'carDescription',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'OBJECT'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('driverName', {
      id: 'driverName',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'DRIVER'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
    }),
    columnHelper.accessor('amountincVAT', {
      id: 'amountincVAT',
      meta: { alignmentType: 'number' },
      header: () => <TranslatedText i18nKey={'CURRENCY_VALUE'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
    }),
  ]
}
