import React from 'react'
import { Heading, Layout } from '@staccx/bento'
import { RightAlignedRow } from '../../../theme/Theme.ItemGroup'
import { TranslatedText } from '@staccx/i18n'
import FleetReportTable from '../components/FleetTables/FleetReport'
import ReportTermSelect from '../components/ReportTermSelect'
import { ConsolidateInvoiceSelect } from '../../../components/menu/ConsolidateInvoiceSelect'
import { useFilterOnConsolidateAgreement } from '../hooks/useFilterOnConsolidateAgreement'
import { useQuery } from 'react-query'
import { fetchFleetReport, fetchFleetReportTerms } from '../../../api/api'
import { useUserContext } from '../../../context/UserProvider'
import ExcelButton from '../components/ExcelButton'
import Container from '../../../components/Container'

const FleetReport = () => {
  const { chosenCompany: company, fleetReportTerm } = useUserContext()
  const companyId = company && company.id
  const { data: terms = [] } = useQuery(['fleetReportTerms', companyId], () => fetchFleetReportTerms(companyId), {
    enabled: !!companyId
  })

  const { data = [], status } = useQuery(
    ['fleetReport', companyId, fleetReportTerm],
    () => fetchFleetReport(companyId, fleetReportTerm, 'fleet'),
    { enabled: !!companyId }
  )

  const mockFleetData = [
  {
    accountID: "ACCT123",
    regNo: "XYZ123",
    vehicleID: "V123",
    driverName: "Jeanine Sofie Larsen",
    HK: "ADM",
    totalTerms: 10,
    totalKm: 5000,
    budgetKm: 6000,
    maxKm: 7000,
    fixedYear: 10000,
    fixedContract: 20000,
    nonFixedYear: 15000,
    nonFixedContract: 25000,
    totalYear: 25000,
    totalContract: 45000
  },
  {
    accountID: "ACCT456",
    regNo: "ABC456",
    vehicleID: "V456",
    driverName: "Kasper Haug",
    HK: "ADM",
    totalTerms: 8,
    totalKm: 4000,
    budgetKm: 5000,
    maxKm: 6000,
    fixedYear: 12000,
    fixedContract: 22000,
    nonFixedYear: 18000,
    nonFixedContract: 28000,
    totalYear: 30000,
    totalContract: 50000
  },
  {
    accountID: "ACCT789",
    regNo: "DEF789",
    vehicleID: "V789",
    driverName: "Sigurd Sørensen",
    HK: "ADM",
    totalTerms: 12,
    totalKm: 6000,
    budgetKm: 7000,
    maxKm: 8000,
    fixedYear: 15000,
    fixedContract: 25000,
    nonFixedYear: 20000,
    nonFixedContract: 30000,
    totalYear: 35000,
    totalContract: 55000
  }
]

  return (
    <Container width="unlimited">
      <Layout rowGap={'small'}>
        <TranslatedText i18nKey={'FLEETREPORT_HEADING'}>
          {(value) => <Heading level="1">{value}</Heading>}
        </TranslatedText>
        <Layout variant="fiftyFifty">
          <TranslatedText i18nKey="TERM">
            {(value) => <ReportTermSelect label={value} reportTerms={terms} />}
          </TranslatedText>
          <ConsolidateInvoiceSelect />
        </Layout>
      </Layout>
      <RightAlignedRow>
        <ExcelButton />
      </RightAlignedRow>

      <FleetReportTable
        data={mockFleetData}
        isLoading={status === 'loading'}
      />
    </Container>
  )
}

export default FleetReport
