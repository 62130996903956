import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TranslatedText } from '@staccx/i18n'
import { Field, Formik } from 'formik'
import { Alert, Input, Loading } from '@staccx/bento'
import { Label } from '../../theme/Theme.Text'
import { fetchContactDetails, updateDriverDetails, createAndSetNewDriver, createDriverWithDetails } from '../../api/api'
import { getContactErrorMessage } from '../../utils/errorHandling'
import styled from 'styled-components'
import SwitchButton from './SwitchButton'
import { useFeatureToggles } from '../../context/UserProvider'
import { useChosenCompany } from '../../context/UserProvider'
import { useLocation } from 'react-router-dom'
import { useCar, useCarContext } from '../../context/CarProvider'
import { ButtonGroup } from '../../theme/Theme.ItemGroup'
import { CancelButton, StyledButton } from '../../theme/Theme.Button'
import DropDown from '../DropDownMenu'
import { set } from 'lodash'
import TextArea from '../fields/TextArea'

const Yup = require('yup')

const validateSchema = Yup.object().shape({
  name: Yup.string().required()
})

const detailsMap = (details) => ({
  name: details.name || '',
  mail: details.mail || '',
  phone: details.phone || '',
  address: details.address || '',
  postCode: details.postCode || '',
  jobTitle: details.jobTitle || ''
})

const ReportDamageForm = ({ close, companyId, driver, onFinish, ...props }) => {
  const [contactDetails, contactDetailsSet] = useState({})
  const [isLoading, isLoadingSet] = useState(true)
  const featureToggles = useFeatureToggles()
  const [insuranceCompany, setInsuranceCompany] = useState('')
  let location = useLocation()
  const [setAsDriverOnCurrentCar, setAsDriverOnCurrentCarSet] = useState(false)
  const company = useChosenCompany()
  const splitPath = location.pathname.split('/')
  const isOnCar = splitPath.map((i) => i.toLowerCase()).includes('cars') && splitPath.length > 2
  const carRegNo = isOnCar ? splitPath[2] : ''
  const car = useCar(carRegNo)

  const getContactDetails = () => {
    if (!driver) {
      isLoadingSet(false)
      contactDetailsSet({
        name: '',
        mail: '',
        phone: '',
        address: '',
        postCode: '',
        jobTitle: ''
      })
      return
    }
    fetchContactDetails(driver.id)
      .then((details) => {
        contactDetailsSet(detailsMap(details))
        isLoadingSet(false)
      })
      .catch(() => alert(`Could not find details for driver with id ${driver.id}`))
  }

  useEffect(() => getContactDetails(), [])

  const switchButtons = [
    { id: 'driver', label: 'DRIVER' },
    { id: 'manager', label: 'MANAGER' },
    { id: 'carConfig', label: 'CAR_CONFIG' }
  ]
  const [chosenCar, chosenCarSet] = useState('')
  const [cardType, cardTypeSet] = useState('')
  const [notes, notesSet] = useState('')
  const damageOptions = [
    { Value: '1', Label: 'Damage on windshield' },
    { Value: '2', Label: 'Damage on car' },
    { Value: '3', Label: 'Road assistance' },
    { Value: '4', Label: 'Damage abroad' },
    { Value: '5', Label: 'Theft' }
  ]

  const allCars = [
    { Value: '1', Label: 'Damage on windshield', registrationNumber: 'AB 12345' },
    { Value: '2', Label: 'Damage on car', registrationNumber: 'CD 67890' },
    { Value: '3', Label: 'Road assistance', registrationNumber: 'EF 12345' },
    { Value: '4', Label: 'Damage abroad', registrationNumber: 'GH 67890' },
    { Value: '5', Label: 'Theft', registrationNumber: 'IJ 12345'}
  ]

  const insuranceOptions = [
    { Value: '1', Label: 'IF Insurance' },
    { Value: '2', Label: 'Tryg' }
  ]

  return !isLoading && allCars ? (
    <>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        initialValues={contactDetails ? contactDetails : ''}
        validationSchema={validateSchema}
        onSubmit={(details, { setSubmitting, setTouched }) => {
          onFinish()
        }}
        render={({ handleSubmit, isValid, isSubmitting, touched, errors, setFieldValue }) => {
          return (
            <>
              <Heading>Report Damage</Heading>
              <form onSubmit={handleSubmit}>
                <Grid>
                  <DropdownWrapper>
                    <Item>
                      <Label>
                        {<TranslatedText i18nKey="NAME" />}
                        
                      </Label>
                      <Field
                        name={`name`}
                        render={({ field }) => (
                          <TranslatedText i18nKey="PLACEHOLDER_NAME">
                            {(value) => <Input variant="filledInput" id={`name`} {...field} placeholder={value} />}
                          </TranslatedText>
                        )}
                      />
                      {touched.name && errors.name && (
                        <Alert variant="error" type="warning">
                          {errors.name}
                        </Alert>
                      )}
                    </Item>
                    <Item>
                      <TranslatedText i18nKey={'REG_NO'}>{(value) => <Label>{value}</Label>}</TranslatedText>
                      <DropDown
                        placeholder="XX NNNNN"
                        options={allCars}
                        radius={`var(--borderRadius)`}
                        label={<TranslatedText i18nKey="REG_NO" />}
                        getOptionValue={(option) => `${option['registrationNumber']}`}
                        getOptionLabel={(option) => `${option['registrationNumber']}`}
                        onChange={(car) => {
                          chosenCarSet(car)
                        }}
                      />
                    </Item>
                    <Item>
                      <TranslatedText i18nKey={'TYPE'}>{(value) => <Label>{value}</Label>}</TranslatedText>
                      <DropDown
                        placeholder="Choose type of damage"
                        options={damageOptions}
                        value={cardType || ''}
                        radius={`var(--borderRadius)`}
                        getOptionValue={(option) => `${option['Value']}`}
                        getOptionLabel={(option) => `${option['Label']}`}
                        onChange={(type) => cardTypeSet(type)}
                      />
                    </Item>
                    <Item>
                      <TranslatedText i18nKey={'TYPE'}>{(value) => <Label>Insurance Company</Label>}</TranslatedText>
                      <DropDown
                        placeholder="Insurance"
                        options={insuranceOptions}
                        value={insuranceCompany || ''}
                        radius={`var(--borderRadius)`}
                        getOptionValue={(option) => `${option['Value']}`}
                        getOptionLabel={(option) => `${option['Label']}`}
                        onChange={(type) => setInsuranceCompany(type)}
                      />
                    </Item>
                    <Item>
                      <TranslatedText i18nKey={'ADDITIONAL_INFORMATION'}>
                        {(value) => <Label>Additional Information</Label>}
                      </TranslatedText>

                      <TextArea
                        placeholder="..."
                        onChange={(e) => {
                          if (e.target.value.length <= 500) {
                            notesSet(e.target.value)
                          }
                        }}
                        value={notes || ''}
                      >
                        {notes || ''}
                      </TextArea>
                    </Item>
                    {isSubmitting ? (
                      <Loading />
                    ) : (
                      <ButtonGroup>
                        {driver && driver.id && (
                          <>
                            <StyledButton type={'submit'} onClick={() => null}>
                              <TranslatedText i18nKey={'SAVE'} />
                            </StyledButton>
                            <DeleteButton onClick={() => props.onDiscontinue()}>
                              {<TranslatedText i18nKey="DELETE_EMP" />}
                            </DeleteButton>
                          </>
                        )}
                        {!driver && (
                          <>
                            <StyledButton type={'submit'} onClick={() => null}>
                              <TranslatedText i18nKey={'SAVE'} />
                            </StyledButton>
                            <CancelButton type={'button'} onClick={() => close(false)}>
                              Cancel
                            </CancelButton>
                          </>
                        )}
                      </ButtonGroup>
                    )}
                  </DropdownWrapper>
                  {featureToggles.tilgang && (
                    <div>
                      <Access>{<TranslatedText i18nKey="ACCESS" />}</Access>
                      {switchButtons.map((btn) => (
                        <div>
                          <SwitchButton
                            id={btn.id}
                            label={<TranslatedText i18nKey={btn.label} />}
                            onCheckedChange={(checked) => {
                              setFieldValue(btn.id, checked)
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </Grid>
              </form>
            </>
          )
        }}
      />
    </>
  ) : (
    <Center>
      <Loading />
    </Center>
  )
}

ReportDamageForm.propTypes = {
  onFinish: PropTypes.func.isRequired
}

export default ReportDamageForm

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Mandatory = styled.span`
  color: #bf0031;
`

const Form = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--color-line);
`

const Heading = styled.p`
  font-weight: var(--fontWeight-bold);
  font-size: var(--font-h2);
  margin-bottom: var(--spacing-medium);
`

const DeleteButton = styled.button`
  border: 1px solid transparent;
  background: none;
  cursor: pointer;
  color: #ed003d;
`
const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-smallPlus);
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  gap: var(--spacing-largePlus);
`

const Access = styled(Label)`
  margin-bottom: var(--spacing-smallMinus);
`
