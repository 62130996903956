import { Chart } from 'primereact/chart'

export function FuelChart({ car }) {
  return (
    <div className='p-4 gap-2 flex flex-col'>
      <span className='text-xl text-blue-500'>Fuel Volume</span>
      <Chart type="line" data={{
        labels: ['', '', '', '', '', '', ''],
        datasets: [
          {
            label: "",
            data: car.fuelVolume,
            fill: true,
            borderColor: '#2969ff'
            
          },
          
        ]
      }} 
       options={ {
            plugins: {
                legend: {
                    display: false
                }
            },
            fill: {
                backgroundColor: '#f2f2f2',
                borderColor: '#f2f2f2',
                borderSkipped: false
            },
            
        }}
      />
    </div>
  )
}
