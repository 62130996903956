import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, Loading, spacing, Text, useInterval } from '@staccx/bento'
import DropDownMenu from '../../../components/DropDownMenu'
import styled, { css } from 'styled-components'
import { i18nInstance, TranslatedText } from '@staccx/i18n'
import { formatCurrency } from '../../../utils/tableFuncs'
import { useChosenCompany, useChosenConsolidateInvoice, useUserContext } from '../../../context/UserProvider'
import { useCarContext } from '../../../context/CarProvider'
import { changeDriver, recordMileage, patchToggleCarReplacement } from '../../../api/api'
import { useMutation, useQueryClient } from 'react-query'
import { useContacts, useDrivers } from '../../../context/DriverProvider'
import formatDrivers from '../../../utils/driversDropdownFormat'
import CarCard from './CarCard'
import CarDetailsOverview from './CarDetailsOverview'
import customToast from '../../carConfiguration/components/toast/customToast'
import Toast from '../../carConfiguration/components/toast/Toast'
import { Link } from 'react-router-dom'
import { fetchContactDetails } from '../../../api/api'
import { Field } from 'formik'

const CarInfo = ({  car, drivers }) => {
/*   const user = useUserContext()
  const { refetch } = useCarContext()
  const { refetch: refetchDrivers } = useDrivers()
  const { refetch: refetchContacts } = useContacts()
  const company = useChosenCompany()
  const consolidateInvoice = useChosenConsolidateInvoice()
  const companyId = company ? company.id : null
  const queryClient = useQueryClient()

  const [mileage, mileageSet] = useState(car.mileage || '')
  const [drivers, driversSet] = useState([])
  const [driver, driverSet] = useState({ value: d.id, label: d.name })
  const cachedDriver = useRef({ value: d.id, label: d.name })

  const toggleReplacement = async () => {
    await patchToggleCarReplacement(car.accountNo)
    queryClient.invalidateQueries(['cars', companyId, consolidateInvoice?.ConsolidateCode])
  }

  useEffect(() => {
    driversSet(formatDrivers(unformattedDrivers).sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)))
  }, [])

  useEffect(() => {
    customToasts()
  }, [car?.registrationNumber])
 */



  const userConfigurationAccess = () => {
    if (user?.hasConfigurationAccess) {
      customToast.info(
        <>
          <TranslatedText i18nKey="NO_CAR_REPLACEMENT" />
          <ToastButtonContainer>
            <ToastLink to={`/car-order`}>
              <ToastButton>
                <TranslatedText i18nKey="YES" />
              </ToastButton>{' '}
            </ToastLink>
            <ToastButton onClick={() => toggleReplacement()}>
              <TranslatedText i18nKey="NO" />
            </ToastButton>
          </ToastButtonContainer>
        </>,
        { autoClose: false }
      )
    } else {
      customToast.info(
        <>
          <ToastLink to={`/help`}>
            <TranslatedText i18nKey="NO_CAR_REPLACEMENT_CONTACT_AUTOPLAN" />
          </ToastLink>
        </>,
        { autoClose: false }
      )
    }
  }

  const saveDriver = useMutation(
    'id',
    ({ accountNo, driverId }) => {
      return changeDriver(accountNo, driverId)
    },
    {
      onSuccess: async () => {
        try {
          await Promise.all([refetch(), refetchDrivers(), refetchContacts()])
        } catch (err) {
          console.debug('Could not refresh data after updating driver', err)
        }
        customToast.clearToastById('driver-error')
      },
      onError: () => {
        driverSet(cachedDriver.current)
        customToast.error(<TranslatedText i18nKey="ERROR_DRIVER_404" />, { toastId: 'driver-error' })
        customToast.clearQueue()
      },
    }
  )

  const checkDriver = (driver) => {
    fetchContactDetails(driver.value)
      .then((details) => {
        if (details.phone && details.mail) {
          saveDriver.mutate({ accountNo: car.accountNo, driverId: details.personContactID })
        } else {
          driverSet(cachedDriver.current)
          if (!details.phone && !details.mail) {
            customToast.error(<TranslatedText i18nKey="ERROR_DRIVER_LACK_INFORMATION" />, { toastId: 'driver-error' })
          } else if (!details.phone && details.mail) {
            customToast.error(<TranslatedText i18nKey="ERROR_DRIVER_LACK_INFORMATION_PHONE" />, {
              toastId: 'driver-error',
            })
          } else {
            customToast.error(<TranslatedText i18nKey="ERROR_DRIVER_LACK_INFORMATION_EMAIL" />, {
              toastId: 'driver-error',
            })
          }

          customToast.clearQueue()
        }
      })
      .catch(() => alert(`Could not find details for driver with id ${driver.value}`))
  }

  const saveMileage = useMutation('mileage', ({ accountNo, mileage }) => recordMileage(accountNo, mileage), {
    onSuccess: () => {
      return queryClient.invalidateQueries('cars').then(() => console.log('Invalidating car'))
    },
  })

  let make = car ? car.make : ''

  switch (make.toLowerCase()) {
    case 'vw':
      make = 'volkswagen'
      break
    case 'mercedes-benz':
      make = 'mercedesbenz'
      break
    default:
      break
  }

  const driverOptions = [
    {
      value:0,
      label: car?.driver?.name
    },
    {
      value:1,
      label: "Demo User"
    }
  ]

  console.log(car)


  const driverIndex = window.localStorage.getItem('driver') ?? 0
  const [driver, setDriver] = useState(driverIndex || 0)

  const mileage = window.localStorage.getItem('mileage') ?? car.mileage
  const [currentMileage, setCurrentMileage] = useState(mileage || 0)

  const [isLoading, setIsloading] = useState(false)
  useEffect(() => {
    setInterval(() => {
      setIsloading(false)
    }, 3000)
  }, [isLoading])
  function update() {
     setIsloading(true)
      window.localStorage.setItem("driver", driver),
      window.localStorage.setItem('mileage', currentMileage)
  }
  return (
    <>
      <CarGrid>
        <CarGridContainer addBackground>
          <CarCard brand={make} car={car}></CarCard>
        </CarGridContainer>
        <div>
         <strong>
            <TranslatedText i18nKey="DRIVER" />
          </strong>
           <DriverGroup>
            <DropDownMenu
              width={'400px'}
              options={driverOptions}
              value={driverOptions[driver]}
              placeholder={i18nInstance.translate('CHOOSE_DRIVER')}
              onChange={(d) => {
                setDriver(d.value)
              }}
            />
            {saveDriver.isLoading && <Loading />}
          </DriverGroup>
          <strong>
            <TranslatedText i18nKey="REGISTER_MILEAGE" />
          </strong>
     <MileageContainer>
                    <Input
                      id={`test`}
                      placeholder={'Navn Navnesen'}
                      value={currentMileage}
                      onChange={(e) => {
                        setCurrentMileage(e.target.value)
                      }}
                    />
                 <Button onClick={() =>   update()              
}>
            {isLoading ? <p>Submitting...</p>: <p>Save</p>}
          </Button>
          </MileageContainer>
          </div>
       
        <CarGridContainer>
          {!car?.accountNo && [
            <TranslatedText i18nKey={'ORDERED_CAR_IS_READ_ONLY'} />,
            <TranslatedText i18nKey={'TAX_CALCULATION_BASIS'}>
              {(value) => (
                <Text>
                  {value}: {formatCurrency(car.taxCalculationBasis || 0)}
                </Text>
              )}
            </TranslatedText>,
          ]}
        </CarGridContainer>
      </CarGrid>
      <CarDetailGrid>
        <CarDetailsOverview car={car}></CarDetailsOverview>
      </CarDetailGrid>
    
      <Toast limit={1} />
    </>
  )
}

const MileageContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

const CarGrid = styled.div`
  display: grid;
  column-gap: 80px;
  width: 100%;
  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const CarDetailGrid = styled.div`
  display: grid;
  width: 100%;
  margin-top: var(--spacing-largeMinus);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  @media (min-width: 600px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

const CarGridContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) =>
    props.addBackground
      ? css`
          padding: 0px;
        `
      : css`
          padding: var(--spacing-smallMinus);
        `}
  ${(props) =>
    props.addBackground
      ? css`
          background-color: var(--color-white);
          border: 1px solid var(--color-grayLine);
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
        `
      : css`
          background-color: transparent;
        `}
`

const MyItemGroup = styled.div`
  display: flex;
  > * {
    margin-right: ${spacing.small};
  }
`

const DriverGroup = styled.div`
  padding-bottom: var(--spacing-mediumMinus);
  > * {
    display: inline-block;
  }
  > :not(div:first-child) {
    height: 25px;
    margin-left: 10px;
  }
`

const StyledButton = styled(Button)`
  align-self: end;
`

const ToastLink = styled(Link)`
  text-decoration: none;
  font-weight: var(--fontWeight-bold);
  color: var(--color-headerText);
  :hover {
    color: var(--color-primary);
  }
`

const ToastButton = styled(Button)`
  position: relative;
  font-size: var(--font-text);
  font-weight: var(--fontWeight-normal);
  color: var(--color-headerText);
  width: var(--spacing-mediumPlus);
  height: var(--spacing-medium);
  margin-right: var(--spacing-smallMinus);
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: var(--spacing-micro);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  background-color: var(--color-primaryLight);
  :hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: var(--color-primary);
    color: var(--color-bg);
    border-radius: var(--spacing-micro);
  }
`
const ToastButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: var(--spacing-smallMinus);
  margin-bottom: var(--spacing-tiny);
`

export default CarInfo
