import React from "react"
import CurvedLineGraph from "./CurvedLineGraph"

const FuelVolume = ({ radius, data }) => (
  <CurvedLineGraph
    name={"fuelVolume"}
    height={"140"}
    data={data}
  />
)

export default FuelVolume
