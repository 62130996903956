import React from "react";
import styled, { css } from "styled-components";
import { TranslatedText } from "@staccx/i18n";
import { SmallHeading } from "./Headings";

const PageContainerTitled = ({ children, title, numItems, maxNumItems }) => {
  return <Container numItemsEqualsMax={numItems >= maxNumItems}>
    {title && <SpacedHeadingContainer>
      <SmallHeading>
        <TranslatedText i18nKey={title}/>
      </SmallHeading>
      <SmallHeading>
        <TranslatedText i18nKey="OF">
          {value => `${numItems} ${value?.toLowerCase()} ${maxNumItems}`}
        </TranslatedText>
      </SmallHeading>
    </SpacedHeadingContainer>}
    {children}
  </Container>
}

export default PageContainerTitled

const Container = styled.div`
  display: grid;
  gap: var(--spacing-medium);
  padding: var(--spacing-mediumMinus) var(--spacing-largeMinus) var(--spacing-small) var(--spacing-largeMinus);
  ${props => props.numItemsEqualsMax ? css`
    padding: var(--spacing-mediumMinus) var(--spacing-largeMinus) var(--spacing-largeMinus) var(--spacing-largeMinus)
  ` : css`
    padding: var(--spacing-mediumMinus) var(--spacing-largeMinus) var(--spacing-small) var(--spacing-largeMinus)
  `};
  border-radius: var(--borderRadius);
  background-color: var(--color-primaryLight);
  opacity: 1;
`

const SpacedHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
