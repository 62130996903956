import React, { useState } from 'react'
import styled from 'styled-components'
import { Alert, Button, Layout } from '@staccx/bento'
import Login from './Login'
import { TranslatedText } from '@staccx/i18n'
import { loggedoutReasons } from '../../../constants/statusMessages'

const LOGIN_TYPE_BYTELAB = 'loginBytelab'
const loggedoutReason = sessionStorage.getItem('loggedoutReason')

const LoginChoice = () => {
  const [type, typeSet] = useState(null)

  return (
    <React.Fragment>
      {!type && (
        <Layout style={{ maxWidth: '400px' }}>
          <Center>
            <svg width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.7343 0L34.8021 10.0166V29.9834L17.7343 40L0.666748 29.9834V10.0166L17.7343 0ZM3.48302 28.388V22.4504L16.326 29.9839V35.9253L3.48302 28.388ZM16.326 26.7359L3.48302 19.2025V13.2647L16.326 20.7982V26.7359ZM19.1423 35.9255V20.7988L24.1584 17.8565V32.9817L19.1423 35.9255ZM26.9747 31.3289L31.9858 28.388V13.265L26.9747 16.2045V31.3289ZM22.7994 6.22079L17.7343 3.24826L4.84241 10.8143L9.90546 13.7842L22.7994 6.22079ZM25.5671 7.84511L12.674 15.4081L17.7347 18.3766L30.6267 10.8144L25.5671 7.84511Z"
                fill="#BD5E55"
              />
            </svg>
            <span>Stacc Asset Finance</span>
          </Center>
          <Button onClick={() => typeSet(LOGIN_TYPE_BYTELAB)}>Bytelab CC</Button>
          <LoggedoutReason />
        </Layout>
      )}
      {type === LOGIN_TYPE_BYTELAB && <Login type={'bytelab'} />}
    </React.Fragment>
  )
}

const LoggedoutReason = () => {
  switch (loggedoutReason) {
    case loggedoutReasons.UNKNOWN_USER:
      return (
        <TranslatedText i18nKey={loggedoutReason}>{(value) => <Alert type={'warning'}>{value}</Alert>}</TranslatedText>
      )

    case loggedoutReasons.NORMAL_LOGOUT:
      return (
        <TranslatedText i18nKey={loggedoutReason}>{(value) => <Alert type={'info'}>{value}</Alert>}</TranslatedText>
      )

    default:
      return null
  }
}

const Center = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

LoginChoice.propTypes = {}

export default LoginChoice
