import React from 'react'
import { TranslatedText } from '@staccx/i18n'
import { formatCurrency, formatDate } from '../../../utils/tableFuncs'
import { NavLink } from 'react-router-dom'
import { ArrowIcon } from '../../home/routes/Home'
import ChevronRight from '../../../icons/ChevronRight'

export default function invoiceData() {
  return [
    {
      accessorKey: 'invoiceNumber',
      id: 'invoiceNumber',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'INVOICENUMBER'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row, getValue }) => row.original.invoiceNumber
    },
    {
      accessorKey: 'invoiceDate',
      id: 'invoiceDate',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'INVOICEDATE'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: ({ getValue }) => formatDate(getValue()) || ''
    },
    {
      accessorKey: 'dueDate',
      id: 'dueDate',
      meta: { alignmentType: 'date' },
      header: () => <TranslatedText i18nKey={'DUEDATE'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: ({ getValue }) => formatDate(getValue()) || ''
    },
    {
      accessorKey: 'status',
      id: 'status',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'STATUS'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: ({ row }) => {
        const dueDate = new Date(row.original.dueDate)
        if (dueDate < new Date()) {
          return <TranslatedText i18nKey={'OVERDUE'} />
        }

        return <TranslatedText i18nKey={'NOT_DUE'} />
      }
    },
    {
      accessorKey: 'totalAmount',
      id: 'totalAmount',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'TOTALAMOUNT'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: ({ getValue }) => formatCurrency(getValue()) || ''
    },
    {
      accessorKey: 'remainingAmount',
      id: 'remainingAmount',
      meta: { alignmentType: 'string' },
      header: () => <TranslatedText i18nKey={'REMAININGAMOUNT'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: ({ getValue }) => formatCurrency(getValue()) || ''
    },
    {
      accessorKey: 'goToInvoice',
      id: 'goToInvoice',
      meta: { alignmentType: 'number' },
      header: () => <p></p>,
      cell: ({ row, getValue }) =>
        row.original.consolidateInvoiceNumber ? (
          <NavLink
            to={`/reports/invoice/${row.original.consolidateInvoiceNumber}`}
            style={{
              textDecoration: 'none'
            }}
          >
            <ChevronRight />
          </NavLink>
        ) : null
    }
  ]
}
