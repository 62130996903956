import styled from 'styled-components'
import { TranslatedText } from '@staccx/i18n'
import AutoplanSmall from '../../icons/AutoPlanSmall'
import React from 'react'
import { NavLink } from 'react-router-dom'
import Container from '../Container'

const Footer = () => {
  return (
  
      <FooterContainer>
        {/* <Links>
         <StyledNavLink to={'dashboard'}>
            Dashboard
          </StyledNavLink>
           <StyledNavLink to={'cars'}>
            Cars
          </StyledNavLink>
           <StyledNavLink to={'employees'}>
            Employees
          </StyledNavLink>
           <StyledNavLink to={'invoices'}>
            Invoices
          </StyledNavLink>
          <StyledNavLink to={'/reports'}>
            Reports
          </StyledNavLink>
        </Links> */}
        <p>© 2023 Stacc</p>
      </FooterContainer>
  )
}

export default Footer



const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-newFont);
  opacity: 0.75;
  justify-content: space-between;
  height: 100px;
  padding: var(--spacing-medium);
  border-top: 1px solid lightgray;

`
const Links = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-large);
`

const StyledLink = styled.a`
  text-decoration: none;
  color: var(--color-newFont);
  font-size: var(--font-base);
  font-weight: var(--fontWeight-normal);
  transition: 0.3s;
  :hover {
    box-shadow: 0 4px 2px -2px var(--color-primary);
  }
`

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: var(--color-newFont);
  font-size: var(--font-base);
  font-weight: var(--fontWeight-normal);

  :hover {
          opacity: .5;

  }
  > span {
    transition: 0.3s;

    :hover {
      box-shadow: 0 4px 2px -2px var(--color-primary);
    }
  }
`
