import { css } from 'styled-components'
import { Heading, spacing, VARIANT_DEFAULT, font, fontWeight } from '@staccx/bento'

Heading.createVariants({
  heading: {
    [VARIANT_DEFAULT]: css`
      line-height: 1;
      margin-bottom: ${spacing.small};
    `,
    extraLarge: css`
      font-size: ${font.h1};
      font-weight: ${fontWeight.bold};
    `,
    large: css`
      font-size: ${font.h2};
      font-weight: ${fontWeight.bold};
    `,
    medium: css`
      font-size: ${font.h3};
      font-weight: ${fontWeight.normal};
    `,
    mediumBold: css`
      font-size: ${font.h3};
      font-weight: ${fontWeight.bold};
    `
  },
})
