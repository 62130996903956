import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import styled from 'styled-components'
import { TranslatedText } from '@staccx/i18n'
import { Text, Wrapper, theming } from '@staccx/bento'
import { useBudgetSelections, usePendingUserTasks } from '../../../context/SigningProvider'
import Container from '../../../components/Container'
import { Selection } from '../components/budgetSelection/Selection'

const BudgetSelection = () => {
  const { id } = useParams()
  const { budgetSelections, isLoading: budgetSelectionsLoading } = useBudgetSelections()
  const { pendingUserTasks, isLoading: pendingUserTasksLoading } = usePendingUserTasks(id)
  const budgetSelection = useMemo(() => findBudgetSelection(budgetSelections, id), [budgetSelections, id])
  const selections = _.groupBy(budgetSelection?.data?.budgetData.carBudgetOptionalSetup, 'customerChoice')

  return (
    <Container isLoading={budgetSelectionsLoading || pendingUserTasksLoading} renderChildrenDuringLoading={false}>
      <Wrapper>
        {budgetSelection && pendingUserTasks?.tasks.length > 0 ? (
          <>
            <CarSummary>
              <Title>
                <TranslatedText i18nKey="CUSTOMIZE_CAR_OPTIONS">
                  {(value) => value ?? 'Gjøre tilvalg på bil'}
                </TranslatedText>
              </Title>
              <Subtitle>
                {budgetSelection.data.budgetData.carMake.toUpperCase()}{' '}
                {budgetSelection.data.budgetData.carModel.toUpperCase()} -{' '}
                <TranslatedText i18nKey="BUDGET">{(value) => value ?? 'Budsjett'}</TranslatedText>{' '}
                {budgetSelection.data.budgetData.carBudgetNo}
              </Subtitle>
            </CarSummary>
            <Selection
              taskId={pendingUserTasks.tasks[0].taskId}
              budgetId={budgetSelection.data.budgetData.carBudgetNo}
              selections={selections}
            />
          </>
        ) : (
          <Text>Could not find pending budget selection.</Text>
        )}
      </Wrapper>
    </Container>
  )
}

const findBudgetSelection = (budgetSelections, id) => budgetSelections && budgetSelections.find((i) => i.flowId === id)

const CarSummary = styled.div`
  padding: var(--spacing-smallPlus) 0 var(--spacing-smallPlus) 0;
  margin-bottom: ${theming.spacing.medium()};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const Title = styled.span`
  font-size: var(--spacing-medium);
  font-weight: 400;
  margin-bottom: ${theming.spacing.medium()};
`

const Subtitle = styled.div`
  margin-bottom: var(--spacing-mediumMinus);
  font-size: var(--font-h3);
  font-weight: 500;
`

export default BudgetSelection
