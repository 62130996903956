import React from "react"
import { Button, Heading, spacing, Text, Wrapper } from "@staccx/bento"
import styled from "styled-components"
import { TranslatedText } from "@staccx/i18n"

const DeleteBox = ({heading, body, confirmButtonText, cancelButtonText, ...props}) => {

  return (
    <NewWrapper>
      <StyledText>
        <Heading level={1}>Delete employee</Heading>
        <Text>Are you sure you want to delete this employee?</Text>
      </StyledText>
      <ButtonGroup>
        <StyledDeleteButton onClick={() => props.onFinish()}>
          Yes, delete employee
        </StyledDeleteButton>
        <StyledButton onClick={() => props.onCancel()}>
          Cancel
        </StyledButton>
      </ButtonGroup>
    </NewWrapper>
  )
}

export default DeleteBox

const StyledText = styled.div`
  margin-bottom: ${spacing.largePlus};
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
`

const StyledButton = styled(Button)`
  background: #F0F0F0;
  color: black;
  :hover: {
    background: #F0F0F0;
    opacity: 0.9;
  }
`
const StyledDeleteButton = styled(Button)`
background: #ED003D;
:hover {
  background: #ED003D;
  opacity: 0.8;
}
`

const NewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
`