import { useEffect, useMemo, useRef, useState } from 'react'
import { getPrice } from '../../../api/api'
import { get } from 'lodash'
import { PRICE_CALCULATION, PRICE_CALCULATION_NO_ENGINE } from './useCarConfiguration'
import { useUserContext } from '../../../context/UserProvider'
import customToast from '../components/toast/customToast'
import { useDebounce } from '@staccx/bento'

export const useCarPrice = ({ configuration, options, vehicleType, financingOptions, errorHandler }) => {
  const {
    chosenWheelPackage,
    chosenAgreements = [],
    derivativeLocal,
    makeKey,
    modelKey,
    fuelType,
    hybridCode,
    secondaryFuelType,
    chosenNorwegianEquipment = [],
    firstRegistrationFee
  } = configuration || {}

  const [price, priceSet] = useState(0)
  const errorMessage = useRef('')
  const { term, mileage } = financingOptions
  const vehicleId = useRef(configuration?.vehicleId)

  const { chosenCompany } = useUserContext()
  const companyId = chosenCompany.id

  const optionsWithoutResValueCalc = []

  if (chosenWheelPackage) {
    optionsWithoutResValueCalc.push({
      price: chosenWheelPackage?.SalesPriceExclVAT
    })
  }

  // Do not include option in price of it is already included in a package
  const isIncludedInPackage = (option) =>
    options.some((item) => item.includes?.some((optionId) => optionId === option.optionId))

  const optionsPrice = useMemo(
    () => options.reduce((acc, next) => (isIncludedInPackage(next) ? acc : acc + next.price), 0),
    [configuration, options]
  )
  const optionsWithoutResValueCalcPrice = useMemo(
    () => (configuration ? optionsWithoutResValueCalc.reduce((acc, next) => acc + next.price, 0) : null),
    [configuration, optionsWithoutResValueCalc]
  )

  const [increment, incrementSet] = useState(0)
  const [debouncePrice] = useDebounce(increment, 2000)

  useEffect(
    () => {
      incrementSet((prevState) => prevState + 1)
    },
    [
      vehicleId.current,
      configuration?.vehicleId,
      options.length,
      term,
      mileage,
      optionsWithoutResValueCalcPrice,
      optionsPrice,
      firstRegistrationFee
    ],
    []
  )

  const chosenNorwegianEquipmentPrice = useMemo(
    () =>
      chosenNorwegianEquipment
        .filter((item) => !item.thirdPartyEquipment)
        .reduce((acc, next) => acc + (next.price || 0), 0),
    [chosenNorwegianEquipment]
  )
  const chosenNorwegianEquipmentPriceThirdParty = useMemo(
    () =>
      chosenNorwegianEquipment
        .filter((item) => item.thirdPartyEquipment)
        .reduce((acc, next) => acc + (next.price || 0), 0),
    [chosenNorwegianEquipment]
  )

  useEffect(() => {
    vehicleId.current = configuration?.vehicleId

    if (!configuration || !vehicleType || !term || !mileage) {
      if (price) priceSet(0)
      return
    }

    if (!derivativeLocal) {
      errorHandler(PRICE_CALCULATION_NO_ENGINE)
      return
    } else {
      customToast.clearToastById(PRICE_CALCULATION_NO_ENGINE)
    }

    if (
      errorMessage?.current?.vehicleId === vehicleId.current &&
      errorMessage.current.message.toLowerCase().includes(PRICE_CALCULATION)
    ) {
      return
    }

    const payload = {
      CarMake: makeKey,
      CarModel: modelKey,
      CarEngine: derivativeLocal,
      CarFuel: fuelType + (secondaryFuelType && secondaryFuelType !== '-' ? secondaryFuelType : ''),
      HybridCode: hybridCode,
      CarCategory: (vehicleType || '').toUpperCase(),
      DrivingDistance: mileage,
      TermCountYear: term.year || term,
      CarPrice: configuration.price + optionsPrice + chosenNorwegianEquipmentPrice,
      PriceWithoutResidualValueCalc: optionsWithoutResValueCalcPrice + chosenNorwegianEquipmentPriceThirdParty,
      FirstRegistrationFee: firstRegistrationFee
    }

    // todo toast on error?
    getPrice(companyId, payload)
      .then((r) => {
        if (r.success && r.monthlyLeasingCost !== price) {
          priceSet(r.monthlyLeasingCost)
        }
      })
      .catch((error) => {
        errorHandler(error)
        errorMessage.current = {
          vehicleId: configuration?.vehicleId,
          message: get(error, 'response.data.Result.Message', ''),
        }
      })
  }, [debouncePrice])

  return {
    monthlyPrice: price > 0 ? chosenAgreements.reduce((acc, curr) => acc + curr.MonthlyCost, 0) + price : price,
    sumAccessoriesRetailPrice: optionsPrice,
    sumOptionsWithoutResidualValueCalcPrice: optionsWithoutResValueCalcPrice,
  }
}
