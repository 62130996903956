import styled, { css } from 'styled-components'
import { spacing, Text } from '@staccx/bento'

Text.createVariants({
  text: {
    lede: css`
      margin-bottom: ${spacing.small};
      display: inline-block;
    `,
    page: css`
      display: flex;
      align-items: center;
    `,
  },
})

export const Label = styled.p`
  margin: 0;
  font-weight: var(--fontWeight-bold);
  font-size: var(--font-base);
`

export const ReportHeading = styled.p`
  margin: ${(props) => (props.noMargin ? '0' : '0 0 var(--spacing-largeMinus) 0')};
  font-size: var(--font-h1);
  font-weight: var(--fontWeight-bold);
`

export const H4Heading = styled.p`
  margin: var(--spacing-tiny) 0;
  line-height: var(--spacing-mediumPlus);
  font-weight: var(--fontWeight-bold);
  font-size: var(--font-h4);
`
