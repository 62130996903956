import React from "react";
import styled from "styled-components";
import { Button, CheckBox, Heading, theming } from "@staccx/bento";
import formatOptionName from "../../../../../utils/formatOptionName";
import formatRequiresText from "../../../../../utils/formatRequiresText";
import { TranslatedText } from "@staccx/i18n";
import CustomPopover from "../../popover/Popover";

export const MultiOptionListItem = ({
  disabled,
  option,
  onSelect,
  getOptionNameByOptionId,
  getOptionIncludedBy,
  getExcludedByOption,
  touchedOptions,
  fulfillsRequirements,
  getSelectedByPackage, 
  handleRemoveOption,
  getOptionDependencies,
  triggerRevalidation = true
}) => {
  const {
    selectedOptions,
    excludedOptions,
    includedOptions
  } = touchedOptions

    const { isRequired: isRequiredByAutoplan } = option

  const isFulfilRequirements = fulfillsRequirements(option)
  const isIncluded = (includedOptions.some(
      (opt) => opt === option.optionId)
  )
  const selectedByPackage = getSelectedByPackage(option)
  const { requiredBy, requires } = getOptionDependencies(option)

  const isChecked =
    (selectedOptions?.some((opt) => opt.optionId === option.optionId)
      || isIncluded) || isRequiredByAutoplan || selectedByPackage

  const getExcludedReason = () => {
    const excludedBy = getExcludedByOption(option)
    return excludedBy.length > 0
      ? excludedBy
      : excludedOptions.filter(id => id === option.optionId)
  }

  const isExcluded = excludedOptions && excludedOptions.includes(option.optionId)
  const isExcludedBy = isExcluded ? getExcludedReason() : null

  const includedBy = getOptionIncludedBy(option.optionId)

  const isDisabled = disabled !== undefined ? disabled : isExcluded || selectedByPackage || isRequiredByAutoplan || isIncluded

  return (
    <OptionWrapper>
      <StyledLabel htmlFor={option.optionId.toString()}>
        <StyledCheckBox
          onChange={() => onSelect(option, null, triggerRevalidation)}
          id={option.optionId.toString()}
          checked={isChecked}
          disabled={isDisabled}
        />
        <OptionText>
          <div>
            <OptionHeading isDisabled={isDisabled} level={6}>
              {formatOptionName(option.optionName)}
            </OptionHeading>
          </div>
          <Unavailable>
            {isExcluded && (
              <>
                <CustomPopover>
                  <TooltipHeading level={4}>
                    <TranslatedText i18nKey={"EXCLUDED_BY"}/>
                  </TooltipHeading>
                  <ItemList>
                    <ul>
                      {isExcludedBy.map((excludedOption, index) =>
                        <li
                          key={index}>{formatOptionName(excludedOption.optionName || getOptionNameByOptionId(excludedOption))}</li>
                      )}
                    </ul>
                  </ItemList>
                </CustomPopover>
                <Explainer>
                  <TranslatedText i18nKey={"UNAVAILABLE"}/>
                </Explainer>
              </>
            )}
            {!isFulfilRequirements && !isExcluded ? (
              <>
                <CustomPopover>
                  <TooltipHeading level={4}>
                    <TranslatedText i18nKey={"REQUIRES"}/>
                  </TooltipHeading>
                  <ItemList>
                    <ul>
                      {formatRequiresText(
                        option.requires,
                        getOptionNameByOptionId
                      ).map((req, index) => (
                        <li key={index}>{req}</li>
                      ))}
                    </ul>
                  </ItemList>
                </CustomPopover>
                <Explainer>
                  <TranslatedText i18nKey={"OTHER_OPTIONS_REQUIRED"}/>
                </Explainer>
              </>
            ) : null}
            {isRequiredByAutoplan ? <Explainer>
              <TranslatedText i18nKey="REQUIRED_BY_AUTOPLAN"/>
            </Explainer> : isIncluded && !selectedByPackage && (
              <>
                <CustomPopover>
                  <ItemList>
                    <span>
                      <TranslatedText i18nKey={"INCLUDED_BY"}/>
                    </span>
                    <ul>
                      {includedBy.map((i) => (
                        <li key={i}>{getOptionNameByOptionId(i.optionId)}</li>
                      ))}
                    </ul>
                  </ItemList>
                </CustomPopover>
                <Explainer>
                  <TranslatedText i18nKey="INCLUDED"/>
                </Explainer>
              </>
            )}
            {selectedByPackage && (
              <>
                <CustomPopover>
                  <TranslatedText i18nKey="INITIAL_OPTION_EXPLAIN"/>
                  <ItemList>
                    <ul>
                      {requiredBy && requiredBy.map((r, i) => (<li key={i}>{getOptionNameByOptionId(parseInt(r))}</li>))}                      
                    </ul>
                    {requires.length > 0 &&
                      <ItemList>
                        <TranslatedText i18nKey={"INITIAL_REQUIREMENTS_EXPLAIN"}>
                          {value => <StyledLabel>{value}:</StyledLabel>}
                        </TranslatedText>
                        <ItemList>
                          <ul>
                            {requires.map((r, i) => (<li key={i}>{formatOptionName(r?.optionName)}</li>))}
                          </ul>
                        </ItemList>
                      </ItemList>
                    }
                    <ButtonArea>
                      <TranslatedText i18nKey="REMOVE">
                        {value => 
                          <Button onClick={() => {
                            selectedByPackage.forEach(o => handleRemoveOption(o))
                          }} >
                            {value}
                          </Button>}
                      </TranslatedText>
                    </ButtonArea>
                  </ItemList>
                </CustomPopover>
                <Explainer>
                  <TranslatedText i18nKey="IS_OPTION_DEPENDENCY"/>
                </Explainer>
              </>
            )}
          </Unavailable>
        </OptionText>
      </StyledLabel>
    </OptionWrapper>
  )
}

const ButtonArea = styled.div`
  margin-top: var(--spacing-medium);
`

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
`

export const StyledCheckBox = styled(CheckBox)`
  & ~ label::before {
    top: 0;
  }

  & ~ label > svg {
    top: var(--spacing-microPlus);
  }

  & ~ label {
    display: block;
  }

  &[disabled] ~ label::before {
    cursor: default;
    background-color: var(--color-line);
    border-color: var(--color-line);
  }
`

const TooltipHeading = styled(Heading)`
  font-size: ${theming.font.base};
`

export const OptionText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const OptionHeading = styled(Heading)`
  margin: 0;
  font-weight: ${prop => prop.isDisabled ? `var(--fontWeight-normal)` : `var(--fontWeight-bold)`};
`

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-small) var(--spacing-smallPlus);
  width: 100%;

  :not(:only-child) {
    box-shadow: 0 var(--spacing-microMinus) 0 0 var(--color-line);
  }
`

const Unavailable = styled.div`
  margin-left: var(--spacing-small);
  display: flex;
  gap: var(--spacing-tiny);
`

const Explainer = styled.span`
  font-style: italic;
`

const ItemList = styled.div`
  margin: 12px 0 12px 0;
  
  ul li::before {
    content: "- "
  }

  span {
    font-weight: var(--fontWeight-bold);
  }

  display: block;
`
