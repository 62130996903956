import { useMemo, useState, useCallback } from 'react'
import styled, { css, keyframes } from 'styled-components'
import {  FullScreenLoader, Heading} from '@staccx/bento'
import Container from '../../../components/Container'
import LostCard from '../components/LostCard'
import { TranslatedText } from '@staccx/i18n'
import { useCarContext } from '../../../context/CarProvider'
import { useDriverContext } from '../../../context/DriverProvider'
import { useChosenCompany, useFeatureToggles, useUser, useUserContext } from '../../../context/UserProvider'
import { useNotifications } from '../hooks/useNotifications'
import { createTextArray } from '../../../api/sanity'
import { useQuery } from 'react-query'
import { newsFromSanity } from '../../../api/api'
import { useI18nContext } from '../../../context/I18nProvider'
import { useNavigate } from 'react-router-dom'
import Dialog from '../../../components/modal/Dialog'
import { Link } from 'react-router-dom'
import customToast from '../../carConfiguration/components/toast/customToast'
import { fetchSalesPerson } from '../../../api/api'
import { useBudgetSelections, usePendingSignings } from '../../../context/SigningProvider'
import ContactForm from '../../../components/modal/ContactForm'
import ReportDamageForm from '../../../components/modal/ReportDamageForm'
import { ComparisonGraph } from '../../../components/ComparisonGraph'
import { InTransit } from '../../../components/InTransit'
import { bytelabConfig } from '../../../utils/bytelabConfig'
import { OrderProgress } from '../components/OrderProgress'
import { TaskList } from '../components/TaskList'
import { set } from 'lodash'
import Loader from '../../../components/Loader'
const Home = () => {
  const [showNewDriverModal, showNewDriverModalSet] = useState(false)
  const [showDamageReport, setShowDamageReport] = useState(false)
  const [selectedNewsArticle, selectedNewsArticleSet] = useState(null)
  const [newsPage, newsPageSet] = useState(0)
  const [showOrderCarDialog, showOrderCarDialogSet] = useState(false)

  const navigate = useNavigate()
  const company = useChosenCompany()
  const featureToggles = useFeatureToggles()

  const { updateCars, allCars } = useCarContext()
    const user = useUserContext()

  const { pendingSignings } = usePendingSignings(user?.user?.id)
  const { budgetSelections } = useBudgetSelections(user?.user?.id)
  const notifications = useNotifications(pendingSignings, budgetSelections, allCars)

  const { updateDrivers } = useDriverContext()
  const { data: news } = useQuery(['news'], () => newsFromSanity())
  const { language } = useI18nContext()
  const formattedNews = useMemo(() => {
    if (!news) return []
    return news.map((newsItem) => ({
      heading: newsItem?.newsHeading?.[language],
      id: newsItem?._id,
      ingress: createTextArray(newsItem?.newsIngress?.[language]),
      body: createTextArray(newsItem?.newsBody?.[language]),
      image: newsItem.newsImage,
      author: newsItem.newsAuthor,
      updatedAt: new Date(newsItem._updatedAt)
    }))
  }, [news, language])

  const { data: salesPerson } = useQuery(['salesperson', company?.id], () => fetchSalesPerson(company?.id), {
    enabled: !!company?.id
  })

  const onEditDriverFinish = () => {
    queryClient.invalidateQueries('cars').then(() => console.log('Updating cars'))
    queryClient.invalidateQueries('drivers').then(() => console.log('Updating drivers'))
    queryClient.invalidateQueries('contacts').then(() => console.log('Updating contacts'))
  }

  const confirmLostCardOrder = useCallback(() => {
    return customToast.success(<TranslatedText i18nKey="ORDER_COMPLETED" />)
  }, [])

  const faultOrder = useCallback(() => {
    return customToast.error(<TranslatedText i18nKey="ORDER_COMPLETED_ERROR" />)
  }, [])

  const co2Data = {
    title: 'Average CO2',
    data: 750,
    overAllAverage: 1100
  }

  const noxData = {
    title: 'Average NOX',
    data: 30,
    overAllAverage: 110
  }


  const signingTasks = [
    {
      date: '2021-09-01',
      type: 'Order',
      status: 'Awaiting signing',
      car: 'Volkswagen ID.5',
      driver: 'Fredrik Myhre',
      due: '2021-09-15'
    },
/*     {
      date: '2021-09-01',
      type: 'Order',
      status: 'Awaiting external approval',
      car: 'Bmw i3',
      driver: "Johann Bugge",
      due: '2021-09-15'
    } */
  ]

  const findCurrentSeason = () => {
    const date = new Date()
    const month = date.getMonth()
    switch(month) {
      case 0:
      case 1:
      case 2:
      case 11:
      case 10:
        case 12:
        return 'winter'
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
        return 'summer'
      default:
        return 'summer'
    }
  }

  const currentSeason = findCurrentSeason()


  const [activeTask, setActiveTask] = useState(null)
  const [triggerModal, setTriggerModal] = useState(false)

  const handleTaskClick = (task) => {
    setActiveTask(task)
    setTriggerModal(true)
  }
  return (
    <WrapperContainer>
      <Section>
        <TopWrapper>
          <div>
            <Heading level={3}>
              <p>Velkommen {user?.newUserData ? "," : "!"}</p>
              {user?.newUserData?.profile?.name}
            </Heading>
          </div>
        </TopWrapper>
        <TasksList>
          <a target="_blank" href={`/Home/Profile`}
>
            <ButtonCard text="Order car" image={ currentSeason === "winter" ?  "orderCar.png" : "orderCarSummer.png"}>
              <ButtonCardIcon>
                <ArrowIcon />
              </ButtonCardIcon>
            </ButtonCard>
          </a>
          <Dialog
            open={showNewDriverModal}
            onOpenChange={showNewDriverModalSet}
            trigger={
              <ButtonCard onClick={() => showNewDriverModalSet(true)} text="New employee" image={ currentSeason === "winter" ? "newEmployee.png" : "newEmployeeSummer.png"}>
                <ButtonCardIcon>
                  <ArrowIcon />
                </ButtonCardIcon>
              </ButtonCard>
            }
          >
            <ContactForm
              close={showNewDriverModalSet}
              onFinish={async (setAsDriverOnCurrentCar) => {
                showNewDriverModalSet(false)
                updateDrivers()
                if (setAsDriverOnCurrentCar) {
                  updateCars()
                }
                ;() => onEditDriverFinish()
              }}
            />
          </Dialog>

          <LostCard onOrder={confirmLostCardOrder} onFaultOrder={faultOrder}>
            <ButtonCard text="Lost fuel card" image={currentSeason === "winter" ? "lostFuelCard.png" : "lostFuelCardSummer.png"}>
              <ButtonCardIcon>
                <ArrowIcon />
              </ButtonCardIcon>
            </ButtonCard>
          </LostCard>
          <Dialog
            open={showDamageReport}
            onOpenChange={setShowDamageReport}
            trigger={
              <ButtonCard onClick={() => setShowDamageReport(true)} text="Report damage" image={currentSeason === "winter"  ? "reportDamage.png" : "reportDamageSummer.png"}>
                <ButtonCardIcon>
                  <ArrowIcon />
                </ButtonCardIcon>
              </ButtonCard>
            }
          >
            {' '}
            <ReportDamageForm
              close={setShowDamageReport}
              onFinish={() => {
                setShowDamageReport(false)
              }}
            />
          </Dialog>
        </TasksList>
      </Section>
       
       <MainPageContentLayout>
       
      <Section>
        <StyledContainer>
          <ComparisonGraph data={co2Data} />
          <ComparisonGraph data={noxData} />
        </StyledContainer>
      </Section>
      <Section>
         <Dialog
            open={triggerModal}
            onOpenChange={setTriggerModal}
          >
            <StyledDialog>
           <h3>Connecting to third party signing</h3>
           <Spinner/>
           </StyledDialog>
          </Dialog>
                <OrderLayout>

        <h4>My tasks</h4>
        <OrderSectionWrapper>
        <TaskList handleTaskClick={handleTaskClick} taskType='Ongoing Changes'/>
        <TaskList handleTaskClick={handleTaskClick} taskType='Signing' tasks={signingTasks}/>
        <TaskList handleTaskClick={handleTaskClick} taskType='Termination'/>
        </OrderSectionWrapper>
        </OrderLayout>
      </Section>
      <OrderLayout>
        <h4>Latest orders</h4>
      <OrderSectionWrapper>
        <OrderProgress/>
      </OrderSectionWrapper>
      </OrderLayout>
</MainPageContentLayout>
    </WrapperContainer>
  )
}

export default Home

const StyledDialog = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  
  border-top: 2px solid blue;
  border-right: 2px solid blue;
  border-bottom: 2px solid blue;
  border-left: 2px solid transparent;
  opacity: .75;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 100%;
`;

const MainPageContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 85%;
`

const OrderLayout = styled.div`
display: flex;
flex-direction: column;
gap: 2rem;

`
const OrderSectionWrapper = styled.div`
 display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
`
const WrapperContainer = styled.div`
  padding: 6rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  `
const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`

const NewsArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  * {
    margin: 0;
  }
`
const Url = styled.a`
  display: flex;
  align-items: center;

  gap: 15px;
  cursor: pointer;
  text-decoration: none;
  color: grey;
  transition: 100ms ease-in-out;
  font-weight: 500;
  :hover {
    gap: 30px;
  }
`
const NewsImage = styled.div`
  background-image: url('/bytelab.webp');
  background-size: cover;
  width: 100%;
  border-radius: 12px;
  min-height: 250px;
`
const NewsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 65%;
  gap: var(--spacing-large);
`
const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
  align-items: start;
  width: 100%;
`

const TasksList = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-medium);
  align-items: start;
  width: 100%;
  flex-wrap: wrap;
`
const Wrapper = styled.div`
  margin: 0 auto;
  padding: var(--spacing-medium);
  @media (min-width: 1200px) {
    padding: 0;
  }
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-largePlus);
  height: 100%;
  padding-top: var(--spacing-large);
  @media (min-width: 1200px) {
    grid-template-columns: 2fr 1fr;
  }
`

const Section = styled.div`
  display: grid;
  gap: 0.5rem;
`

const NewsPagination = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    line-height: 2.5em;
    text-decoration: underline;
    color: var(--color-primary);
    cursor: pointer;
  }
`

const HelpDialogPadding = styled.div`
  padding: 0 0 10px 0;
  width: 100%;
`

const ClickHereForHelpText = styled.div`
  margin: 8px 0 0 0;
`
const HelpLink = styled(Link)`
  text-decoration: none;
`
const Content = styled.div`
  width: 100%;
  margin-top: var(--spacing-medium);
`

const ButtonCardIcon = styled.span`
  position: absolute;
  bottom: 10px;
  right: 5%;
  font-weight: 500;
  color: white;
`

const ButtonCard = styled.button`
  padding: 6rem 10rem;
  border: none;
  display: flex;
  align-items: end;
  justify-content: space-between;
  position: relative;
  border-radius: 12px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease-in-out;
  :hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;    
    :before {
      transition: 0.3s ease-in-out;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.6) 100%);
    }
  }
  ${(p) => css`
    background-image: url('/${p.image}');
    background-size: cover;
  `}
  :before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.4) 100%);
    border-radius: 12px;
  }

  :after {
    ${(p) =>
      css`
        content: '${p.text}';
      `}
    position: absolute;
    bottom: 10px;
    left: 5%;
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
  }
`

export function ArrowIcon({ color = 'red', currentColor = 'white' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={20} width={20} viewBox="0 0 13 12">
      <path fill={currentColor} d="M.372 6.089a.902.902 0 0 1 .902-.902h9.918V6.99H1.274a.902.902 0 0 1-.902-.901Z" />
      <path
        fill={currentColor}
        d="m7.585.679 5.41 5.41-5.41 5.41a.901.901 0 0 1-1.275 0l-.077-.078a.902.902 0 0 1 0-1.275L10.29 6.09 6.233 2.03a.902.902 0 0 1 0-1.275L6.31.68a.902.902 0 0 1 1.275 0Z"
      />
    </svg>
  )
}
