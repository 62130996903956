import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import UserInfo from '../../pages/home/components/UserInfo'
import { TranslatedText } from '@staccx/i18n'
import AutoplanSmall from '../../icons/AutoPlanSmall'
import { reportsSubmenu } from '../../data/routes'
import MenuItem, { MenuTitle } from './MenuItem'
import { NavLink, useLocation } from 'react-router-dom'
import Language from '../../pages/profile/routes/Language'
import { StyledButton } from '../../theme/Theme.Button'
import { useFeatureToggles, useUser, useUserContext } from '../../context/UserProvider'
import Hamburger from './Hamburger'
import { useWindowSize } from '../../utils/useWindowSize'
import { useBudgetSelections, usePendingSignings } from '../../context/SigningProvider'
import { NewMenuItem } from './NewMenuItem'
import { bytelabConfig } from '../../utils/bytelabConfig'
import {
  CarSvg,
  EmployeesSvg,
  HomeSvg,
  InvoicesSvg,
  NotificationsSvg,
  OrderSvg,
  PowerSvg,
  ReportsSvg,
  SettingsSvg,
  SidewayCar,
  SupportSvg,
  TasksSvg
} from '../../icons/NewMenuCollection'
import { NewMenuButton } from './NewMenuButton'
import { first } from 'lodash'
import { useCarContext } from '../../context/CarProvider'

export function NewMenu({ collapse, handleCollapse }) {
  let location = useLocation()
  const featureToggles = useFeatureToggles()
  const [visible, setVisible] = useState(false)
  const threshold = 1219
  const { width } = useWindowSize()
  const [isSmall, isSmallSet] = useState(width < threshold)

  const user = useUserContext()
  const name = user?.newUserData?.profile?.name

  let merge
  if (user) {
    const firstAndLastInitials = name?.match(/\b(\w)/g) || []
    const firstInitial = firstAndLastInitials?.shift()
    const lastInitial = firstAndLastInitials?.pop() ?? ''
    merge = firstInitial
  }

  const roleType = 1

  useEffect(() => {
    if (width < threshold !== isSmall) {
      setVisible(width > threshold)
      isSmallSet(width < threshold)
    }
  }, [width, threshold, isSmall])

  const fetchSubMenu = () => {
    return !isSmall ? reportsSubmenu(featureToggles) : null
  }

  const [openSettings, setOpenSettings] = useState(false)
  const handleMenuClick = () => (onClick, setVisible(!visible))
  const { updateCars, allCars } = useCarContext()

  return (
    <MenuLayout collapse={collapse}>
      {/*   {collapse && <CollapseButton onClick={() => handleCollapse()}>
          <svg width="20" height="32" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line
              y1="-2.79889"
              x2="54.8583"
              y2="-2.79889"
              transform="matrix(-1 0 0 1 55.1711 6.58441)"
              stroke="#A3A2A2"
              stroke-width="5.59778"
            />
            <line
              y1="-2.79889"
              x2="54.8583"
              y2="-2.79889"
              transform="matrix(-1 0 0 1 55.1711 18.8995)"
              stroke="#A3A2A2"
              stroke-width="5.59778"
            />
            <line
              y1="-2.79889"
              x2="54.8583"
              y2="-2.79889"
              transform="matrix(-1 0 0 1 55.1711 31.2147)"
              stroke="#A3A2A2"
              stroke-width="5.59778"
            />
          </svg>
        </CollapseButton>} */}
      <OuterMainMenu>
          <StaccLogoWrapper>
          <StaccLogo />
          </StaccLogoWrapper>
        <InnerMainMenu>
          <NewMenuItem
            icon={<HomeSvg />}
            collapsed={collapse}
            title={'Dashboard'}
            onClick={() => handleMenuClick()}
            path={''}
            islogo={'true'}
          ></NewMenuItem>
          <NewMenuItem
            icon={<CarSvg />}
            title={'Cars'}
            collapsed={collapse}
            onClick={() => handleMenuClick()}
            path={'cars'}
            islogo={'true'}
          ></NewMenuItem>
          {roleType === 2 && (
            <NewMenuItem
              icon={<EmployeesSvg />}
              title={'Employees'}
              collapsed={collapse}
              onClick={() => handleMenuClick()}
              path={'drivers'}
              islogo={'true'}
            ></NewMenuItem>
          )}
          {roleType === 2 && (
            <NewMenuItem
              icon={<InvoicesSvg />}
              title={'Invoices'}
              collapsed={collapse}
              onClick={() => handleMenuClick()}
              path={'invoices'}
              islogo={'true'}
            ></NewMenuItem>
          )}

          <NewMenuItem
            icon={<ReportsSvg />}
            collapsed={collapse}
            title={'Reports'}
            onClick={() => handleMenuClick()}
            path={'/reports'}
            islogo={'true'}
          ></NewMenuItem>

          <NewLink collapsed={collapse} href={'/Home/Profile'}>
            <span>
              <SidewayCar />
            </span>
            {collapse ? null : 'Car configuration'}
          </NewLink>
 <NewLink collapsed={collapse} href={'/Home/MyOffers'}>
            <span>
              <OrderSvg />
            </span>
            {collapse ? null : ''}
          </NewLink>
          {roleType === 2 && (
            <NewMenuItem
              icon={<TasksSvg />}
              title={'My tasks'}
              collapsed={collapse}
              onClick={() => handleMenuClick()}
              path={'tasks'}
              islogo={'true'}
            ></NewMenuItem>
          )}
        </InnerMainMenu>

        <InnerMainMenu>
          {/*           <SubHeader>Manage</SubHeader>
           */}
        </InnerMainMenu>
      </OuterMainMenu>
      <OtherInnerMainMenu>
        <UserWrapper collapsed={collapse}>
          <Avatar>{user ? 'FM' : 'FM'}</Avatar>
        </UserWrapper>
        <NewMenuItem
          icon={<NotificationsSvg />}
          title={'Notifications'}
          onClick={() => handleMenuClick()}
          path={'notifications'}
          collapsed={collapse}
          islogo={'true'}
        ></NewMenuItem>
        <NewMenuButton
          icon={<SettingsSvg />}
          title={'Settings'}
          collapsed={collapse}
          onClick={() => setOpenSettings(!openSettings)}
          path={'settings'}
          islogo={'true'}
        ></NewMenuButton>
        {openSettings && (
          <SettingsContainer onMouseLeave={() => setOpenSettings(false)}>
            <Language />
            <UserInfo />
          </SettingsContainer>
        )}
        <NewMenuItem
          icon={<SupportSvg />}
          collapsed={collapse}
          title={'Support'}
          onClick={() => handleMenuClick()}
          path={'help'}
          islogo={'true'}
        ></NewMenuItem>
        <NewMenuItem
          icon={
              <PowerSvg />
          }
          collapsed={collapse}
          title={'Logout'}
          onClick={() => handleMenuClick()}
          path={'logout'}
          islogo={'true'}
        ></NewMenuItem>
      </OtherInnerMainMenu>
    </MenuLayout>
  )
}

const IconWrapper = styled.div`
  transform: rotate(180deg);
  color: #525050;
  padding: 0px;
`
const CollapseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: 'red';
`

const NewLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.collapsed ? 'center' : 'start')};
  gap: 1rem;
  color: #a3a2a2;
  border-radius: 10px;
  text-decoration: none;
  font-family: var(--font-test);
  font-size: var(--font-base);
  font-weight: 500;
  :hover {
    color: rgb(88, 88, 88);
  }
`

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  left: 25px;
  bottom: 15%;
  z-index: 9999;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  position: absolute;
`

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 4px;
  padding-left: ${(props) => (props.collapsed ? '0' : '1rem')};
  justify-content: ${(props) => (props.collapsed ? 'center' : 'start')};
`

const MenuLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  max-height: 100vh;
  min-width: ${(p) => (p.collapse ? '50px' : '300px')};
  background-color: #ffffff;
  padding: 1rem 1rem;
  color: var(--color-newFont);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
`

const Avatar = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  font-size: 16px;
  
  font-weight: bold;
  justify-content: center;
  background-color: #d1e4ff;
  color: #0056d0;
  border-radius: 50%;
  transition: all ease-in-out 0.15s;
  &:hover {
    cursor: pointer;
    color: #ffffff;
    transition: all ease-in-out 0.15s;
  }
`
const InnerMainMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 26px;
  gap: 26px;
`

const StaccLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  margin-bottom: 11px;
`

const OtherInnerMainMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 31px;
  margin-bottom: 14px;
`
const OuterMainMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
`
export function StaccLogo() {
  return (
    <svg width={40} height={50} viewBox="0 0 25 30" class=" w-full" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3_3)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.2204 0.509766L22.2205 6.51973V18.4998L12.2204 24.5098L2.22048 18.4998V6.51973L12.2204 0.509766ZM3.87054 17.5426V13.98L11.3953 18.5001V22.065L3.87054 17.5426ZM11.3953 16.5513L3.87054 12.0313V8.46858L11.3953 12.9887V16.5513ZM13.0454 22.0651V12.9891L15.9843 11.2237V20.2988L13.0454 22.0651ZM17.6344 19.3071L20.5704 17.5426V8.46877L17.6344 10.2324V19.3071ZM15.1881 4.24224L12.2204 2.45873L4.66701 6.99834L7.63347 8.78027L15.1881 4.24224ZM16.8097 5.21684L9.25559 9.75462L12.2207 11.5358L19.7741 6.99843L16.8097 5.21684Z"
          fill="#AD362B"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_3">
          <rect width="24" height="24" fill="white" transform="translate(0.220703 0.509766)" />
        </clipPath>
      </defs>
    </svg>
  )
}
