import React from 'react'
import ReportAnchor from '../components/ReportAnchor'
import { useFeatureToggles } from '../../../context/UserProvider'
import { reportsSubmenuWithIcons } from '../../../data/routes'
import Container from '../../../components/Container'
import { CarSvg } from '../../../icons/NewMenuCollection'
import * as Tabs from '@radix-ui/react-tabs'
import { Box } from '@staccx/bento'
import styled, { css } from 'styled-components'
import { TranslatedText } from '@staccx/i18n'
import { useState, useMemo } from 'react'
import Notification from '../../home/components/Notification'
import EnvironmentReportTable from '../components/EnvironmentReportTable'
import EnvironmentReport from './EnvironmentReport'
import FleetReport from './FleetReport'
import FleetExport from './FleetExport'
import FuelReport from './FuelReport'
import TollReport from './TollReport'
import InvoiceReport from './InvoiceReport'
import PurchaseReport from './PurchaseReport'
import LeasingReport from './LeasingReport'

const ReportsOverview = () => {
  const featureToggles = useFeatureToggles()
  const [value, setValue] = useState('tab1')

  const tabs = [
    {
      value: 'tab1',
      label: 'Car fleet'
    },
    {
      value: 'tab2',
      label: 'Fleet Report'
    },
    {
      value: 'tab3',
      label: 'Environmental Report'
    },
    {
      value: 'tab4',
      label: 'Toll booth passings'
    },
    {
      value: 'tab5',
      label: 'Invoice report'
    },
    {
      value: 'tab6',
      label: 'Purchase Report'
    },
    {
      value: 'tab7',
      label: 'Leasing'
    },
    {
      value: 'tab8',
      label: 'Fuel Report'
    }
  ]
  return (
    <Container>
      <Box variant="tabs">
        <StyledRoot defaultValue="tab1" onValueChange={(val) => setValue(val)}>
          <StyledList aria-label="Select tasks">
            {tabs.map((tab) => {
              return (
                <StyledTrigger key={tab.value} isactive={value === tab.value} value={tab.value}>
                  {tab.label}
                </StyledTrigger>
              )
            })}
          </StyledList>
          <StyledContent value="tab1">
            <FleetExport />
          </StyledContent>
          <StyledContent value="tab2">
            <FleetReport />
          </StyledContent>
          <StyledContent value="tab3">
            <EnvironmentReport />
          </StyledContent>
          <StyledContent value="tab4">
            <TollReport />
          </StyledContent>
          <StyledContent value="tab5">
            <InvoiceReport />
          </StyledContent>
          <StyledContent value="tab6">
            <PurchaseReport />
          </StyledContent>
          <StyledContent value="tab7">
            <LeasingReport />
          </StyledContent>
          <StyledContent value="tab8">
            <FuelReport />
          </StyledContent>
        </StyledRoot>
      </Box>
    </Container>
  )
}

export default ReportsOverview

const StyledButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 2rem;
  span {
    transition: ease-in-out 0.2s;
  }
`
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-medium);
  background-color: white;
  border: 2px solid transparent;
  border-bottom: 8px solid blue;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  :hover {
    cursor: pointer;
    span {
      transform: translateX(15px);
    }
  }
  transition: ease-in-out 0.2s;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-medium);
  padding: var(--spacing-medium);
  > a {
    text-decoration: none;
  }
  @media (min-width: 680px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1220px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
  }
`

const StyledTrigger = styled(Tabs.Trigger)`
  border: none;
  background-color: white;
  padding: 2rem;
  position: relative;

  cursor: pointer;
  ${(props) =>
    props.isactive
      ? css`
          color: black;
          font-weight: 600;
          :after {
            left: 50%;
            bottom: 0;
            position: absolute;
            transform: translateX(-50%);
            width: 75%;
            width: calc(100% - 4rem);
            content: '';
            display: block;
            height: 4px;
            background-color: blue;
          }
        `
      : css`
          border-bottom: 4px solid transparent;
        `}
`

const StyledList = styled(Tabs.List)`
  display: flex;
  border-bottom: 2px solid lightgray;
`
const StyledRoot = styled(Tabs.Root)`
  background-color: transparent;
`

const StyledContent = styled(Tabs.Content)``
