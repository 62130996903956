import React from "react"

const Elektrisk = props => (
  <svg viewBox="0 0 24 24" width="24" height="24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 8h10v4a5 5 0 0 1-4.1 4.9A5 5 0 0 1 5 16v-4h2v4a3 3 0 1 0 5.86.86A5 5 0 0 1 9 12V8zm2-4h2v3h-2V4zm4 0h2v3h-2V4zm2 8v-2h-6v2a3 3 0 0 0 6 0z"
    />
  </svg>
)

export default Elektrisk
