import React from 'react'
import type { Leasing } from '../../../../types'
import { createColumnHelper } from '@tanstack/react-table'
import { TranslatedText } from '@staccx/i18n'
import { formatDate } from '@staccx/bento'
import { formatCurrency, formatNumber } from '../../../../utils/tableFuncs'

export default function leasingReportTableData() {
  const columnHelper = createColumnHelper<Leasing>()
  return [
    columnHelper.accessor('accountID', {
      id: 'invoiceNo',
      header: () => <TranslatedText i18nKey={'ACCOUNT_NO'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' }
    }),
    columnHelper.accessor('regNo', {
      id: 'regNo',
      header: () => <TranslatedText i18nKey={'REGISTRATION_NUMBER'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' }
    }),
    columnHelper.accessor('vehicleID', {
      id: 'vehicleID',
      header: () => <TranslatedText i18nKey={'VEHICLE_ID'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' }
    }),
    columnHelper.accessor(undefined, {
      id: 'consolidatedAgreement',
      header: () => (
        <TranslatedText i18nKey={'CONSOLIDATED_AGREEMENT'}>{(value) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) =>
        `${row?.row?.original.consolidatedAgreementID} - ${row?.row?.original.consolidatedAgreement}` || '',
      meta: { alignmentType: 'string' }
    }),
    columnHelper.accessor('driverName', {
      id: 'driverName',
      header: () => <TranslatedText i18nKey={'DRIVER'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' }
    }),
    columnHelper.accessor('maxKm', {
      id: 'maxKm',
      header: () => <TranslatedText i18nKey={'MAX_KM'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue(), { precision: 0 }) || '',
      meta: { alignmentType: 'number' }
    }),
    columnHelper.accessor('totalTerms', {
      id: 'totalTerms',
      header: () => <TranslatedText i18nKey={'TB'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' }
    }),
    columnHelper.accessor('termsNow', {
      id: 'termsNow',
      header: () => <TranslatedText i18nKey={'TERMS_NOW'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' }
    }),
    columnHelper.accessor('remainingTerms', {
      id: 'remainingTerms',
      header: () => <TranslatedText i18nKey={'REST_TERMS'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'number' }
    }),
    columnHelper.accessor('startDate', {
      id: 'startDate',
      header: () => <TranslatedText i18nKey={'START_DATE'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatDate(row.getValue()) || '',
      meta: { alignmentType: 'date' }
    }),
    columnHelper.accessor('returnDate', {
      id: 'returnDate',
      header: () => <TranslatedText i18nKey={'RETURN_DATE_LEASING'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatDate(row.getValue()) || '',
      meta: { alignmentType: 'date' }
    }),
    columnHelper.accessor('termLeasing_incl_VAT', {
      id: 'termLeasing_incl_VAT',
      header: () => <TranslatedText i18nKey={'LEASING_RENT_INC'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatCurrency(row.getValue()) || '',
      meta: { alignmentType: 'number' }
    }),
    columnHelper.accessor('restLeasing_incl_VAT', {
      id: 'restLeasing_incl_VAT',
      header: () => (
        <TranslatedText i18nKey={'LEASING_COMMITMENTS_INC'}>{(value) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => formatCurrency(row.getValue()) || '',
      footer: (props) => props.column.id,
      meta: { alignmentType: 'number' }
    }),
    columnHelper.accessor('restLeasing_excl_VAT', {
      id: 'restLeasing_excl_VAT',
      header: () => (
        <TranslatedText i18nKey={'LEASING_COMMITMENTS_NET'}>{(value) => <span>{value}</span>}</TranslatedText>
      ),
      cell: (row) => formatCurrency(row.getValue()) || '',
      footer: (props) => props.column.id,
      meta: { alignmentType: 'number' }
    })
  ]
}
