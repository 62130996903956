import React from "react"
import { Navigate } from "react-router-dom"
import { Loading } from "@staccx/bento"
import { useCarContext } from "../../../context/CarProvider"

const MyCar = () => {
  const carContext = useCarContext()
  const car = carContext.currentUsersCar

  if (!car) {
    return <Loading />
  }

  return <Navigate to={`/cars/${car.registrationNumber}`} replace />

}

export default MyCar
