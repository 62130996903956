import { formatNumber } from '../../../../utils/tableFuncs'
import { createColumnHelper } from '@tanstack/react-table'
import type { Environment } from '../../../../types'
import React from 'react'
import { TranslatedText } from '@staccx/i18n'

export default function environmentReportTableData() {
  const columnHelper = createColumnHelper<Environment>()
  return [
    columnHelper.accessor('regNo', {
      id: 'regNo',
      header: () => <TranslatedText i18nKey={'REGISTRATION_NUMBER'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('vehicleID', {
      id: 'vehicleID',
      header: () => <TranslatedText i18nKey={'VEHICLE_ID'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('invoiceGroup', {
      id: 'invoiceGroup',
      header: () => <TranslatedText i18nKey={'INVOICE_GROUP'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('carDescription', {
      id: 'carDescription',
      header: () => <TranslatedText i18nKey={'OBJECT'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('totalKm', {
      id: 'totalKm',
      header: () => <TranslatedText i18nKey={'KILOMETERS'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue(), { precision: 0 }) || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('volume', {
      id: 'volume',
      header: () => <TranslatedText i18nKey={'LITERS'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue(), { precision: 2 }) || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('fuelType', {
      id: 'fuelType',
      header: () => <TranslatedText i18nKey={'FUEL'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => row.getValue() || '',
      meta: { alignmentType: 'string' },
    }),
    columnHelper.accessor('litersPerTenKm', {
      id: 'litersPerTenKm',
      header: () => <TranslatedText i18nKey={'LITERS_PER_TEN_KM'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue(), { precision: 2 }) || '',
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('co2Emission', {
      id: 'co2Emission',
      header: () => <TranslatedText i18nKey={'CO2'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue(), { precision: 2 }) || '',
      footer: (props) => props.column.id,
      meta: { alignmentType: 'number' },
    }),
    columnHelper.accessor('nOXEmission', {
      id: 'nOXEmission',
      header: () => <TranslatedText i18nKey={'NOX'}>{(value) => <span>{value}</span>}</TranslatedText>,
      cell: (row) => formatNumber(row.getValue(), { precision: 2 }) + ' g' || '',
      footer: (props) => props.column.id,
      meta: { alignmentType: 'number' },
    }),
  ]
}
