import { BarChart } from '@mui/x-charts'
import { Box, Heading } from '@staccx/bento'
import styled from 'styled-components'

export function ComparisonGraph({ data }) {
  return (
    <StyledContainer>
      <HeaderContainer>
        <svg width="43" height="44" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.5 7.333V10m10.37 1.63-1.885 1.885m-19.152-2.182 1.886 1.886M36.167 22H33.5m-24 0H6.833M21.5 42c11.045 0 20-8.955 20-20s-8.955-20-20-20-20 8.955-20 20 8.955 20 20 20Z" stroke="#A8A8A8" stroke-width="2.667" stroke-miterlimit="10" stroke-linecap="round"/><path d="m24.327 19.173 7.84-7.84M21.5 26a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z" stroke="#AD362B" stroke-width="2.667" stroke-miterlimit="10" stroke-linecap="round"/></svg>
        <div>
        <Heading>{data.title}</Heading>
        <p>{Number(data.data).toFixed(2)} kg</p>
        </div>
      </HeaderContainer>
      <BarChart
        xAxis={[
          {
            id: 'barCategories',
            data: ['Your fleet    -    Others'],
            scaleType: 'band'
          }
        ]}
        series={[
          {
            data: [data.data],
             color: '#076EFF',
          },
            {
            data: [data.overAllAverage],
             color: '#9CC5FF',

          },
          
        ]}
        width={350}
        height={200}
      />
    </StyledContainer>
  )
}


const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  padding: 1rem;
`   

const StyledContainer = styled.div`
  background-color: white;
  width: 100%;
  padding: 1rem;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1rem;
`
