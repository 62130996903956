import React from "react"
import { Button, Heading, spacing, Text, Wrapper } from "@staccx/bento"
import styled from "styled-components"
import { TranslatedText } from "@staccx/i18n"

const DialogBox = ({heading, body, confirmButtonText, cancelButtonText, ...props}) => {

  return (
    <Wrapper>
      <StyledText>
        <Heading level={2}>{heading}</Heading>
        <Text>{body}</Text>
      </StyledText>
      <ButtonGroup>
        <StyledButton onClick={() => props.onFinish()}>
          {confirmButtonText || "OK"}
        </StyledButton>
        <StyledButton onClick={() => props.onCancel()}>
          {cancelButtonText || <TranslatedText i18nKey="FORM_RESET" />}
        </StyledButton>
      </ButtonGroup>
    </Wrapper>
  )
}

export default DialogBox

const StyledText = styled.div`
  margin-bottom: ${spacing.largePlus};
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin: var(--spacing-medium);
`

const StyledButton = styled(Button)`
  margin: 0 12px 0 12px;
`
